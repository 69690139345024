var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-5 basket-wrapper-b basket-flatrate"},[_c('header',{staticClass:"is-flex is-justify-content-space-between is-align-items-center mb-4"},[_c('h3',{staticClass:"is-size-4 has-text-weight-bold"},[_vm._v(" Einkaufswagen ")]),_c('button',{staticClass:"button is-transparent p-0 mb-0",on:{"click":function($event){return _vm.setSidebarOpen(false)}}},[_c('CloseIcon')],1)]),_c('div',{staticClass:"basket-container"},[_c('div',[_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-flex-end"},[_c('div',{staticClass:"basket-wrapper"},[_c('div',{staticClass:"basket-title icon-row"},[_vm._m(0),(_vm.helpMeChooseScoped == true)?_c('span',[(_vm.isNuxt == true)?_c('span',{staticClass:"help-choose",on:{"click":_vm.closeBasket}},[_vm._v(" "+_vm._s(_vm.$t("basket.helpMeChoose"))+" ")]):_vm._e(),(_vm.isNuxt != true)?_c('span',{staticClass:"help-choose",on:{"click":_vm.closeBasket}},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ el: this.seeMoreLink.hash }),expression:"{ el: this.seeMoreLink.hash }"}],staticClass:"underlined",attrs:{"to":{
                    hash: this.seeMoreLink.hash,
                  }}},[_vm._v("Produkte vergleichen")])],1):_vm._e()]):_vm._e()]),_c('div',{staticClass:"basket-offer-image"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.selectedProduct.imageBasket}})]),_c('div',{staticClass:"basket-vaha-items"},_vm._l((_vm.products),function(product){return _c('div',{key:product.type,staticClass:"basket-vaha-item",class:{
                active: _vm.$t(_vm.selectedProduct.id) == _vm.$t(product.id),
                borderWhite:
                  _vm.selectedRegionScoped != 'UK' && _vm.vahaSEnabled == false,
                hidden:
                  _vm.selectedRegionScoped != 'UK' &&
                  _vm.$t(product.id) == 2 &&
                  _vm.vahaSEnabled == false,
                fullWidth:
                  _vm.selectedRegionScoped != 'UK' &&
                  _vm.$t(product.id) == 1 &&
                  _vm.vahaSEnabled == false,
              },on:{"click":function($event){_vm.changeProduct(product, _vm.$t(product.id), _vm.selectedRegionScoped)}}},[[_c('div',{staticClass:"basket-vaha-item-image-wrapper basket-vaha-item-image-wrapper--uk"},[_c('p',{staticClass:"basket-product-title strong font-16"},[_vm._v(" "+_vm._s(_vm.$t(product.name))+" ")])])]],2)}),0)])]),_c('div',{staticClass:"basket-offer-selector"},[_c('div',{staticClass:"offer offer-one-time",class:{
            active: _vm.selectedPricingModel == 'ONE_TIME',
          },on:{"click":function($event){return _vm.setSelectedPricingModel('ONE_TIME')}}},[_c('div',{staticClass:"column-left"},[_c('img',{staticClass:"selector",attrs:{"src":_vm.selectedPricingModel === 'ONE_TIME'
                  ? 'https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/images/basket/radio-checked.png'
                  : 'https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/images/basket/radio-unchecked.png'}})]),_c('div',{staticClass:"column-right is-flex is-flex-direction-column"},[_vm._m(1),_c('p',{staticClass:"description"},[_vm._v("12-monatige Mitgliedschaft inklusive")]),_c('div',{staticClass:"prices"},[_c('p',{staticClass:"price-promo"},[_vm._v(" "+_vm._s(_vm.selectedProduct.payUpfront.prices.original)+" ")])])])]),_c('div',{staticClass:"offer offer-installments",class:{
            active: _vm.selectedPricingModel == 'INSTALLMENTS',
          },on:{"click":function($event){return _vm.setSelectedPricingModel('INSTALLMENTS')}}},[_c('div',[_c('img',{staticClass:"selector",attrs:{"src":_vm.selectedPricingModel === 'INSTALLMENTS'
                  ? 'https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/images/basket/radio-checked.png'
                  : 'https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/images/basket/radio-unchecked.png'}})]),_c('div',{staticClass:"column-right is-flex is-flex-direction-column"},[_c('p',{staticClass:"title"},[_vm._v(" Ratenzahlung (12 Monate) ")]),_c('p',{staticClass:"description"},[_vm._v("Monatliche Zahlungsweise")]),_c('p',{staticClass:"description has-text-grey"},[_vm._v(" *12 — monatige Mindestlaufzeit ")]),_c('div',{staticClass:"prices prices-column"},[_c('p',{staticClass:"price-mtl"},[_vm._v(" "+_vm._s(_vm.selectedProduct.payUpfront.prices.monthly)+" ")]),_c('p',{staticClass:"price-description"},[_vm._v(" für 12 Monate (inkl. MwSt) ")])])])])]),_c('div',{class:'separator--light my-4'}),_c('PromoBox',{attrs:{"variant":_vm.promoBoxVariant,"selectedProduct":_vm.selectedProduct,"region":_vm.selectedRegionScoped,"experimentRunning":_vm.experimentRunning,"showYellowBox":false}}),(_vm.activePromotion === 'NO_DISCOUNT')?_c('NoDiscount',{attrs:{"icon":'star-promo',"region":_vm.selectedRegionScoped}}):_vm._e(),(
          _vm.selectedProductDiscountedPrice != null ||
            _vm.discount.code.promoCode != null
        )?_c('div',{class:'separator--light my-4'}):_vm._e(),(
          _vm.selectedProductDiscountedPrice != null &&
            _vm.$te(_vm.discount.code.promoCode)
        )?_c('BasketDiscountRow',{attrs:{"discount":_vm.discount}}):_vm._e(),_c('BasketPricesRowV2',{attrs:{"prices":_vm.prices,"selectedRegion":_vm.selectedRegionScoped,"unique-discount-selected-product":_vm.selectedProductDiscountedPrice}}),_c('div',{class:{ fixed: _vm.experimentRunning }},[_c('div',{staticClass:"asterisks"},[(_vm.selectedPricingModel === 'INSTALLMENTS')?_c('p',[_vm._v(" *für 12 Monate. Nach den ersten 12 Zahlungsmonaten beträgt dein monatliches Abonnement € 49 pro Monat. ")]):_vm._e()]),(_vm.selectedProduct.paymentButton.url)?_c('AppIconButton',{staticClass:"basket-payments-button",attrs:{"theme":"yellow","title":_vm.$t(_vm.selectedProduct.paymentButton.label)},on:{"click":function($event){return _vm.prepareShopifyUrl(_vm.selectedProduct.id, _vm.selectedRegionScoped)}}}):_vm._e()],1),_c('div',{staticClass:"basket-payments-container experiment-wvSDVbi8R6KyxCB9T0CkrA"},_vm._l((_vm.allowedPayments),function(allowedPayment){return _c('img',{key:allowedPayment.name,staticClass:"basket-payments-icon",attrs:{"src":_vm.prepareAllowedPaymentName(allowedPayment.name),"alt":("" + (allowedPayment.name))}})}),0)],1),_c('div',{class:'separator--light my-4'}),_c('PageSectionContainer',{staticClass:"has-background-white pb-5 mb-big",attrs:{"container":""}},[_c('ul',{staticClass:"columns is-multiline is-mobile mt-2 mb-3"},_vm._l((_vm.selectedProduct.experimentFeaturedServices.items),function(item){return _c('li',{key:item.name,staticClass:"column is-12-mobile is-12-tablet is-4-desktop"},[_c('span',{staticClass:"vaha-item delivery-notes",class:{ 'experiment-13-09': _vm.experimentRunning }},[_c('span',{staticClass:"vaha-icon"},[_c(("Icon" + (_vm.$t(item.id))),{tag:"component",class:("vaha-icon-" + (_vm.$t(item.id)))})],1),_c('span',{staticClass:"vaha-text has-text-centered-desktop"},[_vm._v(" "+_vm._s(_vm.$t(item.content))+" ")])])])}),0)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"basket-title-span-holder"},[_c('span',[_c('span',{staticClass:"font-weight-700"},[_vm._v("Wähle deinen VAHA")]),_vm._v("  "),_c('em',{staticClass:"font-weight-400"},[_vm._v("PLAN")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title"},[_vm._v(" Einmalzahlung "),_c('span',{staticClass:"promo"},[_vm._v("Bestes Angebot ")])])}]

export { render, staticRenderFns }