<template>
  <section>
    <AppGridContainer v-if="container"><slot /></AppGridContainer>
    <slot v-else />
  </section>
</template>

<script>
import AppGridContainer from '../AppGridContainer';

export default {
  components: { AppGridContainer },
  props: {
    container: { type: Boolean, default: false },
  },
};
</script>
