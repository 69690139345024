export const noDiscountProductS = {
  mainPromoHeadline: "products.product.promotions.noDiscount.mainPromoHeadline",
  heading: {
    image: "products.productS.promotions.noDiscount.heading.image",
    title: "products.productS.promotions.noDiscount.heading.title",
  },
  pricesBeforeAfter: {
    priceBefore:
      "products.productS.promotions.noDiscount.pricesBeforeAfter.priceBefore",
    priceAfter:
      "products.productS.promotions.noDiscount.pricesBeforeAfter.priceAfter",
  },
  footer: "products.productS.promotions.noDiscount.footer",
  total: {
    headline: "products.productS.promotions.noDiscount.total.headline",
    priceBefore: "products.productS.promotions.noDiscount.total.priceBefore",
    priceAfter: "products.productS.promotions.noDiscount.total.priceAfter",
  },
  discount: {
    heading: "products.productS.promotions.noDiscount.discount.heading",
    title: "products.productS.promotions.noDiscount.discount.title",
    amount: "products.productS.promotions.noDiscount.discount.amount",
    code: {
      description:
        "products.productS.promotions.noDiscount.discount.code.description",
      promoCode:
        "products.productS.promotions.noDiscount.discount.code.promoCode",
    },
  },
  items: {
    item1: {
      title: "products.productS.promotions.noDiscount.items.item1.title",
      description:
        "products.productS.promotions.noDiscount.items.item1.description",
      priceBefore:
        "products.productS.promotions.noDiscount.items.item1.priceBefore",
      priceAfter:
        "products.productS.promotions.noDiscount.items.item1.priceAfter",
    },
    item2: {
      title: "products.productS.promotions.noDiscount.items.item2.title",
      description:
        "products.productS.promotions.noDiscount.items.item2.description",
      priceBefore:
        "products.productS.promotions.noDiscount.items.item2.priceBefore",
      priceAfter:
        "products.productS.promotions.noDiscount.items.item2.priceAfter",
    },
    item3: {
      title: "products.productS.promotions.noDiscount.items.item3.title",
      description:
        "products.productS.promotions.noDiscount.items.item3.description",
      priceBefore:
        "products.productS.promotions.noDiscount.items.item3.priceBefore",
      priceAfter:
        "products.productS.promotions.noDiscount.items.item3.priceAfter",
    },
    item4: {
      title: "products.productS.promotions.noDiscount.items.item4.title",
      description:
        "products.productS.promotions.noDiscount.items.item4.description",
      priceBefore:
        "products.productS.promotions.noDiscount.items.item4.priceBefore",
      priceAfter:
        "products.productS.promotions.noDiscount.items.item4.priceAfter",
    },
    item5: {
      title: "products.productS.promotions.noDiscount.items.item5.title",
      description:
        "products.productS.promotions.noDiscount.items.item5.description",
      priceBefore:
        "products.productS.promotions.noDiscount.items.item5.priceBefore",
      priceAfter:
        "products.productS.promotions.noDiscount.items.item5.priceAfter",
    },
    item6: {
      title: "products.productS.promotions.noDiscount.items.item6.title",
      description:
        "products.productS.promotions.noDiscount.items.item6.description",
      priceBefore:
        "products.productS.promotions.noDiscount.items.item6.priceBefore",
      priceAfter:
        "products.productS.promotions.noDiscount.items.item6.priceAfter",
    },
  },
};

export const flatrateNoDiscountProductS = {
  mainPromoHeadline:
    "products.productS.promotions.flatrateNoDiscount.mainPromoHeadline",
  heading: {
    image: "products.productS.promotions.flatrateNoDiscount.heading.image",
    title: "products.productS.promotions.flatrateNoDiscount.heading.title",
  },
  footer: "products.productS.promotions.flatrateNoDiscount.footer",
  total: {
    headline: "products.productS.promotions.flatrateNoDiscount.total.headline",
    priceBefore:
      "products.productS.promotions.flatrateNoDiscount.total.priceBefore",
    priceAfter:
      "products.productS.promotions.flatrateNoDiscount.total.priceAfter",
  },
  discount: {
    heading: "products.productS.promotions.noDiscount.discount.heading",
    title: "products.productS.promotions.flatrateNoDiscount.discount.title",
    amount: "products.productS.promotions.flatrateNoDiscount.discount.amount",
    code: {
      description:
        "products.productS.promotions.flatrateNoDiscount.discount.code.description",
      promoCode:
        "products.productS.promotions.flatrateNoDiscount.discount.code.promoCode",
    },
  },
  items: {
    item1: {
      title:
        "products.productS.promotions.flatrateNoDiscount.items.item1.title",
      description:
        "products.productS.promotions.flatrateNoDiscount.items.item1.description",
      priceBefore:
        "products.productS.promotions.flatrateNoDiscount.items.item1.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateNoDiscount.items.item1.priceAfter",
    },
    item2: {
      title:
        "products.productS.promotions.flatrateNoDiscount.items.item2.title",
      description:
        "products.productS.promotions.flatrateNoDiscount.items.item2.description",
      priceBefore:
        "products.productS.promotions.flatrateNoDiscount.items.item2.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateNoDiscount.items.item2.priceAfter",
    },
    item3: {
      title:
        "products.productS.promotions.flatrateNoDiscount.items.item3.title",
      description:
        "products.productS.promotions.flatrateNoDiscount.items.item3.description",
      priceBefore:
        "products.productS.promotions.flatrateNoDiscount.items.item3.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateNoDiscount.items.item3.priceAfter",
    },
    item4: {
      title:
        "products.productS.promotions.flatrateNoDiscount.items.item4.title",
      description:
        "products.productS.promotions.flatrateNoDiscount.items.item4.description",
      priceBefore:
        "products.productS.promotions.flatrateNoDiscount.items.item4.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateNoDiscount.items.item4.priceAfter",
    },
    item5: {
      title:
        "products.productS.promotions.flatrateNoDiscount.items.item5.title",
      description:
        "products.productS.promotions.flatrateNoDiscount.items.item5.description",
      priceBefore:
        "products.productS.promotions.flatrateNoDiscount.items.item5.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateNoDiscount.items.item5.priceAfter",
    },
    item6: {
      title:
        "products.productS.promotions.flatrateNoDiscount.items.item6.title",
      description:
        "products.productS.promotions.flatrateNoDiscount.items.item6.description",
      priceBefore:
        "products.productS.promotions.flatrateNoDiscount.items.item6.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateNoDiscount.items.item6.priceAfter",
    },
  },
};

export const noDiscountProductX = {
  mainPromoHeadline:
    "products.productX.promotions.noDiscount.mainPromoHeadline",
  heading: {
    image: "products.productX.promotions.noDiscount.heading.image",
    title: "products.productX.promotions.noDiscount.heading.title",
  },
  pricesBeforeAfter: {
    priceBefore:
      "products.productX.promotions.noDiscount.pricesBeforeAfter.priceBefore",
    priceAfter:
      "products.productX.promotions.noDiscount.pricesBeforeAfter.priceAfter",
  },
  footer: "products.productX.promotions.noDiscount.footer",
  total: {
    headline: "products.productX.promotions.noDiscount.total.headline",
    priceBefore: "products.productX.promotions.noDiscount.total.priceBefore",
    priceAfter: "products.productX.promotions.noDiscount.total.priceAfter",
  },
  discount: {
    heading: "products.productX.promotions.noDiscount.discount.heading",
    title: "products.productX.promotions.noDiscount.discount.title",
    amount: "products.productX.promotions.noDiscount.discount.amount",
    code: {
      description:
        "products.productX.promotions.noDiscount.discount.code.description",
      promoCode:
        "products.productX.promotions.noDiscount.discount.code.promoCode",
    },
  },
  items: {
    item1: {
      title: "products.productX.promotions.noDiscount.items.item1.title",
      description:
        "products.productX.promotions.noDiscount.items.item1.description",
      priceBefore:
        "products.productX.promotions.noDiscount.items.item1.priceBefore",
      priceAfter:
        "products.productX.promotions.noDiscount.items.item1.priceAfter",
    },
    item2: {
      title: "products.productX.promotions.noDiscount.items.item2.title",
      description:
        "products.productX.promotions.noDiscount.items.item2.description",
      priceBefore:
        "products.productX.promotions.noDiscount.items.item2.priceBefore",
      priceAfter:
        "products.productX.promotions.noDiscount.items.item2.priceAfter",
    },
    item3: {
      title: "products.productX.promotions.noDiscount.items.item3.title",
      description:
        "products.productX.promotions.noDiscount.items.item3.description",
      priceBefore:
        "products.productX.promotions.noDiscount.items.item3.priceBefore",
      priceAfter:
        "products.productX.promotions.noDiscount.items.item3.priceAfter",
    },
    item4: {
      title: "products.productX.promotions.noDiscount.items.item4.title",
      description:
        "products.productX.promotions.noDiscount.items.item4.description",
      priceBefore:
        "products.productX.promotions.noDiscount.items.item4.priceBefore",
      priceAfter:
        "products.productX.promotions.noDiscount.items.item4.priceAfter",
    },
    item5: {
      title: "products.productX.promotions.noDiscount.items.item5.title",
      description:
        "products.productX.promotions.noDiscount.items.item5.description",
      priceBefore:
        "products.productX.promotions.noDiscount.items.item5.priceBefore",
      priceAfter:
        "products.productX.promotions.noDiscount.items.item5.priceAfter",
    },
    item6: {
      title: "products.productX.promotions.noDiscount.items.item6.title",
      description:
        "products.productX.promotions.noDiscount.items.item6.description",
      priceBefore:
        "products.productX.promotions.noDiscount.items.item6.priceBefore",
      priceAfter:
        "products.productX.promotions.noDiscount.items.item6.priceAfter",
    },
  },
};

export const flatrateNoDiscountProductX = {
  mainPromoHeadline:
    "products.productX.promotions.flatrateNoDiscount.mainPromoHeadline",
  heading: {
    image: "products.productX.promotions.flatrateNoDiscount.heading.image",
    title: "products.productX.promotions.flatrateNoDiscount.heading.title",
  },
  footer: "products.productX.promotions.flatrateNoDiscount.footer",
  total: {
    headline: "products.productX.promotions.flatrateNoDiscount.total.headline",
    priceBefore:
      "products.productX.promotions.flatrateNoDiscount.total.priceBefore",
    priceAfter:
      "products.productX.promotions.flatrateNoDiscount.total.priceAfter",
  },
  discount: {
    heading: "products.productX.promotions.noDiscount.discount.heading",
    title: "products.productX.promotions.flatrateNoDiscount.discount.title",
    amount: "products.productX.promotions.flatrateNoDiscount.discount.amount",
    code: {
      description:
        "products.productX.promotions.flatrateNoDiscount.discount.code.description",
      promoCode:
        "products.productX.promotions.flatrateNoDiscount.discount.code.promoCode",
    },
  },
  items: {
    item1: {
      title:
        "products.productX.promotions.flatrateNoDiscount.items.item1.title",
      description:
        "products.productX.promotions.flatrateNoDiscount.items.item1.description",
      priceBefore:
        "products.productX.promotions.flatrateNoDiscount.items.item1.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateNoDiscount.items.item1.priceAfter",
    },
    item2: {
      title:
        "products.productX.promotions.flatrateNoDiscount.items.item2.title",
      description:
        "products.productX.promotions.flatrateNoDiscount.items.item2.description",
      priceBefore:
        "products.productX.promotions.flatrateNoDiscount.items.item2.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateNoDiscount.items.item2.priceAfter",
    },
    item3: {
      title:
        "products.productX.promotions.flatrateNoDiscount.items.item3.title",
      description:
        "products.productX.promotions.flatrateNoDiscount.items.item3.description",
      priceBefore:
        "products.productX.promotions.flatrateNoDiscount.items.item3.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateNoDiscount.items.item3.priceAfter",
    },
    item4: {
      title:
        "products.productX.promotions.flatrateNoDiscount.items.item4.title",
      description:
        "products.productX.promotions.flatrateNoDiscount.items.item4.description",
      priceBefore:
        "products.productX.promotions.flatrateNoDiscount.items.item4.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateNoDiscount.items.item4.priceAfter",
    },
    item5: {
      title:
        "products.productX.promotions.flatrateNoDiscount.items.item5.title",
      description:
        "products.productX.promotions.flatrateNoDiscount.items.item5.description",
      priceBefore:
        "products.productX.promotions.flatrateNoDiscount.items.item5.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateNoDiscount.items.item5.priceAfter",
    },
    item6: {
      title:
        "products.productX.promotions.flatrateNoDiscount.items.item6.title",
      description:
        "products.productX.promotions.flatrateNoDiscount.items.item6.description",
      priceBefore:
        "products.productX.promotions.flatrateNoDiscount.items.item6.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateNoDiscount.items.item6.priceAfter",
    },
  },
};
