<template>
  <section class="transformation-package-container">
    <div
      class="is-flex is-justify-content-space-between is-align-items-flex-end is-flex-direction-column"
    >
      <div class="basket-wrapper mt-3 mb-3 is-align-items-center">
        <img
          :src="$t(content.transformationPackage.heading.image)"
          class="transformation-package-image mr-3"
        />
        <h3 class="font-18 font-700">
          {{ $t(content.transformationPackage.heading.title) }}
        </h3>
      </div>
      <div
        class="basket-wrapper mt-2 mb-2"
        v-for="item of content.transformationPackage.items"
        :key="item.title"
      >
        <div class="basket-title">
          <p class="font-700">{{ $t(item.title) }}</p>
          <p class="font-400 font-grey font-12">
            {{ $t(item.description) }}
          </p>
        </div>

        <div
          class="basket-list-price-wrapper is-justify-content-space-between is-align-items-flex-start"
        >
          <p class="font-700">
            <span class="text-strikethrough">{{ $t(item.priceBefore) }}</span>
            <br />
            {{ $t(item.priceAfter) }}
          </p>
        </div>
      </div>
      <div :class="'separator mt-4 mb-4'" />
      <div class="basket-wrapper mt-3 mb-3 total-gain">
        <div class="total-worth">
          {{ $t(content.transformationPackage.total.headline) }}
        </div>
        <div class="total-worth-price-before font-700">
          <span class="text-strikethrough">{{
            $t(content.transformationPackage.total.priceBefore)
          }}</span
          ><span
            >&nbsp;{{
              $t(content.transformationPackage.total.priceAfter)
            }}</span
          >
        </div>
      </div>
      <div :class="'separator mt-4 mb-4'" />
    </div>
  </section>
</template>

<script>
export default {
  name: "TransformationPackage",
  props: {
    region: { type: String, default: "UK" },
  },
  computed: {
    content() {
      return {
        transformationPackage: {
          heading: {
            image: "transformationPackage.heading.image",
            title: "transformationPackage.heading.title",
          },
          total: {
            headline: "transformationPackage.total.headline",
            priceBefore: "transformationPackage.total.priceBefore",
            priceAfter: "transformationPackage.total.priceAfter",
          },
          items: {
            item1: {
              title: "transformationPackage.items.item1.title",
              description: "transformationPackage.items.item1.description",
              priceBefore: "transformationPackage.items.item1.priceBefore",
              priceAfter: "transformationPackage.items.item1.priceAfter",
            },
            item2: {
              title: "transformationPackage.items.item2.title",
              description: "transformationPackage.items.item2.description",
              priceBefore: "transformationPackage.items.item2.priceBefore",
              priceAfter: "transformationPackage.items.item2.priceAfter",
            },
            item3: {
              title: "transformationPackage.items.item3.title",
              description: "transformationPackage.items.item3.description",
              priceBefore: "transformationPackage.items.item3.priceBefore",
              priceAfter: "transformationPackage.items.item3.priceAfter",
            },
            item4: {
              title: "transformationPackage.items.item4.title",
              description: "transformationPackage.items.item4.description",
              priceBefore: "transformationPackage.items.item4.priceBefore",
              priceAfter: "transformationPackage.items.item4.priceAfter",
            },
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.transformation-package-container {
  .font-18 {
    font-size: 18px;
    line-height: 27px;
  }

  .basket-wrapper {
    display: flex;
    justify-content: space-between;

    .basket-title {
      flex-direction: column;
      padding-right: 20px;
      margin-top: 0;
    }
  }

  .transformation-package-image {
    max-height: 64px;
    width: auto;
  }

  .text-strikethrough {
    text-decoration: line-through;
  }

  .font-12 {
    font-size: 12px;
  }

  .font-700 {
    font-weight: 700;
  }

  .font-400 {
    font-weight: 400;
  }
  .font-grey {
    color: #828282;
  }
}
</style>
