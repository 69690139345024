import en_UK from "./en-UK.json";
import de_DE from "./de-DE.json";
import de_AT from "./de-AT.json";
import en_AE from "./en-AE.json";

export const defaultLocale = "en-UK";

export const languages = {
  "en-UK": en_UK,
  "de-DE": de_DE,
  "de-AT": de_AT,
  "en-AE": en_AE,
};
