import Vue from "vue";
import Vuex from "vuex";
import { SELECTED_PRODUCT, PRODUCTS, BASE_PRICES } from "../constants";
const camelCase = require("camelcase");
const activePromotion = process.env.VUE_APP_ACTIVE_PROMOTION || "";
const activePromotionCamelCase = camelCase(
  process.env.VUE_APP_ACTIVE_PROMOTION
);
Vue.use(Vuex);

// console.log(activePromotionCamelCase);
export const store = new Vuex.Store({
  state: {
    selectedPricingModel: "ONE_TIME",
    activePromotion,
    activePromotionCamelCase,
    hasDiscount: false,
    uniqueDiscount: {
      selectedProductDiscountedPrice: null,
      vahaSdiscountedPrice: null,
      vahaXdiscountedPrice: null,
      value: null,
      vahaSformattedBasePrice: null,
      vahaXformattedBasePrice: null,
      selectedProductBasePriceFormatted: null,
    },
    basePrices: BASE_PRICES,
    experimentRunning: false,
    vahaSEnabled: true,
    promoBoxVariant: "WITH_REVIEWS",
    selectedProduct: SELECTED_PRODUCT,
    products: PRODUCTS,
    environment: "development",
    experimentsRunning: {
      EMmfUn4VQcibwYfV7XLi0w: false,
    },
    checkoutId: "",
    discountDataFromShopify: {
      shopifyDiscountValid: false,
      shopifyDiscountData: {
        code: null,
        id: null,
        priceRule: {
          ends_at: null,
          starts_at: null,
          value: null,
          value_type: null,
        },
      },
    },
  },
  mutations: {
    setSelectedPricingModel(state, model) {
      state.selectedPricingModel = model;
    },
    setSelectedProduct(state, index) {
      state.selectedProduct = state.products[index];
    },
    setExperimentRunning(state, experimentID) {
      state.experimentsRunning[experimentID] = true;
    },
    setIsExperimentRunning(state, status) {
      state.experimentRunning = status;
    },
    setPromoBoxVariant(state, variant) {
      state.promoBoxVariant = variant;
    },
    setEnvironment(state, environment) {
      state.environment = environment;
    },
    setDynamicPriceAfter(state, prices) {
      const {
        vahaXPrice,
        vahaSPrice,
        selectedProductPriceAfter,
        vahaSbasePrice,
        vahaXbasePrice,
      } = prices;
      state.selectedProduct.promotions.januarySale.pricesBeforeAfter.priceAfter = selectedProductPriceAfter;
      state.products[0].promotions.januarySale.pricesBeforeAfter.priceAfter = vahaXPrice;
      state.products[1].promotions.januarySale.pricesBeforeAfter.priceAfter = vahaSPrice;
      console.log(
        vahaXPrice,
        vahaSPrice,
        selectedProductPriceAfter,
        vahaSbasePrice,
        vahaXbasePrice
      );
    },
    setCheckoutId(state, payload) {
      state.checkoutId = payload;
    },
    setDiscountDataFromShopify(state, payload) {
      state.discountDataFromShopify = payload;
    },
    setUniqueDiscountData(state, payload) {
      const {
        selectedProductDiscountedPrice,
        vahaSdiscountedPrice,
        vahaXdiscountedPrice,
        vahaSformattedBasePrice,
        vahaXformattedBasePrice,
        value,
        selectedProductBasePriceFormatted,
      } = payload;
      console.log(payload);
      state.uniqueDiscount.selectedProductDiscountedPrice = selectedProductDiscountedPrice;
      state.uniqueDiscount.vahaSdiscountedPrice = vahaSdiscountedPrice;
      state.uniqueDiscount.vahaXdiscountedPrice = vahaXdiscountedPrice;
      state.uniqueDiscount.value = value;
      state.uniqueDiscount.vahaSformattedBasePrice = vahaSformattedBasePrice;
      state.uniqueDiscount.vahaXformattedBasePrice = vahaXformattedBasePrice;
      state.uniqueDiscount.selectedProductBasePriceFormatted = selectedProductBasePriceFormatted;
      state.products[0].promotions[
        activePromotionCamelCase
      ].pricesBeforeAfter.priceBefore = vahaXformattedBasePrice;
      state.products[1].promotions[
        activePromotionCamelCase
      ].pricesBeforeAfter.priceBefore = vahaSformattedBasePrice;
    },
    setDiscountData(state, payload) {
      // console.log(payload);
      const { discountData, activePromotionCamelCase } = payload;
      const { discountCode, discountValue, hasDiscount } = discountData;
      state.hasDiscount = hasDiscount;
      state.selectedProduct.promotions[
        activePromotionCamelCase
      ].discount.code.promoCode = discountCode;
      state.products[0].promotions[
        activePromotionCamelCase
      ].discount.code.promoCode = discountCode;
      state.products[1].promotions[
        activePromotionCamelCase
      ].discount.code.promoCode = discountCode;

      state.selectedProduct.promotions[
        activePromotionCamelCase
      ].discount.amount = discountValue;
      state.products[0].promotions[
        activePromotionCamelCase
      ].discount.amount = discountValue;
      state.products[1].promotions[
        activePromotionCamelCase
      ].discount.amount = discountValue;
    },
  },
});
