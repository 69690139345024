export default {
  methods: {
    closeBasket() {
      this.$root.$emit("linkClicked");
      if (window.innerWidth < 768) {
        this.$root.$emit("close");
      }
    },
  },
};
