import { CHECKOUT_LINKS, BASE_PRICES } from "../constants";
import { store } from "../store";
import dayjs from "dayjs";
import axios from "axios";
import queryString from "query-string";
const camelCase = require("camelcase");
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default {
  methods: {
    async prepareCheckoutLink(
      selectedProductId,
      selectedRegionScoped,
      isProductFlatrateScoped
    ) {
      let newLink = "";
      // console.log(
      //   selectedProductId,
      //   selectedRegionScoped,
      //   isProductFlatrateScoped,
      //   this.environment
      // );
      const selectedRegionLowerCase = selectedRegionScoped.toLowerCase();

      // DACH flatrate product
      if (isProductFlatrateScoped && selectedRegionLowerCase !== "uk") {
        return this.prepareFlatrateLink(
          selectedProductId,
          selectedRegionLowerCase,
          this.environment
        );
      }

      // not flatrate - including UK
      if (
        !isProductFlatrateScoped ||
        (isProductFlatrateScoped && selectedRegionLowerCase === "uk")
      ) {
        if (selectedRegionLowerCase === "uk") {
          // attention - for JANUARY SALE promo, for UK, we append the discount code dynamically
          newLink = await this.prepareUKlinkWithDynamicDiscount(
            selectedProductId,
            selectedRegionLowerCase,
            this.activePromotionCamelCase
          );
          // newLink = this.prepareUKlink(
          //   selectedProductId,
          //   selectedRegionLowerCase,
          //   this.activePromotionCamelCase
          // );
        }

        // for DACH we show from parent (for now)
        else {
          if (selectedProductId == "1") {
            //newLink = 'https://checkout-staging-your.vaha.com/payment-options?discount_code=VALENTINSTAG';
            newLink =
              CHECKOUT_LINKS[this.environment][selectedRegionLowerCase][
                this.activePromotionCamelCase
              ].vahaX;
          } else if (selectedProductId == 2 || selectedProductId == "2") {
            newLink =
              CHECKOUT_LINKS[this.environment][selectedRegionLowerCase][
                this.activePromotionCamelCase
              ].vahaS;
          }
        }
      }

      // replace link for the experiment
      console.log(this.experimentsRunning);
      if (
        this.experimentsRunning.EMmfUn4VQcibwYfV7XLi0w === true ||
        this.experimentsRunning.oHdPzUTeSRKo3nmfWLGSIw === true ||
        this.experimentsRunning.QwuifDbCQHOJMStwjrvedQ === true
      ) {
        console.log("basket link replaced");
        if (selectedProductId == 1) {
          newLink =
            "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1";
        } else if (selectedProductId == 2) {
          newLink =
            "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1";
        }
      }

      if (this.experimentsRunning.ij4EkYI0QfubwM58_nOvcQ === true) {
        console.log("basket link replaced for primer");
        if (selectedProductId == 1) {
          newLink = "https://your-checkout.vaha.com?country=uk";
        } else if (selectedProductId == 2) {
          newLink = "https://your-checkout.vaha.com?country=uk&model=vaha-s";
        }
      }

      // optionally pass all the GET parameters + validate characted ? vs &
      console.log(newLink);
      const discountData = await this.determineDiscountCodeAndValue(
        "products.productX.type",
        selectedRegionLowerCase
      );

      let GETparams = window.location.search;
      console.log(GETparams);
      if (GETparams != null && GETparams.length > 0) {
        if (newLink.includes("?")) {
          GETparams = GETparams.substring(1);
          newLink += `&${GETparams}`;
        } else {
          newLink += GETparams;
        }
      }
      console.log(newLink);
      console.log(discountData.discountCode);
      console.log(this.$te(discountData.discountCode));
      if (this.$te(discountData.discountCode)) {
        console.log("should enter");
        const discountCode = this.$t(discountData.discountCode);
        if (newLink.includes("?")) {
          newLink += `&discount=${discountCode}`;
        } else {
          newLink += `?discount=${discountCode}`;
        }
      }

      if (newLink.includes("discount_code")) {
        newLink = newLink.replace("discount_code", "discount");
      }

      // if (this.experimentsRunning.ij4EkYI0QfubwM58_nOvcQ === true) {
      //   console.log("basket link replaced for primer");
      //   newLink = newLink.replace("discount", "discount_code");
      // }

      console.log(newLink);

      // remove duplicates
      let [path, params] = newLink.split("?");
      console.log(path);
      console.log(params);
      let result =
        path +
        "?" +
        new URLSearchParams(
          Object.fromEntries(new URLSearchParams(params))
        ).toString();

      console.log(result);
      // if (this.$te(discountData.discountCode)) {

      //}
      // selectedRegionLowerCase replace discount_code to discount

      return result;
    },
    prepareUKlink(selectedProductId, selectedRegionLowerCase, promotionKey) {
      let newLink = "";

      if (selectedProductId == "1") {
        newLink =
          CHECKOUT_LINKS[this.environment][selectedRegionLowerCase][
            promotionKey
          ].vahaX;
      }
      if (selectedProductId == "2") {
        newLink =
          CHECKOUT_LINKS[this.environment][selectedRegionLowerCase][
            promotionKey
          ].vahaS;
      }
      return newLink;
    },
    async prepareUKlinkWithDynamicDiscount(
      selectedProductId,
      selectedRegionLowerCase,
      promotionKey
    ) {
      let newLink = "";
      const selectedProductType = store.state.selectedProduct.type;
      const discountData = await this.determineDiscountCodeAndValue(
        selectedProductType,
        selectedRegionLowerCase
      );
      console.log(CHECKOUT_LINKS);
      console.log(this.environment);
      console.log(selectedRegionLowerCase);
      console.log(promotionKey);
      console.log(
        CHECKOUT_LINKS[this.environment][selectedRegionLowerCase][promotionKey]
      );
      if (selectedProductId == "1") {
        newLink = `${
          CHECKOUT_LINKS[this.environment][selectedRegionLowerCase][
            promotionKey
          ].vahaX
        }`;
      }
      if (selectedProductId == "2") {
        newLink = `${
          CHECKOUT_LINKS[this.environment][selectedRegionLowerCase][
            promotionKey
          ].vahaS
        }`;
      }
      console.log(newLink);
      if (this.$te(discountData.discountCode)) {
        const discountCode = this.$t(discountData.discountCode);
        newLink += `?discount=${discountCode}`;
      }
      console.log(newLink);

      return newLink;
    },
    prepareFlatrateLink(
      selectedProductId,
      selectedRegionLowerCase,
      environment
    ) {
      let newLink = "";

      if (selectedProductId == "1") {
        // newLink =
        //   "https://my-checkout.vaha.com/payment-options?discount_code=VAHANEUJAHR";
        // newLink =
        //   "https://checkout-staging-my.vaha.com/payment-options?discount_code=VAHANEUJAHR";
        newLink =
          CHECKOUT_LINKS[environment][selectedRegionLowerCase].flatrate.vahaX;
      } else if (selectedProductId == "2") {
        newLink =
          CHECKOUT_LINKS[environment][selectedRegionLowerCase].flatrate.vahaS;
        // newLink =
        //   "https://my-checkout.vaha.com/payment-options?model=vaha-s&discount_code=VAHANEUJAHR";
        // newLink =
        //   "https://checkout-staging-my.vaha.com/payment-options?model=vaha-s&discount_code=VAHANEUJAHR";
      }
      return newLink;
    },
    async determineDiscountCodeAndValue(
      selectedProductType = "products.productS.type",
      selectedRegionScoped
    ) {
      // 1-31 - day of month
      console.log(selectedProductType);
      const dayNumber = this.determineDayNumber();
      let vahaXPrice =
        "products.productX.promotions.wellnessSale.pricesBeforeAfter.priceAfter";
      let vahaSPrice =
        "products.productS.promotions.wellnessSale.pricesBeforeAfter.priceAfter";
      let discountValue = null;
      let discountCode = null;
      // depending on the day of the month, we have different promotions
      if (process.env.VUE_APP_ACTIVE_PROMOTION === "JANUARY_SALE") {
        if (dayNumber === "24" || dayNumber === "25") {
          discountValue = "- £100";
          discountCode = "JANSALE100";
          vahaXPrice = "£1,850 (incl. VAT)";
          vahaSPrice = "£1,095 (incl. VAT)";
        } else if (dayNumber === "26" || dayNumber === "27") {
          discountValue = "- £125";
          discountCode = "JANSALE125";
          vahaXPrice = "£1,825 (incl. VAT)";
          vahaSPrice = "£1,070 (incl. VAT)";
        } else if (dayNumber === "28" || dayNumber === "29") {
          discountValue = "- £150";
          discountCode = "JANSALE150";
          vahaXPrice = "£1,800 (incl. VAT)";
          vahaSPrice = "£1,045 (incl. VAT)";
        } else if (dayNumber === "30" || dayNumber === "31") {
          discountValue = "- £125";
          discountCode = "JANSALE125";
          vahaXPrice = "£1,825 (incl. VAT)";
          vahaSPrice = "£1,070 (incl. VAT)";
        }
      }
      console.log(selectedRegionScoped);
      const parsedGETparams = queryString.parse(window.location.search);
      if (parsedGETparams != null && parsedGETparams.discount_code != null) {
        const updatedData = await this.calculateDiscountBasedOnShopifyCode(
          parsedGETparams.discount_code,
          selectedRegionScoped,
          selectedProductType,
          true
        );
        discountValue = updatedData.value;
        discountCode = parsedGETparams.discount_code;
      } else if (parsedGETparams.discount_code == null) {
        if (selectedProductType === "products.productX.type") {
          discountValue = `products.productX.promotions.${this.activePromotionCamelCase}.discount.amount`;
          discountCode = `products.productX.promotions.${this.activePromotionCamelCase}.discount.code.promoCode`;
        } else if (selectedProductType === "products.productS.type") {
          discountValue = `products.productS.promotions.${this.activePromotionCamelCase}.discount.amount`;
          discountCode = `products.productS.promotions.${this.activePromotionCamelCase}.discount.code.promoCode`;
        }

        console.log(this.$t(discountCode));
        console.log(discountCode);
        const updatedData = await this.calculateDiscountBasedOnShopifyCode(
          this.$t(discountCode),
          selectedRegionScoped,
          selectedProductType,
          false
        );
        discountValue = (updatedData && updatedData.value) || discountValue;
      }

      console.log(discountValue, discountCode);
      let hasDiscount = false;
      if (discountValue != null && discountCode != null) {
        hasDiscount = true;
      }

      return {
        hasDiscount,
        discountValue,
        discountCode,
        vahaSPrice,
        vahaXPrice,
      };
    },
    determineDayNumber() {
      return dayjs.utc().format("DD");
    },
    async calculateDiscountBasedOnShopifyCode(
      shopifyDiscountCode,
      selectedRegionScoped,
      selectedProductType,
      isFromUrl = false
    ) {
      try {
        const selectedRegionLowerCase = selectedRegionScoped.toLowerCase();

        console.log(selectedRegionLowerCase);
        console.log(shopifyDiscountCode);
        let vahaStoreCountry = "";
        let regionI18Ncode = "";
        let regionI18Ncurrency = "";
        let staticCode = null

        if (selectedRegionLowerCase === "uk") {
          vahaStoreCountry = "uk";
          regionI18Ncode = "en-GB";
          regionI18Ncurrency = "GBP";
          staticCode = this.discountCodeUK;
        }

        if (selectedRegionLowerCase === "ae") {
          vahaStoreCountry = "uae";
          regionI18Ncode = "en-AE";
          regionI18Ncurrency = "AED";
          staticCode = this.discountCodeAE;
        }

        if (selectedRegionLowerCase === "de" || selectedRegionLowerCase === "at") {
           vahaStoreCountry = "eu";
           regionI18Ncode = "de-DE";
           regionI18Ncurrency = "EUR";
           staticCode = this.discountCodeDACH;
        }
        console.log(`static code ${staticCode} region ${regionI18Ncode}`);
        if (staticCode == null || !staticCode) {
          return;
        }
        const discountCode = isFromUrl ? shopifyDiscountCode : staticCode;
        const shopifyDiscountData = await axios.get(
          `https://vaha-api-production.herokuapp.com/system/api/v1/shopify/discount_status?discount_code=${discountCode}&vaha_model=vaha_x&vaha_store_country=${vahaStoreCountry}`
        );
        const payload = { shopifyDiscountValid: true, shopifyDiscountData };
        store.commit("setDiscountDataFromShopify", payload);

        const shopifyPricerule = shopifyDiscountData.data.price_rule;
        const valueType = shopifyPricerule.value_type;
        const value = shopifyPricerule.value;

        const selectedProductCamelCase = camelCase(
          this.$root.$t(selectedProductType)
        );
        const vahaXbasePrice = BASE_PRICES[selectedRegionLowerCase].vahaX;
        const vahaSbasePrice = BASE_PRICES[selectedRegionLowerCase].vahaS;
        const selectedProductBasePrice =
          BASE_PRICES[selectedRegionLowerCase][selectedProductCamelCase];

        let selectedProductDiscountedPrice,
          vahaXdiscountedPrice,
          vahaSdiscountedPrice,
          discountValue;
        if (valueType === "percentage") {
          // calculate percentage
          console.log('')
          selectedProductDiscountedPrice =
            (window.Math.floor((selectedProductBasePrice) * (100 + parseFloat(value))) /
            100);
          vahaXdiscountedPrice =
            (window.Math.floor(vahaXbasePrice) * (100 + parseFloat(value))) / 100;
          vahaSdiscountedPrice =
            (window.Math.floor(vahaSbasePrice) * (100 + parseFloat(value))) / 100;
          console.log(selectedProductDiscountedPrice);
          selectedProductDiscountedPrice = window.Math.floor(selectedProductDiscountedPrice);
          console.log(selectedProductDiscountedPrice);
          discountValue = `${this.formatAmount(
            value,
            regionI18Ncode,
            false,
            regionI18Ncurrency,
            false
          )}%`;
        } else if (valueType === "fixed_amount") {
          selectedProductDiscountedPrice =
            parseFloat(selectedProductBasePrice) + parseFloat(value);
          vahaXdiscountedPrice = parseFloat(vahaXbasePrice) + parseFloat(value);
          vahaSdiscountedPrice = parseFloat(vahaSbasePrice) + parseFloat(value);
          discountValue = this.formatAmount(
            value,
            regionI18Ncode,
            false,
            regionI18Ncurrency,
            true
          );
        }
        // format updatedprices
        const selectedProductFormattedPrice = this.formatAmount(
          selectedProductDiscountedPrice,
          regionI18Ncode,
          false,
          regionI18Ncurrency,
          true
        );
        const vahaXformattedPrice = this.formatAmount(
          vahaXdiscountedPrice,
          regionI18Ncode,
          false,
          regionI18Ncurrency,
          true
        );
        const vahaSformattedPrice = this.formatAmount(
          vahaSdiscountedPrice,
          regionI18Ncode,
          false,
          regionI18Ncurrency,
          true
        );

        const vahaSformattedBasePrice = this.formatAmount(
          vahaSbasePrice,
          regionI18Ncode,
          false,
          regionI18Ncurrency,
          true
        );

        const vahaXformattedBasePrice = this.formatAmount(
          vahaXbasePrice,
          regionI18Ncode,
          false,
          regionI18Ncurrency,
          true
        );

        const selectedProductBasePriceFormatted = this.formatAmount(
          selectedProductBasePrice,
          regionI18Ncode,
          false,
          regionI18Ncurrency,
          true
        );

        const response = {
          selectedProductDiscountedPrice: selectedProductFormattedPrice,
          vahaSdiscountedPrice: vahaSformattedPrice,
          vahaXdiscountedPrice: vahaXformattedPrice,
          vahaSformattedBasePrice,
          vahaXformattedBasePrice,
          value: discountValue,
          selectedProductBasePriceFormatted,
        };

        store.commit("setUniqueDiscountData", response);
        return response;
      } catch (e) {
        console.log(e);
        if (e.response.status === 404) {
          console.log("discount code not found");
        }
        if (e.response.data.error === 409) {
          console.log("the discount code is not valid for this price rule");
        }
        console.log(e.response.data.error);
      }
    },
    formatAmount(amount, locale, withDecimals, currency, withCurrency = true) {
      // if the amount is e.g 95.50 it is rounded to the nearest (smaller) integer: 95
      // if (Number.isInteger(amount - 0.5)) amount = Math.floor(amount);
      const props = {
        maximumSignificantDigits: 4,
      };
      if (withCurrency) {
        (props.style = "currency"), (props.currency = currency);
      }
      if (!withDecimals) {
        props.minimumFractionDigits = 0;
        props.maximumFractionDigits = 0;
      }
      return Intl.NumberFormat(locale, props).format(amount);
    },
  },
  computed: {
    environment() {
      return store.state.environment;
    },
    discountCodeUK() {
      return process.env.VUE_APP_DISCOUNT_CODE_UK;
    },
    discountCodeAE() {
      return process.env.VUE_APP_DISCOUNT_CODE_AE;
    },
    discountCodeDACH() {
      return process.env.VUE_APP_DISCOUNT_CODE_DACH;
    },
    selectedProductType() {
      return store.state.selectedProduct.type;
    },
    activePromotionCamelCase() {
      return store.state.activePromotionCamelCase;
    },
    experimentsRunning() {
      return store.state.experimentsRunning;
    },
  },
};
