<template>
  <div :class="[$style.container]">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" module>
@import 'src/static/styles/bulma-variables';

$container-side-padding: 25px;

.container {
  padding: 0 $container-side-padding;
  margin: 0 auto;

  @include desktop {
    max-width: 1160px + 2 * $container-side-padding;
  }

  @include widescreen {
    max-width: 1560px + 2 * $container-side-padding;
  }
}
</style>
