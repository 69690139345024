<template>
  <section class="vahaxmas-container">
    <BasketCollapse
      :id="activePromotion"
      :title="$t(content.heading.title)"
      :icon="icon"
    >
      <div
        class="is-flex is-justify-content-space-between is-align-items-flex-end is-flex-direction-column"
      >
        <BasketRowItem
          :key="item.title"
          v-for="item of filteredItems"
          :item="item"
          :region="region"
        />
      </div>
    </BasketCollapse>
  </section>
</template>

<script>
import { store } from "../../store/index";
import BasketCollapse from "../BasketCollapse.vue";
import BasketRowItem from "../BasketRowItem.vue";
export default {
  name: "VahaXmas",
  props: {
    region: { type: String, default: "UK" },
    flatrate: { type: Boolean, default: false },
    icon: { type: String, default: "info" },
  },
  components: {
    BasketCollapse,
    BasketRowItem,
  },
  computed: {
    content() {
      const activePromotionCamelCase = store.state.activePromotionCamelCase;
      return store.state.selectedProduct.promotions[activePromotionCamelCase];
      //   if (this.flatrate) {
      //     return store.state.selectedProduct.promotions.flatrateSpringIntoShape;
      //   } else {
      //     return store.state.selectedProduct.promotions[activePromotionCamelCase];
      //   }
    },
    activePromotion() {
      return process.env.VUE_APP_ACTIVE_PROMOTION;
    },
    filteredItems() {
      const items = [];
      const selectedProductId = this.$root.$t(store.state.selectedProduct.id);
      let storeItemsPath = "";
      const activePromotionCamelCase = store.state.activePromotionCamelCase;
console.log(activePromotionCamelCase)
      if (selectedProductId === "1") {
        storeItemsPath = this.flatrate
          ? "products.productX.promotions.flatrateSpringIntoShape.items"
          : "products.productX.promotions.vahaxmas.items";
      } else if (selectedProductId === "2") {
        storeItemsPath = this.flatrate
          ? "products.productS.promotions.flatrateSpringIntoShape.items"
          : "products.productS.promotions.vahaxmas.items";
      }

      for (let i = 1; i < 10; i++) {
        const item = `${storeItemsPath}.item${i}`;
        const string = `item${i}`;
        console.log(this.$root.$t(`${item}.title`));
        console.log(`${item}.title`);
        if (this.$root.$te(`${item}.title`)) {
          const itemToPush = this.flatrate
            ? store.state.selectedProduct.promotions.flatrateSpringIntoShape
                .items[string]
            : store.state.selectedProduct.promotions[activePromotionCamelCase]
                .items[string];
          items.push(itemToPush);
        }
      }
      console.log(
        store.state.selectedProduct.promotions[activePromotionCamelCase].items
      );
      console.log(items);
      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
.vahaxmas-container {
  .font-18 {
    font-size: 18px;
    line-height: 27px;
  }

  .font-16 {
    font-size: 16px;
    line-height: 24px;
  }

  .font-15 {
    font-size: 15px;
    line-height: 24px;
  }

  .basket-wrapper {
    display: flex;
    justify-content: space-between;

    .basket-title {
      flex-direction: column;
      padding-right: 20px;
      margin-top: 0;
    }
  }

  .vahaxmas-image {
    max-height: 64px;
    width: auto;
  }

  .text-strikethrough {
    text-decoration: line-through;
  }

  .font-12 {
    font-size: 12px;
    line-height: 150%;
  }

  .font-700 {
    font-weight: 700;
  }

  .font-400 {
    font-weight: 400;
  }
  .font-grey {
    color: #828282;
  }

  .min-width-50 {
    min-width: 50px;
  }
}
</style>
