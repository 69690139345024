<template>
  <section class="width-100 is-flex mt-3 mb-3 is-justify-content-space-between">
    <div class="font-700">
      <p
        v-if="selectedRegion === 'DE' || selectedRegion === 'AT'"
        class="font-16 has-text-black mr-2 line-height-21"
      >
        Gesamtbetrag
      </p>
      <p v-else class="font-16 has-text-black mr-2 line-height-21">Total</p>
    </div>
    <template v-if="selectedPricingModel === 'ONE_TIME'">
      <div class="font-700 has-text-right is-flex is-flex-direction-column">
        <div
          class="is-flex line-height-21 is-align-items-flex-end is-justify-content-flex-end"
        >
          <p
            v-if="uniqueDiscount.selectedProductBasePriceFormatted != null"
            class="font-16 has-text-black mr-2 has-text-strikethrough line-height-21"
          >
            {{ selectedProduct.payUpfront.prices.original }}
          </p>
        </div>
        <!-- <div
          class="is-flex line-height-21 is-align-items-flex-end is-justify-content-flex-end"
        >
          <p class="mt-1 mb-1" style="font-weight:400;font-size: 16px;">
            SUMMERPROJECT23 -15%
          </p>
        </div> -->
        <div
          class="is-flex line-height-21 is-align-items-flex-end is-justify-content-flex-end"
        >
          <p class="font-22 has-text-primary line-height-21 mb-1">
            {{ selectedProduct.payUpfront.prices.original }}
          </p>
        </div>

        <span class="font-14" style="color:#828282;">(inkl. MwSt.)</span>
      </div>
    </template>
    <template v-if="selectedPricingModel === 'INSTALLMENTS'">
      <div class="is-flex has-text-right is-flex is-flex-direction-column">
        <p class="font-700 font-22">
          {{ selectedProduct.payUpfront.prices.monthlyBare }}
        </p>
        <p style="color:#777E90;font-size: 16px" class="mt-1 mb-1">
          (inkl. MwSt)
        </p>
        <p style="color:#777E90;font-size: 18px">für 12 Monate</p>
      </div>
    </template>
  </section>
</template>

<script>
import { store } from "../store";
// import InfoIcon from "../icons/info-icon";
export default {
  name: "BasketPricesRowV2",
  props: {
    prices: { type: Object, default: () => {} },
    selectedRegion: { type: String, default: "DE" },
    uniqueDiscountSelectedProduct: { type: String, default: null },
  },
  // components: {
  //   InfoIcon,
  // },
  data() {
    return {
      isCardModalActive: false,
    };
  },
  methods: {
    handleModalOpen() {
      console.log("click");
      this.isCardModalActive = true;
    },
  },
  computed: {
    activePromotion() {
      return store.state.activePromotion;
    },
    uniqueDiscount() {
      return store.state.uniqueDiscount;
    },
    selectedPricingModel() {
      return store.state.selectedPricingModel;
    },
    selectedProduct() {
      return store.state.selectedProduct;
    },
  },
};
</script>

<style lang="scss">
.width-100 {
  width: 100%;
}

.font-700 {
  font-weight: 700;
}

.font-16 {
  font-size: 16px;
  line-height: 150%;
}

.font-22 {
  font-size: 22px;
  line-height: 150%;
}

.has-text-black {
  color: black;
}

.has-text-primary {
  color: #f69e1e;
}

.has-text-grey {
  color: #222;
}

.has-text-strikethrough {
  text-decoration: line-through;
}

.line-height-21 {
  line-height: 21px !important;
}
</style>
