export default {
  methods: {
    prepareAllowedPaymentName(name) {
      if (name !== "splitit") {
        return `https://uk-vaha.s3.eu-central-1.amazonaws.com/static/assets/icons/${name}.svg`;
      } else {
        return `https://uk-vaha.s3.eu-central-1.amazonaws.com/static/assets/icons/${name}.png`;
      }
    },
  },
};
