export const mayDaySaleProductS = {
    mainPromoHeadline:
      "products.product.promotions.mayDaySale.mainPromoHeadline",
    heading: {
      image: "products.productS.promotions.mayDaySale.heading.image",
      title: "products.productS.promotions.mayDaySale.heading.title",
    },
    pricesBeforeAfter: {
      priceBefore:
        "products.productS.promotions.mayDaySale.pricesBeforeAfter.priceBefore",
      priceAfter:
        "products.productS.promotions.mayDaySale.pricesBeforeAfter.priceAfter",
    },
    footer: "products.productS.promotions.mayDaySale.footer",
    total: {
      headline: "products.productS.promotions.mayDaySale.total.headline",
      priceBefore: "products.productS.promotions.mayDaySale.total.priceBefore",
      priceAfter: "products.productS.promotions.mayDaySale.total.priceAfter",
    },
    discount: {
      heading: "products.productS.promotions.mayDaySale.discount.heading",
      title: "products.productS.promotions.mayDaySale.discount.title",
      amount: "products.productS.promotions.mayDaySale.discount.amount",
      code: {
        description:
          "products.productS.promotions.mayDaySale.discount.code.description",
        promoCode:
          "products.productS.promotions.mayDaySale.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title: "products.productS.promotions.mayDaySale.items.item1.title",
        description:
          "products.productS.promotions.mayDaySale.items.item1.description",
        priceBefore:
          "products.productS.promotions.mayDaySale.items.item1.priceBefore",
        priceAfter:
          "products.productS.promotions.mayDaySale.items.item1.priceAfter",
      },
      item2: {
        title: "products.productS.promotions.mayDaySale.items.item2.title",
        description:
          "products.productS.promotions.mayDaySale.items.item2.description",
        priceBefore:
          "products.productS.promotions.mayDaySale.items.item2.priceBefore",
        priceAfter: "products.productS.promotions.mayDaySale.items.item2.priceAfter",
      },
      item3: {
        title: "products.productS.promotions.mayDaySale.items.item3.title",
        description:
          "products.productS.promotions.mayDaySale.items.item3.description",
        priceBefore:
          "products.productS.promotions.mayDaySale.items.item3.priceBefore",
        priceAfter:
          "products.productS.promotions.mayDaySale.items.item3.priceAfter",
      },
      item4: {
        title: "products.productS.promotions.mayDaySale.items.item4.title",
        description:
          "products.productS.promotions.mayDaySale.items.item4.description",
        priceBefore:
          "products.productS.promotions.mayDaySale.items.item4.priceBefore",
        priceAfter:
          "products.productS.promotions.mayDaySale.items.item4.priceAfter",
      },
      item5: {
        title: "products.productS.promotions.mayDaySale.items.item5.title",
        description:
          "products.productS.promotions.mayDaySale.items.item5.description",
        priceBefore:
          "products.productS.promotions.mayDaySale.items.item5.priceBefore",
        priceAfter:
          "products.productS.promotions.mayDaySale.items.item5.priceAfter",
      },
    },
  };
  
  export const flatrateMayDaySaleProductS = {
    mainPromoHeadline:
      "products.productS.promotions.flatrateMayDaySale.mainPromoHeadline",
    heading: {
      image: "products.productS.promotions.flatrateMayDaySale.heading.image",
      title: "products.productS.promotions.flatrateMayDaySale.heading.title",
    },
    footer: "products.productS.promotions.flatrateMayDaySale.footer",
    total: {
      headline:
        "products.productS.promotions.flatrateMayDaySale.total.headline",
      priceBefore:
        "products.productS.promotions.flatrateMayDaySale.total.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateMayDaySale.total.priceAfter",
    },
    discount: {
      heading: "products.productS.promotions.mayDaySale.discount.heading",
      title: "products.productS.promotions.flatrateMayDaySale.discount.title",
      amount: "products.productS.promotions.flatrateMayDaySale.discount.amount",
      code: {
        description:
          "products.productS.promotions.flatrateMayDaySale.discount.code.description",
        promoCode:
          "products.productS.promotions.flatrateMayDaySale.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title:
          "products.productS.promotions.flatrateMayDaySale.items.item1.title",
        description:
          "products.productS.promotions.flatrateMayDaySale.items.item1.description",
        priceBefore:
          "products.productS.promotions.flatrateMayDaySale.items.item1.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateMayDaySale.items.item1.priceAfter",
      },
      item2: {
        title:
          "products.productS.promotions.flatrateMayDaySale.items.item2.title",
        description:
          "products.productS.promotions.flatrateMayDaySale.items.item2.description",
        priceBefore:
          "products.productS.promotions.flatrateMayDaySale.items.item2.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateMayDaySale.items.item2.priceAfter",
      },
      item3: {
        title:
          "products.productS.promotions.flatrateMayDaySale.items.item3.title",
        description:
          "products.productS.promotions.flatrateMayDaySale.items.item3.description",
        priceBefore:
          "products.productS.promotions.flatrateMayDaySale.items.item3.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateMayDaySale.items.item3.priceAfter",
      },
      item4: {
        title:
          "products.productS.promotions.flatrateMayDaySale.items.item4.title",
        description:
          "products.productS.promotions.flatrateMayDaySale.items.item4.description",
        priceBefore:
          "products.productS.promotions.flatrateMayDaySale.items.item4.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateMayDaySale.items.item4.priceAfter",
      },
      item5: {
        title:
          "products.productS.promotions.flatrateMayDaySale.items.item5.title",
        description:
          "products.productS.promotions.flatrateMayDaySale.items.item5.description",
        priceBefore:
          "products.productS.promotions.flatrateMayDaySale.items.item5.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateMayDaySale.items.item5.priceAfter",
      },
      item6: {
        title:
          "products.productS.promotions.flatrateMayDaySale.items.item6.title",
        description:
          "products.productS.promotions.flatrateMayDaySale.items.item6.description",
        priceBefore:
          "products.productS.promotions.flatrateMayDaySale.items.item6.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateMayDaySale.items.item6.priceAfter",
      },
    },
  };
  
  export const mayDaySaleProductX = {
    mainPromoHeadline:
      "products.productX.promotions.mayDaySale.mainPromoHeadline",
    heading: {
      image: "products.productX.promotions.mayDaySale.heading.image",
      title: "products.productX.promotions.mayDaySale.heading.title",
    },
    pricesBeforeAfter: {
      priceBefore:
        "products.productX.promotions.mayDaySale.pricesBeforeAfter.priceBefore",
      priceAfter:
        "products.productX.promotions.mayDaySale.pricesBeforeAfter.priceAfter",
    },
    footer: "products.productX.promotions.mayDaySale.footer",
    total: {
      headline: "products.productX.promotions.mayDaySale.total.headline",
      priceBefore: "products.productX.promotions.mayDaySale.total.priceBefore",
      priceAfter: "products.productX.promotions.mayDaySale.total.priceAfter",
    },
    discount: {
      heading: "products.productX.promotions.mayDaySale.discount.heading",
      title: "products.productX.promotions.mayDaySale.discount.title",
      amount: "products.productX.promotions.mayDaySale.discount.amount",
      code: {
        description:
          "products.productX.promotions.mayDaySale.discount.code.description",
        promoCode:
          "products.productX.promotions.mayDaySale.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title: "products.productX.promotions.mayDaySale.items.item1.title",
        description:
          "products.productX.promotions.mayDaySale.items.item1.description",
        priceBefore:
          "products.productX.promotions.mayDaySale.items.item1.priceBefore",
        priceAfter:
          "products.productX.promotions.mayDaySale.items.item1.priceAfter",
      },
      item2: {
        title: "products.productX.promotions.mayDaySale.items.item2.title",
        description:
          "products.productX.promotions.mayDaySale.items.item2.description",
        priceBefore:
          "products.productX.promotions.mayDaySale.items.item2.priceBefore",
        priceAfter: "products.productX.promotions.mayDaySale.items.item2.priceAfter",
      },
      item3: {
        title: "products.productX.promotions.mayDaySale.items.item3.title",
        description:
          "products.productX.promotions.mayDaySale.items.item3.description",
        priceBefore:
          "products.productX.promotions.mayDaySale.items.item3.priceBefore",
        priceAfter:
          "products.productX.promotions.mayDaySale.items.item3.priceAfter",
      },
      item4: {
        title: "products.productX.promotions.mayDaySale.items.item4.title",
        description:
          "products.productX.promotions.mayDaySale.items.item4.description",
        priceBefore:
          "products.productX.promotions.mayDaySale.items.item4.priceBefore",
        priceAfter:
          "products.productX.promotions.mayDaySale.items.item4.priceAfter",
      },
      item5: {
        title: "products.productX.promotions.mayDaySale.items.item5.title",
        description:
          "products.productX.promotions.mayDaySale.items.item5.description",
        priceBefore:
          "products.productX.promotions.mayDaySale.items.item5.priceBefore",
        priceAfter:
          "products.productX.promotions.mayDaySale.items.item5.priceAfter",
      },
    },
  };
  
  export const flatrateMayDaySaleProductX = {
    mainPromoHeadline:
      "products.productX.promotions.flatrateMayDaySale.mainPromoHeadline",
    heading: {
      image: "products.productX.promotions.flatrateMayDaySale.heading.image",
      title: "products.productX.promotions.flatrateMayDaySale.heading.title",
    },
    footer: "products.productX.promotions.flatrateMayDaySale.footer",
    total: {
      headline:
        "products.productX.promotions.flatrateMayDaySale.total.headline",
      priceBefore:
        "products.productX.promotions.flatrateMayDaySale.total.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateMayDaySale.total.priceAfter",
    },
    discount: {
      heading: "products.productX.promotions.mayDaySale.discount.heading",
      title: "products.productX.promotions.flatrateMayDaySale.discount.title",
      amount: "products.productX.promotions.flatrateMayDaySale.discount.amount",
      code: {
        description:
          "products.productX.promotions.flatrateMayDaySale.discount.code.description",
        promoCode:
          "products.productX.promotions.flatrateMayDaySale.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title:
          "products.productX.promotions.flatrateMayDaySale.items.item1.title",
        description:
          "products.productX.promotions.flatrateMayDaySale.items.item1.description",
        priceBefore:
          "products.productX.promotions.flatrateMayDaySale.items.item1.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateMayDaySale.items.item1.priceAfter",
      },
      item2: {
        title:
          "products.productX.promotions.flatrateMayDaySale.items.item2.title",
        description:
          "products.productX.promotions.flatrateMayDaySale.items.item2.description",
        priceBefore:
          "products.productX.promotions.flatrateMayDaySale.items.item2.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateMayDaySale.items.item2.priceAfter",
      },
      item3: {
        title:
          "products.productX.promotions.flatrateMayDaySale.items.item3.title",
        description:
          "products.productX.promotions.flatrateMayDaySale.items.item3.description",
        priceBefore:
          "products.productX.promotions.flatrateMayDaySale.items.item3.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateMayDaySale.items.item3.priceAfter",
      },
      item4: {
        title:
          "products.productX.promotions.flatrateMayDaySale.items.item4.title",
        description:
          "products.productX.promotions.flatrateMayDaySale.items.item4.description",
        priceBefore:
          "products.productX.promotions.flatrateMayDaySale.items.item4.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateMayDaySale.items.item4.priceAfter",
      },
      item5: {
        title:
          "products.productX.promotions.flatrateMayDaySale.items.item5.title",
        description:
          "products.productX.promotions.flatrateMayDaySale.items.item5.description",
        priceBefore:
          "products.productX.promotions.flatrateMayDaySale.items.item5.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateMayDaySale.items.item5.priceAfter",
      },
      item6: {
        title:
          "products.productX.promotions.flatrateMayDaySale.items.item6.title",
        description:
          "products.productX.promotions.flatrateMayDaySale.items.item6.description",
        priceBefore:
          "products.productX.promotions.flatrateMayDaySale.items.item6.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateMayDaySale.items.item6.priceAfter",
      },
    },
  };
  