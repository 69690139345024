<template>
  <div class="p-5 basket-wrapper-b basket-ae">
    <header
      class="is-flex is-justify-content-space-between is-align-items-center mb-4"
    >
      <h3 class="is-size-4 has-text-weight-bold">
        {{ $t("basket.headline") }}
      </h3>
      <button
        class="button is-transparent p-0 mb-0"
        @click="setSidebarOpen(false)"
      >
        <CloseIcon />
      </button>
    </header>
    <div class="basket-container">
      <div>
        <div
          class="is-flex is-justify-content-space-between is-align-items-flex-end"
        >
          <div class="basket-wrapper">
            <div class="basket-title icon-row">
              <span class="basket-title-span-holder">
                <span>{{ $t("basket.title") }}</span>
              </span>
              <span
                v-if="
                  selectedRegionScoped == 'UK' && helpMeChooseScoped == true
                "
              >
                <span
                  @click="closeBasket"
                  v-if="isNuxt == true"
                  class="help-choose"
                >
                  {{ $t("basket.helpMeChoose") }}
                </span>
                <span
                  @click="closeBasket"
                  v-if="isNuxt != true"
                  class="help-choose"
                >
                  <router-link
                    :to="{
                      hash: this.seeMoreLink.hash,
                    }"
                    v-scroll-to="{ el: this.seeMoreLink.hash }"
                    class="underlined"
                    >{{ $t("basket.helpMeChoose") }}</router-link
                  >
                </span>
              </span>
            </div>
            <div :class="'separator--light my-4'" />
            <!-- <div class="basket-promo-special mt-4 mb-4 p-5">
              <h3
                v-html="
                  $t(selectedProduct.promotions.flashSale.mainPromoHeadline)
                "
              />
            </div> -->
            <!-- Code for later with loop -->
            <!-- <div class="basket-vaha-items">
              <div
                v-for="product in products"
                :key="product.type"
                class="basket-vaha-item"
                :class="{
                  active: $t(selectedProduct.id) == $t(product.id),
                }"
                @click="
                  changeProduct(product, $t(product.id), selectedRegionScoped)
                "
              >
                <template v-if="$t(product.id) != 1">
                  <div
                    class="basket-vaha-item-image-wrapper basket-vaha-item-image-wrapper--uk"
                  >
                    <img :src="product.image" />
                    <h6 class="basket-product-title strong font-16 mt-2">
                      {{ $t(product.name) }}
                    </h6>
                  </div>
                  <div class="basket-vaha-item-product-name">

                    <p
                      class="basket-price mt-3 mb-3 has-text-strikethrough"
                      style="font-size: 14px;text-decoration-color:red;"
                    >
                      {{ $t(product.prices.direct.price) }}
                    </p>
                    <p
                      class="strong font-18 basket-price mt-3 mb-3"
                      style="font-size: 24px;"
                    >
                      {{ $t(product.prices.direct.discountPrice) }}
                    </p>
                  </div>
                </template>
              </div>
            </div> -->
            <!--  end code for later in loop -->
            <div class="basket-vaha-items">
              <div class="basket-vaha-item active">
                <template>
                  <div
                    class="basket-vaha-item-image-wrapper basket-vaha-item-image-wrapper--uk"
                  >
                    <img :src="products[1].image" />
                    <h6 class="basket-product-title strong font-16 mt-2">
                      {{ $t(products[1].name) }}
                    </h6>
                  </div>
                  <div class="basket-vaha-item-product-name">
                    <!-- <p class="strong font-18 basket-price mt-3 mb-3">
                      {{ $t(product.prices.direct.price) }}
                    </p> -->

                    <p
                      class="strong font-18 basket-price mt-3 mb-3"
                      style="font-size: 24px;"
                    >
                      {{ $t(products[1].prices.direct.price) }}
                    </p>
                    <!-- <p class="has-text-carbon-black light font-14 mt-2 mb-0">
                      {{ $t("basket.monthlyFrom") }}
                    </p>
                    <p class="strong font-26 mt-1 mb-2">
                      <span>
                        {{ $t(product.prices.recommended.price)
                        }}{{ $t(product.prices.recommended.period)
                        }}<template v-if="klarnaNon0Enabled">*</template>
                      </span>
                    </p> -->
                  </div>
                </template>
              </div>
            </div>
            <div :class="'separator--light my-4'" />
          </div>
        </div>

        <PromoBox
          :variant="promoBoxVariant"
          :showYellowBox="basketVersion !== 'UK' ? true : false"
          :selectedProduct="selectedProduct"
          :region="selectedRegionScoped"
          :experimentRunning="experimentRunning"
        />
        <!-- <div :class="'separator--light my-4'" /> -->

        <div
          class="is-flex is-justify-content-space-between is-align-items-flex-end"
        >
          <BasketCollapse
            :id="'MEMBERSHIP'"
            :title="$t(selectedProduct.membership.headline)"
            :icon="'medal'"
          >
            <div class="basket-wrapper">
              <div
                class="basket-list-price-wrapper is-justify-content-space-between"
              >
                <!-- <ul class="basket-price-summary">
                  <li
                    v-for="bulletpoint in selectedProduct.membership
                      .bulletPoints"
                    :key="bulletpoint"
                  >
                    {{ $t(bulletpoint) }}
                  </li>
                </ul> -->

                <p class="basket-price-summary">
                  Includes 12-month membership. Billing begins after the first
                  year of your membership
                </p>
                <div
                  class="has-text-right is-flex-shrink-0"
                  v-if="selectedRegionScoped == 'UK'"
                >
                  <span class="basket-price">
                    {{ $t(selectedProduct.membership.price) }}
                    <span
                      v-if="$t(selectedProduct.membership.pricePeriod)"
                      class="basket-price-period"
                    >
                      / {{ $t(selectedProduct.membership.pricePeriod) }}
                    </span>
                  </span>
                  <!-- <span class="basket-price-hint">{{
                    $t(selectedProduct.membership.priceHint)
                  }}</span> -->
                </div>
              </div>
            </div>
          </BasketCollapse>
        </div>
        <div :class="'separator--light my-4'" />
        <!-- <EasterSalePromo
          v-if="activePromotion === 'EASTER_SALE'"
          :icon="'star-promo'"
        /> -->
        <!-- <MayDaySalePromo
          v-if="activePromotion === 'MAY_DAY_SALE'"
          :icon="'star-promo'"
        /> -->
        <!-- <NoDiscount
          v-if="activePromotion === 'NO_DISCOUNT'"
          :icon="'star-promo'"
          :region="selectedRegionScoped"
        /> -->
        <!-- <Vahaxmas
          v-if="activePromotion === 'VAHAXMAS'"
          :icon="'star-promo'"
          :region="selectedRegionScoped" 
        /> -->
        <Vahanewyear
          v-if="activePromotion === 'VAHANEWYEAR'"
          :icon="'star-promo'"
          :region="selectedRegionScoped"
        />
        <!-- <SummerWithFlow
          v-if="activePromotion === 'SUMMER_WITH_FLOW'"
          :icon="'star-promo'"
        /> -->
        <!-- <VahaPower
          v-if="activePromotion === 'VAHA_POWER'"
          :icon="'star-promo'"
        /> -->
      </div>
      <div :class="{ fixed: experimentRunning }">
        <!-- <div
          v-if="
            selectedProductDiscountedPrice != null ||
              $te(discount.code.promoCode)
          "
          :class="'separator--light my-4'"
        />
        <BasketDiscountRow
          v-if="
            selectedProductDiscountedPrice != null ||
              $te(discount.code.promoCode)
          "
          :discount="discount"
        /> -->
        <div :class="'separator--light my-4'" />
        <BasketPricesRow
          :prices="prices"
          :selectedRegion="selectedRegionScoped"
          :unique-discount-selected-product="selectedProductDiscountedPrice"
        />
        <AppIconButton
          v-if="selectedProduct.paymentButton.url"
          class="basket-payments-button"
          theme="yellow"
          :title="$t(selectedProduct.paymentButton.label)"
          @click="prepareShopifyUrl(selectedProduct.id, selectedRegionScoped)"
        />
        <!-- <AppIconButton
          v-if="selectedProduct.paymentButton.url"
          class="basket-payments-button"
          theme="yellow"
          :title="$t(selectedProduct.paymentButton.label)"
          @click="
            redirectToShop(
              $t(selectedProduct.paymentButton.url),
              {
                id: $t(selectedProduct.id),
                name: $t(selectedProduct.name),
              },
              $t(selectedProduct.id),
              selectedCurrencyScoped,
              selectedRegionScoped,
              false
            )
          "
        /> -->
      </div>
      <div class="basket-payments-container">
        <img
          v-for="allowedPayment in allowedPayments"
          :key="allowedPayment.name"
          :src="prepareAllowedPaymentName(allowedPayment.name)"
          :alt="`${allowedPayment.name}`"
          class="basket-payments-icon"
        />
      </div>

      <div :class="'separator--light my-4'" />

      <PageSectionContainer class="has-background-white pb-5 mb-big" container>
        <ul class="columns is-multiline is-mobile mt-2 mb-1">
          <template v-for="item in selectedProduct.featuredServices.items">
            <li
              v-if="$te(item.id)"
              :key="item.name"
              class="column is-12-mobile is-12-tablet is-4-desktop"
            >
              <span
                class="vaha-item delivery-notes"
                :class="{ 'experiment-13-09': experimentRunning }"
              >
                <span class="vaha-icon">
                  <component
                    :is="`Icon${$t(item.id)}`"
                    :class="`vaha-icon-${$t(item.id)}`"
                  />
                </span>
                <span class="vaha-text has-text-centered-desktop">
                  {{ $t(item.content) }}
                </span>
              </span>
            </li>
          </template>
        </ul>
      </PageSectionContainer>
      <template v-if="klarnaNon0Enabled">
        <div
          class="klarna-terms font-14 has-text-centered"
          style="margin-top:-20px"
        >
          *in case of installment purchase the interest charged by Klarna for
          monthly installments will apply.
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import InfoIcon from "../../icons/info";
import CloseIcon from "../../icons/x";
import ServiceSection from "../../components/ServiceSection";
import PromoBox from "../../components/PromoBox";
import PageSectionContainer from "../../components/container/PageSectionContainer";
import BasketCollapse from "../../components/BasketCollapse";
import TransformationPackage from "../../components/TransformationPackage";
import BlackFriday from "../../components/BlackFriday";
import BasketDiscountRow from "../../components/BasketDiscountRow.vue";
import AppIconButton from "../../components/AppIconButton";
import IconTesting from "../../icons/trial";
import IconWarranty from "../../icons/guarantee";
import IconDelivery from "../../icons/delivery";
import Icon30Days from "../../icons/30days";
import IconStar from "../../icons/star";
import IconVerticalDivider from "../../icons/vertical-divider";
import { store } from "../../store";
import prepareAllowedPaymentName from "../../mixins/prepareAllowedPaymentName";
import redirectToShop from "../../mixins/redirectToShop";
import changeProduct from "../../mixins/changeProduct";
import closeBasket from "../../mixins/closeBasket";
import createCheckout from "../../mixins/createCheckout";
import setSidebarOpen from "../../mixins/setSidebarOpen";
import prepareCheckoutLink from "../../mixins/prepareCheckoutLink";
import BasketPricesRow from "../../components/BasketPricesRow.vue";
import { BASE_PRICES } from "../../constants";
import NoDiscount from "../../components/promotions/no-discount.vue";
import Vahaxmas from "../../components/promotions/vahaxmas.vue";
import Vahanewyear from "../../components/promotions/vahanewyear.vue";
export default {
  name: "AEBasketClassic",
  props: {
    allowedPayments: { type: Array, default: () => [] },
    isProductFlatrate: { type: Boolean, default: true },
    helpMeChooseScoped: { type: Boolean },
    isNuxt: { type: Boolean },
    selectedRegionScoped: { type: String, default: "AE" },
    selectedCurrencyScoped: { type: String, default: "AED" },
    seeMoreLink: { type: Object, default: () => {} },
  },
  mixins: [
    prepareAllowedPaymentName,
    redirectToShop,
    changeProduct,
    closeBasket,
    setSidebarOpen,
    prepareCheckoutLink,
    createCheckout,
  ],
  components: {
    CloseIcon,
    ServiceSection,
    PageSectionContainer,
    IconDelivery,
    Icon30Days,
    IconTesting,
    IconWarranty,
    IconStar,
    IconVerticalDivider,
    AppIconButton,
    BasketCollapse,
    TransformationPackage,
    PromoBox,
    BlackFriday,
    BasketDiscountRow,
    InfoIcon,
    BasketPricesRow,
    NoDiscount,
    Vahaxmas,
    Vahanewyear,
  },
  computed: {
    selectedProduct() {
      return store.state.selectedProduct;
    },
    products() {
      return store.state.products;
    },
    activePromotion() {
      return store.state.activePromotion;
    },
    experimentRunning() {
      return store.state.experimentRunning;
    },
    vahaSEnabled() {
      return store.state.vahaSEnabled;
    },
    promoBoxVariant() {
      return store.state.promoBoxVariant;
    },
    basketVersion() {
      return "UK";
    },
    discount() {
      const activePromotionCamelCase = store.state.activePromotionCamelCase;
      return store.state.selectedProduct.promotions[activePromotionCamelCase]
        .discount;
    },
    prices() {
      const activePromotionCamelCase = store.state.activePromotionCamelCase;
      return store.state.selectedProduct.promotions[activePromotionCamelCase]
        .pricesBeforeAfter;
    },
    selectedProductDiscountedPrice() {
      return store.state.uniqueDiscount.selectedProductDiscountedPrice;
    },
    klarnaNon0Enabled() {
      return process.env.VUE_APP_KLARNA_NON_0 === "true" ? true : false;
    },
  },
  async mounted() {
    // imported from prepareCheckoutLink
    console.log(this.selectedRegionScoped);

    if (this.selectedRegionScoped === "AE") {
      console.log("ok");
      this.changeProduct(this.products[1], 2, "AE");
      const discountData = await this.determineDiscountCodeAndValue(
        this.selectedProduct.type,
        this.selectedRegionScoped
      );
      const selectedRegionLowerCase = this.selectedRegionScoped.toLowerCase();
      console.log(discountData);
      const activePromotionCamelCase = store.state.activePromotionCamelCase;
      const payload = { discountData, activePromotionCamelCase };
      store.commit("setDiscountData", payload);
      const { vahaXPrice, vahaSPrice } = discountData;
      const selectedProductType = store.state.selectedProduct.type;
      const selectedProductPriceAfter =
        selectedProductType === "products.productS.type"
          ? vahaSPrice
          : vahaXPrice;

      const vahaXbasePrice = BASE_PRICES[selectedRegionLowerCase].vahaX;
      const vahaSbasePrice = BASE_PRICES[selectedRegionLowerCase].vahaS;
      console.log(vahaXbasePrice, vahaSPrice);
      const pricesPayload = {
        vahaXPrice,
        vahaSPrice,
        selectedProductPriceAfter,
        vahaXbasePrice,
        vahaSbasePrice,
      };
      console.log("aaa");
      console.log(pricesPayload);
      store.commit("setDynamicPriceAfter", pricesPayload);
    }
  },
};
</script>

<style lang="scss">
.basket-ae {
  .basket-vaha-item {
    width: 100% !important;
  }
}
</style>
