<template v-if="$te(item.title) && $te(item.description)">
  <div class="basket-wrapper mt-2 mb-2 basket-row-item">
    <div v-if="$te(item.title)" class="basket-title">
      <p v-if="$te(item.title)" class="font-700">{{ $t(item.title) }}</p>
      <p
        v-if="$te(item.description)"
        class="font-400 font-grey font-12"
        v-html="$t(item.description)"
      />
    </div>
    <template v-if="region === 'UK'">
      <div
        class="basket-list-price-wrapper is-justify-content-space-between is-align-items-flex-start"
      >
        <p class="font-700 has-text-right min-width-50">
          <span v-if="$te(item.priceBefore)" class="text-strikethrough">{{
            $t(item.priceBefore)
          }}</span>
          <br />
          <span v-if="$te(item.priceAfter)">{{ $t(item.priceAfter) }}</span>
        </p>
      </div></template
    >
  </div>
</template>
<script>
export default {
  name: "BasketRowItem",
  props: {
    item: { type: Object, default: () => {} },
    region: { type: String, default: "UK" },
  },
};
</script>

<style lang="scss" scoped>
.basket-row-item {
  display: flex;
  justify-content: space-between;
  .font-18 {
    font-size: 18px;
    line-height: 27px;
  }

  .font-16 {
    font-size: 16px;
    line-height: 24px;
  }

  .font-15 {
    font-size: 15px;
    line-height: 24px;
  }

  .basket-title {
    flex-direction: column;
    padding-right: 20px;
    margin-top: 0;
  }

  .flash-sale-image {
    max-height: 64px;
    width: auto;
  }

  .text-strikethrough {
    text-decoration: line-through;
  }

  .font-12 {
    font-size: 12px;
    line-height: 150%;
  }

  .font-700 {
    font-weight: 700;
  }

  .font-400 {
    font-weight: 400;
  }
  .font-grey {
    color: #828282;
  }

  .min-width-50 {
    min-width: 50px;
  }
}
</style>
