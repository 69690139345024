export const achieveTheUnexpectedProductS = {
    mainPromoHeadline:
      "products.product.promotions.achieveTheUnexpected.mainPromoHeadline",
    heading: {
      image: "products.productS.promotions.achieveTheUnexpected.heading.image",
      title: "products.productS.promotions.achieveTheUnexpected.heading.title",
    },
    pricesBeforeAfter: {
      priceBefore:
        "products.productS.promotions.achieveTheUnexpected.pricesBeforeAfter.priceBefore",
      priceAfter:
        "products.productS.promotions.achieveTheUnexpected.pricesBeforeAfter.priceAfter",
    },
    footer: "products.productS.promotions.achieveTheUnexpected.footer",
    total: {
      headline: "products.productS.promotions.achieveTheUnexpected.total.headline",
      priceBefore: "products.productS.promotions.achieveTheUnexpected.total.priceBefore",
      priceAfter: "products.productS.promotions.achieveTheUnexpected.total.priceAfter",
    },
    discount: {
      heading: "products.productS.promotions.achieveTheUnexpected.discount.heading",
      title: "products.productS.promotions.achieveTheUnexpected.discount.title",
      amount: "products.productS.promotions.achieveTheUnexpected.discount.amount",
      code: {
        description:
          "products.productS.promotions.achieveTheUnexpected.discount.code.description",
        promoCode:
          "products.productS.promotions.achieveTheUnexpected.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title: "products.productS.promotions.achieveTheUnexpected.items.item1.title",
        description:
          "products.productS.promotions.achieveTheUnexpected.items.item1.description",
        priceBefore:
          "products.productS.promotions.achieveTheUnexpected.items.item1.priceBefore",
        priceAfter:
          "products.productS.promotions.achieveTheUnexpected.items.item1.priceAfter",
      },
      item2: {
        title: "products.productS.promotions.achieveTheUnexpected.items.item2.title",
        description:
          "products.productS.promotions.achieveTheUnexpected.items.item2.description",
        priceBefore:
          "products.productS.promotions.achieveTheUnexpected.items.item2.priceBefore",
        priceAfter: "products.productS.promotions.achieveTheUnexpected.items.item2.priceAfter",
      },
      item3: {
        title: "products.productS.promotions.achieveTheUnexpected.items.item3.title",
        description:
          "products.productS.promotions.achieveTheUnexpected.items.item3.description",
        priceBefore:
          "products.productS.promotions.achieveTheUnexpected.items.item3.priceBefore",
        priceAfter:
          "products.productS.promotions.achieveTheUnexpected.items.item3.priceAfter",
      },
      item4: {
        title: "products.productS.promotions.achieveTheUnexpected.items.item4.title",
        description:
          "products.productS.promotions.achieveTheUnexpected.items.item4.description",
        priceBefore:
          "products.productS.promotions.achieveTheUnexpected.items.item4.priceBefore",
        priceAfter:
          "products.productS.promotions.achieveTheUnexpected.items.item4.priceAfter",
      },
      item5: {
        title: "products.productS.promotions.achieveTheUnexpected.items.item5.title",
        description:
          "products.productS.promotions.achieveTheUnexpected.items.item5.description",
        priceBefore:
          "products.productS.promotions.achieveTheUnexpected.items.item5.priceBefore",
        priceAfter:
          "products.productS.promotions.achieveTheUnexpected.items.item5.priceAfter",
      },
    },
  };
  
  export const flatrateAchieveTheUnexpectedProductS = {
    mainPromoHeadline:
      "products.productS.promotions.flatrateAchieveTheUnexpected.mainPromoHeadline",
    heading: {
      image: "products.productS.promotions.flatrateAchieveTheUnexpected.heading.image",
      title: "products.productS.promotions.flatrateAchieveTheUnexpected.heading.title",
    },
    footer: "products.productS.promotions.flatrateAchieveTheUnexpected.footer",
    total: {
      headline:
        "products.productS.promotions.flatrateAchieveTheUnexpected.total.headline",
      priceBefore:
        "products.productS.promotions.flatrateAchieveTheUnexpected.total.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateAchieveTheUnexpected.total.priceAfter",
    },
    discount: {
      heading: "products.productS.promotions.achieveTheUnexpected.discount.heading",
      title: "products.productS.promotions.flatrateAchieveTheUnexpected.discount.title",
      amount: "products.productS.promotions.flatrateAchieveTheUnexpected.discount.amount",
      code: {
        description:
          "products.productS.promotions.flatrateAchieveTheUnexpected.discount.code.description",
        promoCode:
          "products.productS.promotions.flatrateAchieveTheUnexpected.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item1.title",
        description:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item1.description",
        priceBefore:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item1.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item1.priceAfter",
      },
      item2: {
        title:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item2.title",
        description:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item2.description",
        priceBefore:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item2.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item2.priceAfter",
      },
      item3: {
        title:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item3.title",
        description:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item3.description",
        priceBefore:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item3.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item3.priceAfter",
      },
      item4: {
        title:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item4.title",
        description:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item4.description",
        priceBefore:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item4.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item4.priceAfter",
      },
      item5: {
        title:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item5.title",
        description:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item5.description",
        priceBefore:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item5.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item5.priceAfter",
      },
      item6: {
        title:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item6.title",
        description:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item6.description",
        priceBefore:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item6.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateAchieveTheUnexpected.items.item6.priceAfter",
      },
    },
  };
  
  export const achieveTheUnexpectedProductX = {
    mainPromoHeadline:
      "products.productX.promotions.achieveTheUnexpected.mainPromoHeadline",
    heading: {
      image: "products.productX.promotions.achieveTheUnexpected.heading.image",
      title: "products.productX.promotions.achieveTheUnexpected.heading.title",
    },
    pricesBeforeAfter: {
      priceBefore:
        "products.productX.promotions.achieveTheUnexpected.pricesBeforeAfter.priceBefore",
      priceAfter:
        "products.productX.promotions.achieveTheUnexpected.pricesBeforeAfter.priceAfter",
    },
    footer: "products.productX.promotions.achieveTheUnexpected.footer",
    total: {
      headline: "products.productX.promotions.achieveTheUnexpected.total.headline",
      priceBefore: "products.productX.promotions.achieveTheUnexpected.total.priceBefore",
      priceAfter: "products.productX.promotions.achieveTheUnexpected.total.priceAfter",
    },
    discount: {
      heading: "products.productX.promotions.achieveTheUnexpected.discount.heading",
      title: "products.productX.promotions.achieveTheUnexpected.discount.title",
      amount: "products.productX.promotions.achieveTheUnexpected.discount.amount",
      code: {
        description:
          "products.productX.promotions.achieveTheUnexpected.discount.code.description",
        promoCode:
          "products.productX.promotions.achieveTheUnexpected.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title: "products.productX.promotions.achieveTheUnexpected.items.item1.title",
        description:
          "products.productX.promotions.achieveTheUnexpected.items.item1.description",
        priceBefore:
          "products.productX.promotions.achieveTheUnexpected.items.item1.priceBefore",
        priceAfter:
          "products.productX.promotions.achieveTheUnexpected.items.item1.priceAfter",
      },
      item2: {
        title: "products.productX.promotions.achieveTheUnexpected.items.item2.title",
        description:
          "products.productX.promotions.achieveTheUnexpected.items.item2.description",
        priceBefore:
          "products.productX.promotions.achieveTheUnexpected.items.item2.priceBefore",
        priceAfter: "products.productX.promotions.achieveTheUnexpected.items.item2.priceAfter",
      },
      item3: {
        title: "products.productX.promotions.achieveTheUnexpected.items.item3.title",
        description:
          "products.productX.promotions.achieveTheUnexpected.items.item3.description",
        priceBefore:
          "products.productX.promotions.achieveTheUnexpected.items.item3.priceBefore",
        priceAfter:
          "products.productX.promotions.achieveTheUnexpected.items.item3.priceAfter",
      },
      item4: {
        title: "products.productX.promotions.achieveTheUnexpected.items.item4.title",
        description:
          "products.productX.promotions.achieveTheUnexpected.items.item4.description",
        priceBefore:
          "products.productX.promotions.achieveTheUnexpected.items.item4.priceBefore",
        priceAfter:
          "products.productX.promotions.achieveTheUnexpected.items.item4.priceAfter",
      },
      item5: {
        title: "products.productX.promotions.achieveTheUnexpected.items.item5.title",
        description:
          "products.productX.promotions.achieveTheUnexpected.items.item5.description",
        priceBefore:
          "products.productX.promotions.achieveTheUnexpected.items.item5.priceBefore",
        priceAfter:
          "products.productX.promotions.achieveTheUnexpected.items.item5.priceAfter",
      },
    },
  };
  
  export const flatrateAchieveTheUnexpectedProductX = {
    mainPromoHeadline:
      "products.productX.promotions.flatrateAchieveTheUnexpected.mainPromoHeadline",
    heading: {
      image: "products.productX.promotions.flatrateAchieveTheUnexpected.heading.image",
      title: "products.productX.promotions.flatrateAchieveTheUnexpected.heading.title",
    },
    footer: "products.productX.promotions.flatrateAchieveTheUnexpected.footer",
    total: {
      headline:
        "products.productX.promotions.flatrateAchieveTheUnexpected.total.headline",
      priceBefore:
        "products.productX.promotions.flatrateAchieveTheUnexpected.total.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateAchieveTheUnexpected.total.priceAfter",
    },
    discount: {
      heading: "products.productX.promotions.achieveTheUnexpected.discount.heading",
      title: "products.productX.promotions.flatrateAchieveTheUnexpected.discount.title",
      amount: "products.productX.promotions.flatrateAchieveTheUnexpected.discount.amount",
      code: {
        description:
          "products.productX.promotions.flatrateAchieveTheUnexpected.discount.code.description",
        promoCode:
          "products.productX.promotions.flatrateAchieveTheUnexpected.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item1.title",
        description:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item1.description",
        priceBefore:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item1.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item1.priceAfter",
      },
      item2: {
        title:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item2.title",
        description:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item2.description",
        priceBefore:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item2.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item2.priceAfter",
      },
      item3: {
        title:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item3.title",
        description:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item3.description",
        priceBefore:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item3.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item3.priceAfter",
      },
      item4: {
        title:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item4.title",
        description:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item4.description",
        priceBefore:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item4.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item4.priceAfter",
      },
      item5: {
        title:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item5.title",
        description:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item5.description",
        priceBefore:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item5.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item5.priceAfter",
      },
      item6: {
        title:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item6.title",
        description:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item6.description",
        priceBefore:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item6.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateAchieveTheUnexpected.items.item6.priceAfter",
      },
    },
  };
  