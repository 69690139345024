<template>
  <section class="black-friday-container">
    <div
      class="is-flex is-justify-content-space-between is-align-items-flex-end is-flex-direction-column"
    >
      <div class="basket-wrapper mt-3 mb-3 is-align-items-center">
        <!-- <img
          :src="$t(content.blackFriday.heading.image)"
          class="black-friday-image mr-3"
        /> -->
        <h3 class="font-18 font-700">
          {{ $t(content.blackFriday.heading.title) }}
        </h3>
      </div>
      <div
        class="basket-wrapper mt-2 mb-2"
        v-for="item of content.blackFriday.items"
        :key="item.title"
      >
        <div class="basket-title">
          <p class="font-700">{{ $t(item.title) }}</p>
          <p class="font-400 font-grey font-12" v-html="$t(item.description)" />
        </div>

        <div
          class="basket-list-price-wrapper is-justify-content-space-between is-align-items-flex-start"
        >
          <p class="font-700">
            <span class="text-strikethrough">{{ $t(item.priceBefore) }}</span>
            <br />
            {{ $t(item.priceAfter) }}
          </p>
        </div>
      </div>
      <!-- <div :class="'separator mt-4 mb-4'" />
      <div class="basket-wrapper mt-3 mb-3 total-gain">
        <div class="total-worth">
          {{ $t(content.blackFriday.total.headline) }}
        </div>
        <div class="total-worth-price-before font-700">
          <span class="text-strikethrough">{{
            $t(content.blackFriday.total.priceBefore)
          }}</span
          ><span>&nbsp;{{ $t(content.blackFriday.total.priceAfter) }}</span>
        </div>
      </div> -->
      <div :class="'separator mt-4 mb-4'" />
      <div class="basket-wrapper mt-3 mb-3 total-gain">
        <div class="total-worth">
          {{ $t(content.blackFriday.discount.title) }} <br />
          <span v-html="$t(content.blackFriday.discount.code.description)" />
          <strong class = "has-text-black">{{ $t(content.blackFriday.discount.code.promoCode) }}</strong>
        </div>
        <div class="total-worth-price-before font-700">
          <span>&nbsp;{{ $t(content.blackFriday.discount.amount) }}</span>
        </div>
      </div>
      <div :class="'separator mt-4 mb-4'" />
    </div>
  </section>
</template>

<script>
export default {
  name: "BlackFriday",
  props: {
    region: { type: String, default: "UK" },
    flatrate: { type: Boolean, default: false },
  },
  computed: {
    content() {
      if (this.flatrate) {
        return {
          blackFriday: {
            heading: {
              image: "flatrateBlackFriday.heading.image",
              title: "flatrateBlackFriday.heading.title",
            },
            total: {
              headline: "flatrateBlackFriday.total.headline",
              priceBefore: "flatrateBlackFriday.total.priceBefore",
              priceAfter: "flatrateBlackFriday.total.priceAfter",
            },
            discount: {
              title: "flatrateBlackFriday.discount.title",
              amount: "flatrateBlackFriday.discount.amount",
              code: {
                description: "flatrateBlackFriday.discount.code.description",
                promoCode: "flatrateBlackFriday.discount.code.promoCode",
              },
            },
            items: {
              item1: {
                title: "flatrateBlackFriday.items.item1.title",
                description: "flatrateBlackFriday.items.item1.description",
                priceBefore: "flatrateBlackFriday.items.item1.priceBefore",
                priceAfter: "flatrateBlackFriday.items.item1.priceAfter",
              },
              item3: {
                title: "flatrateBlackFriday.items.item3.title",
                description: "flatrateBlackFriday.items.item3.description",
                priceBefore: "flatrateBlackFriday.items.item3.priceBefore",
                priceAfter: "flatrateBlackFriday.items.item3.priceAfter",
              },
              item4: {
                title: "flatrateBlackFriday.items.item4.title",
                description: "flatrateBlackFriday.items.item4.description",
                priceBefore: "flatrateBlackFriday.items.item4.priceBefore",
                priceAfter: "flatrateBlackFriday.items.item4.priceAfter",
              },
              item5: {
                title: "flatrateBlackFriday.items.item5.title",
                description: "flatrateBlackFriday.items.item5.description",
                priceBefore: "flatrateBlackFriday.items.item5.priceBefore",
                priceAfter: "flatrateBlackFriday.items.item5.priceAfter",
              },
            },
          },
        };
      } else {
        return {
          blackFriday: {
            heading: {
              image: "blackFriday.heading.image",
              title: "blackFriday.heading.title",
            },
            total: {
              headline: "blackFriday.total.headline",
              priceBefore: "blackFriday.total.priceBefore",
              priceAfter: "blackFriday.total.priceAfter",
            },
            discount: {
              title: "blackFriday.discount.title",
              amount: "blackFriday.discount.amount",
              code: {
                description: "blackFriday.discount.code.description",
                promoCode: "blackFriday.discount.code.promoCode",
              },
            },
            items: {
              item1: {
                title: "blackFriday.items.item1.title",
                description: "blackFriday.items.item1.description",
                priceBefore: "blackFriday.items.item1.priceBefore",
                priceAfter: "blackFriday.items.item1.priceAfter",
              },
              item2: {
                title: "blackFriday.items.item2.title",
                description: "blackFriday.items.item2.description",
                priceBefore: "blackFriday.items.item2.priceBefore",
                priceAfter: "blackFriday.items.item2.priceAfter",
              },
              item3: {
                title: "blackFriday.items.item3.title",
                description: "blackFriday.items.item3.description",
                priceBefore: "blackFriday.items.item3.priceBefore",
                priceAfter: "blackFriday.items.item3.priceAfter",
              },
              item4: {
                title: "blackFriday.items.item4.title",
                description: "blackFriday.items.item4.description",
                priceBefore: "blackFriday.items.item4.priceBefore",
                priceAfter: "blackFriday.items.item4.priceAfter",
              },
              item5: {
                title: "blackFriday.items.item5.title",
                description: "blackFriday.items.item5.description",
                priceBefore: "blackFriday.items.item5.priceBefore",
                priceAfter: "blackFriday.items.item5.priceAfter",
              },
            },
          },
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.black-friday-container {
  .font-18 {
    font-size: 18px;
    line-height: 27px;
  }

  .basket-wrapper {
    display: flex;
    justify-content: space-between;

    .basket-title {
      flex-direction: column;
      padding-right: 20px;
      margin-top: 0;
    }
  }

  .black-friday-image {
    max-height: 64px;
    width: auto;
  }

  .text-strikethrough {
    text-decoration: line-through;
  }

  .font-12 {
    font-size: 12px;
  }

  .font-700 {
    font-weight: 700;
  }

  .font-400 {
    font-weight: 400;
  }
  .font-grey {
    color: #828282;
  }
}
</style>
