import Vue from "vue";
import VueCustomElement from "vue-custom-element";
import Basket from "./Basket.vue";
import { Sidebar, Collapse, Tooltip, Modal } from "buefy";
import VueI18n from "vue-i18n";
import VueScrollTo from "vue-scrollto";
import { languages } from "./i18n/index.js";
import { defaultLocale } from "./i18n/index.js";

Vue.use(VueI18n);
Vue.use(Collapse);
Vue.use(Sidebar);
Vue.use(Tooltip);
Vue.use(Modal);
Vue.use(VueScrollTo);

Vue.use(VueCustomElement);

// Vue.config.silent = true;

const messages = Object.assign(languages);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: defaultLocale, // set locale
  messages, // set locale messages
});

Vue.customElement("shared-basket", Basket, {
  beforeCreateVueInstance(RootComponentDefinition) {
    RootComponentDefinition.i18n = i18n;
    return RootComponentDefinition;
  },
});
// console.log(process.env.VUE_APP_DEBUG_MODE);
if (process.env.VUE_APP_DEBUG_MODE === "true") {
  new Vue({
    i18n,
    render: (h) => h(Basket),
  }).$mount("#app");
}
