import { render, staticRenderFns } from "./BasketRowItem.vue?vue&type=template&id=39898c76&scoped=true&v-if=%24te(item.title)%20%26%26%20%24te(item.description)&"
import script from "./BasketRowItem.vue?vue&type=script&lang=js&"
export * from "./BasketRowItem.vue?vue&type=script&lang=js&"
import style0 from "./BasketRowItem.vue?vue&type=style&index=0&id=39898c76&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39898c76",
  null
  
)

export default component.exports