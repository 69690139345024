export const wellnessSaleProductS = {
  mainPromoHeadline:
    "products.product.promotions.wellnessSale.mainPromoHeadline",
  heading: {
    image: "products.productS.promotions.wellnessSale.heading.image",
    title: "products.productS.promotions.wellnessSale.heading.title",
  },
  pricesBeforeAfter: {
    priceBefore:
      "products.productS.promotions.wellnessSale.pricesBeforeAfter.priceBefore",
    priceAfter:
      "products.productS.promotions.wellnessSale.pricesBeforeAfter.priceAfter",
  },
  footer: "products.productS.promotions.wellnessSale.footer",
  total: {
    headline: "products.productS.promotions.wellnessSale.total.headline",
    priceBefore: "products.productS.promotions.wellnessSale.total.priceBefore",
    priceAfter: "products.productS.promotions.wellnessSale.total.priceAfter",
  },
  discount: {
    heading: "products.productS.promotions.wellnessSale.discount.heading",
    title: "products.productS.promotions.wellnessSale.discount.title",
    amount: "products.productS.promotions.wellnessSale.discount.amount",
    code: {
      description:
        "products.productS.promotions.wellnessSale.discount.code.description",
      promoCode:
        "products.productS.promotions.wellnessSale.discount.code.promoCode",
    },
  },
  items: {
    item1: {
      title: "products.productS.promotions.wellnessSale.items.item1.title",
      description:
        "products.productS.promotions.wellnessSale.items.item1.description",
      priceBefore:
        "products.productS.promotions.wellnessSale.items.item1.priceBefore",
      priceAfter:
        "products.productS.promotions.wellnessSale.items.item1.priceAfter",
    },
    item2: {
      title: "products.productS.promotions.wellnessSale.items.item2.title",
      description:
        "products.productS.promotions.wellnessSale.items.item2.description",
      priceBefore:
        "products.productS.promotions.wellnessSale.items.item2.priceBefore",
      priceAfter: "products.productS.promotions.wellnessSale.items.item2.priceAfter",
    },
    item3: {
      title: "products.productS.promotions.wellnessSale.items.item3.title",
      description:
        "products.productS.promotions.wellnessSale.items.item3.description",
      priceBefore:
        "products.productS.promotions.wellnessSale.items.item3.priceBefore",
      priceAfter:
        "products.productS.promotions.wellnessSale.items.item3.priceAfter",
    },
    item4: {
      title: "products.productS.promotions.wellnessSale.items.item4.title",
      description:
        "products.productS.promotions.wellnessSale.items.item4.description",
      priceBefore:
        "products.productS.promotions.wellnessSale.items.item4.priceBefore",
      priceAfter:
        "products.productS.promotions.wellnessSale.items.item4.priceAfter",
    },
    item5: {
      title: "products.productS.promotions.wellnessSale.items.item5.title",
      description:
        "products.productS.promotions.wellnessSale.items.item5.description",
      priceBefore:
        "products.productS.promotions.wellnessSale.items.item5.priceBefore",
      priceAfter:
        "products.productS.promotions.wellnessSale.items.item5.priceAfter",
    },
  },
};

export const flatrateWellnessSaleProductS = {
  mainPromoHeadline:
    "products.productS.promotions.flatrateWellnessSale.mainPromoHeadline",
  heading: {
    image: "products.productS.promotions.flatrateWellnessSale.heading.image",
    title: "products.productS.promotions.flatrateWellnessSale.heading.title",
  },
  footer: "products.productS.promotions.flatrateWellnessSale.footer",
  total: {
    headline:
      "products.productS.promotions.flatrateWellnessSale.total.headline",
    priceBefore:
      "products.productS.promotions.flatrateWellnessSale.total.priceBefore",
    priceAfter:
      "products.productS.promotions.flatrateWellnessSale.total.priceAfter",
  },
  discount: {
    heading: "products.productS.promotions.wellnessSale.discount.heading",
    title: "products.productS.promotions.flatrateWellnessSale.discount.title",
    amount: "products.productS.promotions.flatrateWellnessSale.discount.amount",
    code: {
      description:
        "products.productS.promotions.flatrateWellnessSale.discount.code.description",
      promoCode:
        "products.productS.promotions.flatrateWellnessSale.discount.code.promoCode",
    },
  },
  items: {
    item1: {
      title:
        "products.productS.promotions.flatrateWellnessSale.items.item1.title",
      description:
        "products.productS.promotions.flatrateWellnessSale.items.item1.description",
      priceBefore:
        "products.productS.promotions.flatrateWellnessSale.items.item1.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateWellnessSale.items.item1.priceAfter",
    },
    item2: {
      title:
        "products.productS.promotions.flatrateWellnessSale.items.item2.title",
      description:
        "products.productS.promotions.flatrateWellnessSale.items.item2.description",
      priceBefore:
        "products.productS.promotions.flatrateWellnessSale.items.item2.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateWellnessSale.items.item2.priceAfter",
    },
    item3: {
      title:
        "products.productS.promotions.flatrateWellnessSale.items.item3.title",
      description:
        "products.productS.promotions.flatrateWellnessSale.items.item3.description",
      priceBefore:
        "products.productS.promotions.flatrateWellnessSale.items.item3.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateWellnessSale.items.item3.priceAfter",
    },
    item4: {
      title:
        "products.productS.promotions.flatrateWellnessSale.items.item4.title",
      description:
        "products.productS.promotions.flatrateWellnessSale.items.item4.description",
      priceBefore:
        "products.productS.promotions.flatrateWellnessSale.items.item4.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateWellnessSale.items.item4.priceAfter",
    },
    item5: {
      title:
        "products.productS.promotions.flatrateWellnessSale.items.item5.title",
      description:
        "products.productS.promotions.flatrateWellnessSale.items.item5.description",
      priceBefore:
        "products.productS.promotions.flatrateWellnessSale.items.item5.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateWellnessSale.items.item5.priceAfter",
    },
    item6: {
      title:
        "products.productS.promotions.flatrateWellnessSale.items.item6.title",
      description:
        "products.productS.promotions.flatrateWellnessSale.items.item6.description",
      priceBefore:
        "products.productS.promotions.flatrateWellnessSale.items.item6.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateWellnessSale.items.item6.priceAfter",
    },
  },
};

export const wellnessSaleProductX = {
  mainPromoHeadline:
    "products.productX.promotions.wellnessSale.mainPromoHeadline",
  heading: {
    image: "products.productX.promotions.wellnessSale.heading.image",
    title: "products.productX.promotions.wellnessSale.heading.title",
  },
  pricesBeforeAfter: {
    priceBefore:
      "products.productX.promotions.wellnessSale.pricesBeforeAfter.priceBefore",
    priceAfter:
      "products.productX.promotions.wellnessSale.pricesBeforeAfter.priceAfter",
  },
  footer: "products.productX.promotions.wellnessSale.footer",
  total: {
    headline: "products.productX.promotions.wellnessSale.total.headline",
    priceBefore: "products.productX.promotions.wellnessSale.total.priceBefore",
    priceAfter: "products.productX.promotions.wellnessSale.total.priceAfter",
  },
  discount: {
    heading: "products.productX.promotions.wellnessSale.discount.heading",
    title: "products.productX.promotions.wellnessSale.discount.title",
    amount: "products.productX.promotions.wellnessSale.discount.amount",
    code: {
      description:
        "products.productX.promotions.wellnessSale.discount.code.description",
      promoCode:
        "products.productX.promotions.wellnessSale.discount.code.promoCode",
    },
  },
  items: {
    item1: {
      title: "products.productX.promotions.wellnessSale.items.item1.title",
      description:
        "products.productX.promotions.wellnessSale.items.item1.description",
      priceBefore:
        "products.productX.promotions.wellnessSale.items.item1.priceBefore",
      priceAfter:
        "products.productX.promotions.wellnessSale.items.item1.priceAfter",
    },
    item2: {
      title: "products.productX.promotions.wellnessSale.items.item2.title",
      description:
        "products.productX.promotions.wellnessSale.items.item2.description",
      priceBefore:
        "products.productX.promotions.wellnessSale.items.item2.priceBefore",
      priceAfter: "products.productX.promotions.wellnessSale.items.item2.priceAfter",
    },
    item3: {
      title: "products.productX.promotions.wellnessSale.items.item3.title",
      description:
        "products.productX.promotions.wellnessSale.items.item3.description",
      priceBefore:
        "products.productX.promotions.wellnessSale.items.item3.priceBefore",
      priceAfter:
        "products.productX.promotions.wellnessSale.items.item3.priceAfter",
    },
    item4: {
      title: "products.productX.promotions.wellnessSale.items.item4.title",
      description:
        "products.productX.promotions.wellnessSale.items.item4.description",
      priceBefore:
        "products.productX.promotions.wellnessSale.items.item4.priceBefore",
      priceAfter:
        "products.productX.promotions.wellnessSale.items.item4.priceAfter",
    },
    item5: {
      title: "products.productX.promotions.wellnessSale.items.item5.title",
      description:
        "products.productX.promotions.wellnessSale.items.item5.description",
      priceBefore:
        "products.productX.promotions.wellnessSale.items.item5.priceBefore",
      priceAfter:
        "products.productX.promotions.wellnessSale.items.item5.priceAfter",
    },
  },
};

export const flatrateWellnessSaleProductX = {
  mainPromoHeadline:
    "products.productX.promotions.flatrateWellnessSale.mainPromoHeadline",
  heading: {
    image: "products.productX.promotions.flatrateWellnessSale.heading.image",
    title: "products.productX.promotions.flatrateWellnessSale.heading.title",
  },
  footer: "products.productX.promotions.flatrateWellnessSale.footer",
  total: {
    headline:
      "products.productX.promotions.flatrateWellnessSale.total.headline",
    priceBefore:
      "products.productX.promotions.flatrateWellnessSale.total.priceBefore",
    priceAfter:
      "products.productX.promotions.flatrateWellnessSale.total.priceAfter",
  },
  discount: {
    heading: "products.productX.promotions.wellnessSale.discount.heading",
    title: "products.productX.promotions.flatrateWellnessSale.discount.title",
    amount: "products.productX.promotions.flatrateWellnessSale.discount.amount",
    code: {
      description:
        "products.productX.promotions.flatrateWellnessSale.discount.code.description",
      promoCode:
        "products.productX.promotions.flatrateWellnessSale.discount.code.promoCode",
    },
  },
  items: {
    item1: {
      title:
        "products.productX.promotions.flatrateWellnessSale.items.item1.title",
      description:
        "products.productX.promotions.flatrateWellnessSale.items.item1.description",
      priceBefore:
        "products.productX.promotions.flatrateWellnessSale.items.item1.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateWellnessSale.items.item1.priceAfter",
    },
    item2: {
      title:
        "products.productX.promotions.flatrateWellnessSale.items.item2.title",
      description:
        "products.productX.promotions.flatrateWellnessSale.items.item2.description",
      priceBefore:
        "products.productX.promotions.flatrateWellnessSale.items.item2.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateWellnessSale.items.item2.priceAfter",
    },
    item3: {
      title:
        "products.productX.promotions.flatrateWellnessSale.items.item3.title",
      description:
        "products.productX.promotions.flatrateWellnessSale.items.item3.description",
      priceBefore:
        "products.productX.promotions.flatrateWellnessSale.items.item3.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateWellnessSale.items.item3.priceAfter",
    },
    item4: {
      title:
        "products.productX.promotions.flatrateWellnessSale.items.item4.title",
      description:
        "products.productX.promotions.flatrateWellnessSale.items.item4.description",
      priceBefore:
        "products.productX.promotions.flatrateWellnessSale.items.item4.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateWellnessSale.items.item4.priceAfter",
    },
    item5: {
      title:
        "products.productX.promotions.flatrateWellnessSale.items.item5.title",
      description:
        "products.productX.promotions.flatrateWellnessSale.items.item5.description",
      priceBefore:
        "products.productX.promotions.flatrateWellnessSale.items.item5.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateWellnessSale.items.item5.priceAfter",
    },
    item6: {
      title:
        "products.productX.promotions.flatrateWellnessSale.items.item6.title",
      description:
        "products.productX.promotions.flatrateWellnessSale.items.item6.description",
      priceBefore:
        "products.productX.promotions.flatrateWellnessSale.items.item6.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateWellnessSale.items.item6.priceAfter",
    },
  },
};
