import { BASE_PRICES } from "../constants";
import { store } from "../store";
import prepareCheckoutLink from "./prepareCheckoutLink";
export default {
  mixins: [prepareCheckoutLink],
  methods: {
    changeProduct(updatedProduct, productID, selectedRegionScoped) {
      console.log('CHANGE_PRODUCT')
      
      console.log(updatedProduct,productID,selectedRegionScoped)
      store.commit("setSelectedProduct", parseInt(this.$t(updatedProduct.id)) - 1);
      this.updateDiscountCode(selectedRegionScoped);
      this.selectedProductIndexScoped = parseInt(this.$t(updatedProduct.id)) - 1;
      let label = "";
      if (parseInt(productID, 10) === 2) {
        label = "side_panel_vaha_x";
      } else if (parseInt(productID, 10) === 1) {
        label = "side_panel_vaha_s";
      }
      if (window.dataLayer != null) {
        window.dataLayer.push({
          event: "generic_event",
          category: "Product",
          action: "switchProduct",
          label,
        });
      }

      this.$root.$emit("changeProduct", parseInt(this.$t(updatedProduct.id)) - 1, updatedProduct);
    },
    async updateDiscountCode(selectedRegionScoped) {
      console.log("update discount", selectedRegionScoped);
      if (selectedRegionScoped == null) return;
      try {
        const selectedProductType = store.state.selectedProduct.type;
        const discountData = await this.determineDiscountCodeAndValue(
          selectedProductType,
          selectedRegionScoped
        );
        const selectedRegionLowerCase = this.selectedRegionScoped.toLowerCase();
        const activePromotionCamelCase = store.state.activePromotionCamelCase;
        const payload = { discountData, activePromotionCamelCase };
        store.commit("setDiscountData", payload);
        const { vahaXPrice, vahaSPrice } = discountData;

        const selectedProductPriceAfter =
          selectedProductType === "products.productS.type"
            ? vahaSPrice
            : vahaXPrice;
        const vahaXbasePrice = BASE_PRICES[selectedRegionLowerCase].vahaX;
        const vahaSbasePrice = BASE_PRICES[selectedRegionLowerCase].vahaS;
        const pricesPayload = {
          vahaXPrice,
          vahaSPrice,
          selectedProductPriceAfter,
          vahaXbasePrice,
          vahaSbasePrice,
        };

        store.commit("setDynamicPriceAfter", pricesPayload);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
