import prepareCheckoutLink from "./prepareCheckoutLink";

export default {
  mixins: [prepareCheckoutLink],
  methods: {
    async redirectToShop(
      selectedLink,
      selectedProductTranslated,
      selectedProductId,
      selectedCurrencyScoped,
      selectedRegionScoped,
      isProductFlatrateScoped = false
    ) {
      this.$root.$emit("checkoutButtonClicked", true);
      if (window.dataLayer) {
        const { id, name } = selectedProductTranslated;
        window.dataLayer.push({
          event: "addToCart",
          ecommerce: {
            currencyCode: selectedCurrencyScoped, // currency of the shop (uk -> GBP)
            add: {
              // 'add' actionFieldObject measures.
              products: [{ id, name }],
            },
          },
        });

        let label = "";
        if (parseInt(id, 10) === 1) {
          label = "side_panel_vaha_x";
        } else if (parseInt(id, 10) === 2) {
          label = "side_panel_vaha_s";
        }

        window.dataLayer.push({
          event: "generic_event",
          category: "Checkout",
          action: "initiateCheckOut",
          label,
        });
      }

      console.log(isProductFlatrateScoped);

      let newLink = await this.prepareCheckoutLink(
        selectedProductId,
        selectedRegionScoped,
        isProductFlatrateScoped
      );
      console.log(`basket`, newLink);

      // if not flatrate product

      // to ensure we log data to data layer
      console.log(newLink);
      window.location.href = newLink;
      this.$root.$emit("checkoutButtonClicked", false);
      // setTimeout(window.open(link, "_self"), 0);
    },
  },
};
