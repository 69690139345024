// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AppIconButton_default_33Oxc{border:1.5px solid transparent;border-radius:4px;align-items:center;box-shadow:none;color:#222;cursor:pointer;display:inline-flex;font-family:inherit;font-size:1rem!important;font-weight:600!important;height:50px;justify-content:center;letter-spacing:.02em;line-height:30px;min-width:183px;white-space:nowrap;padding:calc(.5em - 1px) 1em}.AppIconButton_carbon_kMY7j{border:1.5px solid #0c1830;border-radius:4px;background-color:transparent}.AppIconButton_carbon_kMY7j:hover{border:1.5px solid #222;border-radius:4px;color:#fff;background-color:#0c1830}.AppIconButton_yellow_1BZqB{background-color:#ffd26e;color:#222}.AppIconButton_yellow_1BZqB:hover{border:2px solid inherit;border-radius:4px;background-color:#feba50;color:#222}.AppIconButton_transparent_1pLOX{background-color:transparent}", ""]);
// Exports
exports.locals = {
	"tablet": "766px",
	"desktop": "1025px",
	"widescreen": "1650px",
	"fullhd": "1921px",
	"default": "AppIconButton_default_33Oxc",
	"carbon": "AppIconButton_carbon_kMY7j",
	"yellow": "AppIconButton_yellow_1BZqB",
	"transparent": "AppIconButton_transparent_1pLOX"
};
module.exports = exports;
