// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AppGridContainer_container_2cZQ8{padding:0 25px;margin:0 auto}@media screen and (min-width:1025px){.AppGridContainer_container_2cZQ8{max-width:1210px}}@media screen and (min-width:1650px){.AppGridContainer_container_2cZQ8{max-width:1610px}}", ""]);
// Exports
exports.locals = {
	"tablet": "766px",
	"desktop": "1025px",
	"widescreen": "1650px",
	"fullhd": "1921px",
	"container": "AppGridContainer_container_2cZQ8"
};
module.exports = exports;
