// deprecated permalinks
const ukVahaXsubscriptionPermalink =
  "https://uk-checkout.vaha.com/cart/42459602682007:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1";
const ukVahaSsubscriptionPermalink =
  "https://uk-checkout.vaha.com/cart/42459613560983:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1";

const dachVahaSsubscriptionPermalink =
  "https://vaha.com/cart/43595166974219:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1";
const dachVahaXsubscriptionPermalink =
  "https://vaha.com/cart/43595164844299:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1";

import {
  wellnessSaleProductS,
  wellnessSaleProductX,
  flatrateWellnessSaleProductS,
  flatrateWellnessSaleProductX,
} from "./promotions/wellnessSale";

import {
  valentinesDayProductS,
  valentinesDayProductX,
  flatrateValentinesDayProductS,
  flatrateValentinesDayProductX,
} from "./promotions/valentinesDay";

import {
  achieveTheUnexpectedProductS,
  achieveTheUnexpectedProductX,
  flatrateAchieveTheUnexpectedProductS,
  flatrateAchieveTheUnexpectedProductX,
} from "./promotions/achieveTheUnexpected";

import {
  springIntoShapeProductS,
  springIntoShapeProductX,
  flatrateSpringIntoShapeProductS,
  flatrateSpringIntoShapeProductX,
} from "./promotions/springIntoShape";

import {
  goBeyondProductS,
  goBeyondProductX,
  flatrateGoBeyondProductS,
  flatrateGoBeyondProductX,
} from "./promotions/goBeyond";

import {
  easterSaleProductS,
  easterSaleProductX,
  flatrateEasterSaleProductS,
  flatrateEasterSaleProductX,
} from "./promotions/easterSale";

import {
  mayDaySaleProductS,
  mayDaySaleProductX,
  flatrateMayDaySaleProductS,
  flatrateMayDaySaleProductX,
} from "./promotions/mayDaySale";

import {
  vahaMomentsProductS,
  vahaMomentsProductX,
  flatrateVahaMomentsProductS,
  flatrateVahaMomentsProductX,
} from "./promotions/vahaMoments";

import {
  summerWithFlowProductS,
  summerWithFlowProductX,
  flatrateSummerWithFlowProductS,
  flatrateSummerWithFlowProductX,
} from "./promotions/summerWithFlow";

import {
  vahaPowerProductS,
  vahaPowerProductX,
  flatrateVahaPowerProductS,
  flatrateVahaPowerProductX,
} from "./promotions/vahaPower";

import {
  noDiscountProductS,
  noDiscountProductX,
  flatrateNoDiscountProductS,
  flatrateNoDiscountProductX,
} from "./promotions/noDiscount";

import {
  blackVahaProductS,
  blackVahaProductX,
  flatrateBlackVahaProductS,
  flatrateBlackVahaProductX,
} from "./promotions/blackVaha";

import {
  vahaxmasProductS,
  vahaxmasProductX,
  flatrateVahaxmasProductS,
  flatrateVahaxmasProductX,
} from "./promotions/vahaxmas";

import {
  vahanewyearProductS,
  vahanewyearProductX,
  flatrateVahanewyearProductS,
  flatrateVahanewyearProductX,
} from "./promotions/vahanewyear";

export const CHECKOUT_LINKS = {
  development: {
    uk: {
      current: {
        vahaX:
          "https://vaha-uk.myshopify.com/cart/38603968741527:1,38603967791255:1,38603967725719:1,38603968348311:1",
        vahaS:
          "https://vaha-uk.myshopify.com/cart/39596804079767:1,38603967791255:1,39618109636759:1,38603967725719:1,38603968348311:1",
      },
      transformationPackage: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,41283248193687:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,41283248193687:1",
      },
      blackFriday: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,41283248193687:1?discount=VAHABLACKFRIDAY",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,41283248193687:1?discount=VAHABLACKFRIDAY",
      },
      christmasPromo: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=VAHACHRISTMAS",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=VAHACHRISTMAS",
      },
      boxingDay: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=BOXINGDAYSALE",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=BOXINGDAYSALE",
      },
      newYear: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=VAHANEWYEAR",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=VAHANEWYEAR",
      },
      flashSale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=FLASHSALE",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=FLASHSALE",
      },
      januarySale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1",
      },
      wellnessSale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      valentinesDay: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      achieveTheUnexpected: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      springIntoShape: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      goBeyond: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      easterSale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      mayDaySale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      vahaMoments: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      summerWithFlow: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      vahaPower: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      noDiscount: {
        vahaX: ukVahaXsubscriptionPermalink,
        vahaS: ukVahaSsubscriptionPermalink,
      },
    },
    de: {
      flatrate: {
        vahaS:
          "https://checkout-staging-my.vaha.com/payment-options/?model=vaha-s&discount_code=WELLNESS",
        vahaX:
          "https://checkout-staging-my.vaha.com/payment-options?discount_code=WELLNESS",
      },
      current: {
        vahaS:
          "https://checkout-staging.vaha.com/payment-options/?model=vaha-s",
        vahaX: "https://checkout-staging.vaha.com/payment-options/",
      },
      transformationPackage: {
        vahaS:
          "https://checkout-staging.vaha.com/payment-options/?model=vaha-s",
        vahaX: "https://checkout-staging.vaha.com/payment-options/",
      },
      blackFriday: {
        vahaS:
          "https://checkout-staging.vaha.com/payment-options/?model=vaha-s&discount_code=VAHABLACKWEEK",
        vahaX:
          "https://checkout-staging.vaha.com/payment-options/?discount_code=VAHABLACKWEEK",
      },
      newYear: {
        vahaS:
          "https://checkout-staging.vaha.com/payment-options/?model=vaha-s&discount_code=VAHANEUJAHR",
        vahaX:
          "https://checkout-staging.vaha.com/payment-options/?discount_code=VAHANEUJAHR",
      },
      wellnessSale: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s&discount_code=WELLNESS",
        vahaX:
          "https://checkout-staging-your.vaha.com/payment-options/?discount_code=WELLNESS",
      },
      valentinesDay: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s&discount_code=VALENTINSTAG",
        vahaX:
          "https://checkout-staging-your.vaha.com/payment-options/?discount_code=VALENTINSTAG",
      },
      achieveTheUnexpected: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s&discount_code=UNERWARTET",
        vahaX:
          "https://checkout-staging-your.vaha.com/payment-options?discount_code=UNERWARTET",
      },
      springIntoShape: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s",
        vahaX: "https://checkout-staging-your.vaha.com/payment-options",
      },
      goBeyond: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      easterSale: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      mayDaySale: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      vahaMoments: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      summerWithFlow: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      vahaPower: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      noDiscount: {
        vahaS: dachVahaSsubscriptionPermalink,
        vahaX: dachVahaXsubscriptionPermalink,
      },
    },
    at: {
      flatrate: {
        vahaS:
          "https://checkout-staging-my.vaha.com/payment-options/?model=vaha-s&country=at&discount_code=WELLNESS",
        vahaX:
          "https://checkout-staging-my.vaha.com/payment-options/?country=at&discount_code=WELLNESS",
      },
      current: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,32468747485262:1,39530519625806:1,39530521886798:1,39530523426894:1,39530523656270:1?attributes[country=at]",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,32468747485262:1,39530519625806:1,39530521886798:1,39530523426894:1,39530523656270:1?attributes[country=at]",
      },
      transformationPackage: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,32468747485262:1,39530519625806:1,39530521886798:1,39530523426894:1,39530523656270:1?attributes[country=at]",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,32468747485262:1,39530519625806:1,39530521886798:1,39530523426894:1,39530523656270:1?attributes[country=at]",
      },
      blackFriday: {
        vahaS:
          "https://checkout-staging.vaha.com/payment-options/?model=vaha-s&discount_code=VAHABLACKWEEK",
        vahaX:
          "https://checkout-staging.vaha.com/payment-options/?discount_code=VAHABLACKWEEK",
      },
      newYear: {
        vahaS:
          "https://checkout-staging.vaha.com/payment-options/?model=vaha-s&discount_code=VAHANEUJAHR",
        vahaX:
          "https://checkout-staging.vaha.com/payment-options/?discount_code=VAHANEUJAHR",
      },
      wellnessSale: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s&discount_code=WELLNESS",
        vahaX:
          "https://checkout-staging-your.vaha.com/payment-options/?discount_code=WELLNESS",
      },
      valentinesDay: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s&discount_code=VALENTINSTAG",
        vahaX:
          "https://checkout-staging-your.vaha.com/payment-options/?discount_code=VALENTINSTAG",
      },
      achieveTheUnexpected: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s&discount_code=UNERWARTET",
        vahaX:
          "https://checkout-staging-your.vaha.com/payment-options?discount_code=UNERWARTET",
      },
      springIntoShape: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s",
        vahaX: "https://checkout-staging-your.vaha.com/payment-options",
      },
      goBeyond: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      easterSale: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      mayDaySale: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      vahaMoments: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      summerWithFlow: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      vahaPower: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      noDiscount: {
        vahaS: dachVahaSsubscriptionPermalink,
        vahaX: dachVahaXsubscriptionPermalink,
      },
    },
  },
  staging: {
    uk: {
      current: {
        vahaX:
          "https://vaha-uk.myshopify.com/cart/38603968741527:1,38603967791255:1,38603967725719:1,38603968348311:1",
        vahaS:
          "https://vaha-uk.myshopify.com/cart/39596804079767:1,38603967791255:1,39618109636759:1,38603967725719:1,38603968348311:1",
      },
      transformationPackage: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,41283248193687:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,41283248193687:1",
      },
      blackFriday: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,41283248193687:1?discount=VAHABLACKFRIDAY",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,41283248193687:1?discount=VAHABLACKFRIDAY",
      },
      christmasPromo: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=VAHACHRISTMAS",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=VAHACHRISTMAS",
      },
      boxingDay: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=BOXINGDAYSALE",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=BOXINGDAYSALE",
      },
      newYear: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=VAHANEWYEAR",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=VAHANEWYEAR",
      },
      flashSale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=FLASHSALE",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=FLASHSALE",
      },
      januarySale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1",
      },
      wellnessSale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      valentinesDay: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      achieveTheUnexpected: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      springIntoShape: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      goBeyond: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      easterSale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      mayDaySale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      vahaMoments: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      summerWithFlow: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      vahaPower: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      noDiscount: {
        vahaX: ukVahaXsubscriptionPermalink,
        vahaS: ukVahaSsubscriptionPermalink,
      },
    },
    de: {
      flatrate: {
        vahaS:
          "https://checkout-staging-my.vaha.com/payment-options/?model=vaha-s&discount_code=WELLNESS",
        vahaX:
          "https://checkout-staging-my.vaha.com/payment-options?discount_code=WELLNESS",
      },
      current: {
        vahaS:
          "https://checkout-staging.vaha.com/payment-options/?model=vaha-s",
        vahaX: "https://checkout-staging.vaha.com/payment-options/",
      },
      transformationPackage: {
        vahaS:
          "https://checkout-staging.vaha.com/payment-options/?model=vaha-s",
        vahaX: "https://checkout-staging.vaha.com/payment-options/",
      },
      blackFriday: {
        vahaS:
          "https://checkout-staging.vaha.com/payment-options/?model=vaha-s&discount_code=VAHABLACKWEEK",
        vahaX:
          "https://checkout-staging.vaha.com/payment-options/?discount_code=VAHABLACKWEEK",
      },
      newYear: {
        vahaS:
          "https://checkout-staging.vaha.com/payment-options/?model=vaha-s&discount_code=VAHANEUJAHR",
        vahaX:
          "https://checkout-staging.vaha.com/payment-options/?discount_code=VAHANEUJAHR",
      },
      wellnessSale: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s&discount_code=WELLNESS",
        vahaX:
          "https://checkout-staging-your.vaha.com/payment-options/?discount_code=WELLNESS",
      },
      valentinesDay: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s&discount_code=VALENTINSTAG",
        vahaX:
          "https://checkout-staging-your.vaha.com/payment-options/?discount_code=VALENTINSTAG",
      },
      achieveTheUnexpected: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s&discount_code=UNERWARTET",
        vahaX:
          "https://checkout-staging-your.vaha.com/payment-options?discount_code=UNERWARTET",
      },
      springIntoShape: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s",
        vahaX: "https://checkout-staging-your.vaha.com/payment-options",
      },
      goBeyond: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      easterSale: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      mayDaySale: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      vahaMoments: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      summerWithFlow: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      vahaPower: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      noDiscount: {
        vahaS: dachVahaSsubscriptionPermalink,
        vahaX: dachVahaXsubscriptionPermalink,
      },
    },
    at: {
      flatrate: {
        vahaS:
          "https://checkout-staging-my.vaha.com/payment-options/?model=vaha-s&country=at&discount_code=WELLNESS",
        vahaX:
          "https://checkout-staging-my.vaha.com/payment-options/?country=at&discount_code=WELLNESS",
      },
      current: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,32468747485262:1,39530519625806:1,39530521886798:1,39530523426894:1,39530523656270:1?attributes[country=at]",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,32468747485262:1,39530519625806:1,39530521886798:1,39530523426894:1,39530523656270:1?attributes[country=at]",
      },
      transformationPackage: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,32468747485262:1,39530519625806:1,39530521886798:1,39530523426894:1,39530523656270:1?attributes[country=at]",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,32468747485262:1,39530519625806:1,39530521886798:1,39530523426894:1,39530523656270:1?attributes[country=at]",
      },
      blackFriday: {
        vahaS:
          "https://checkout-staging.vaha.com/payment-options/?model=vaha-s&discount_code=VAHABLACKWEEK",
        vahaX:
          "https://checkout-staging.vaha.com/payment-options/?discount_code=VAHABLACKWEEK",
      },
      newYear: {
        vahaS:
          "https://checkout-staging.vaha.com/payment-options/?model=vaha-s&discount_code=VAHANEUJAHR",
        vahaX:
          "https://checkout-staging.vaha.com/payment-options/?discount_code=VAHANEUJAHR",
      },
      wellnessSale: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s&discount_code=WELLNESS",
        vahaX:
          "https://checkout-staging-your.vaha.com/payment-options/?discount_code=WELLNESS",
      },
      valentinesDay: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s&discount_code=VALENTINSTAG",
        vahaX:
          "https://checkout-staging-your.vaha.com/payment-options/?discount_code=VALENTINSTAG",
      },
      achieveTheUnexpected: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s&discount_code=UNERWARTET",
        vahaX:
          "https://checkout-staging-your.vaha.com/payment-options?discount_code=UNERWARTET",
      },
      springIntoShape: {
        vahaS:
          "https://checkout-staging-your.vaha.com/payment-options/?model=vaha-s",
        vahaX: "https://checkout-staging-your.vaha.com/payment-options",
      },
      goBeyond: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      easterSale: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      mayDaySale: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      vahaMoments: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      summerWithFlow: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      vahaPower: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      noDiscount: {
        vahaS: dachVahaSsubscriptionPermalink,
        vahaX: dachVahaXsubscriptionPermalink,
      },
    },
  },
  production: {
    uk: {
      current: {
        vahaX:
          "https://vaha-uk.myshopify.com/cart/38603968741527:1,38603967791255:1,38603967725719:1,38603968348311:1",
        vahaS:
          "https://vaha-uk.myshopify.com/cart/39596804079767:1,38603967791255:1,39618109636759:1,38603967725719:1,38603968348311:1",
      },
      transformationPackage: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,41283248193687:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,41283248193687:1",
      },
      blackFriday: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,41283248193687:1?discount=VAHABLACKFRIDAY",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,41283248193687:1?discount=VAHABLACKFRIDAY",
      },
      christmasPromo: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=VAHACHRISTMAS",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=VAHACHRISTMAS",
      },
      boxingDay: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=BOXINGDAYSALE",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=BOXINGDAYSALE",
      },
      newYear: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=VAHANEWYEAR",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=VAHANEWYEAR",
      },
      flashSale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=FLASHSALE",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1?discount=FLASHSALE",
      },
      januarySale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41283218440343:1,41283229253783:1,38603967725719:1",
      },
      wellnessSale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      valentinesDay: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      achieveTheUnexpected: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      springIntoShape: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      goBeyond: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      easterSale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      mayDaySale: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      vahaMoments: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      summerWithFlow: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      vahaPower: {
        vahaX:
          "https://uk-checkout.vaha.com/cart/38603968741527:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
        vahaS:
          "https://uk-checkout.vaha.com/cart/39596804079767:1,38603968348311:1,38603967791255:1,41694599250071:1,41283229253783:1,38603967725719:1",
      },
      noDiscount: {
        vahaX: ukVahaXsubscriptionPermalink,
        vahaS: ukVahaSsubscriptionPermalink,
      },
    },
    de: {
      flatrate: {
        vahaS:
          "https://my-checkout.vaha.com/payment-options/?model=vaha-s&discount_code=WELLNESS",
        vahaX:
          "https://my-checkout.vaha.com/payment-options?discount_code=WELLNESS",
      },
      current: {
        vahaS: "https://your-checkout.vaha.com/payment-options/?model=vaha-s",
        vahaX: "https://your-checkout.vaha.com/payment-options/",
      },
      transformationPackage: {
        vahaS: "https://your-checkout.vaha.com/payment-options/?model=vaha-s",
        vahaX: "https://your-checkout.vaha.com/payment-options/",
      },
      newYear: {
        vahaS:
          "https://your-checkout.vaha.com/payment-options/?model=vaha-s&discount_code=VAHANEUJAHR",
        vahaX:
          "https://your-checkout.vaha.com/payment-options/?discount_code=VAHANEUJAHR",
      },
      valentinesDay: {
        vahaS:
          "https://your-checkout.vaha.com/payment-options/?model=vaha-s&discount_code=VALENTINSTAG",
        vahaX:
          "https://your-checkout.vaha.com/payment-options/?discount_code=VALENTINSTAG",
      },
      achieveTheUnexpected: {
        vahaS:
          "https://your-checkout.vaha.com/payment-options/?model=vaha-s&discount_code=UNERWARTET",
        vahaX:
          "https://your-checkout.vaha.com/payment-options/?discount_code=UNERWARTET",
      },
      springIntoShape: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      goBeyond: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      easterSale: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      mayDaySale: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      vahaMoments: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      summerWithFlow: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      vahaPower: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      noDiscount: {
        vahaS: dachVahaSsubscriptionPermalink,
        vahaX: dachVahaXsubscriptionPermalink,
      },
    },
    at: {
      flatrate: {
        vahaS:
          "https://my-checkout.vaha.com/payment-options/?model=vaha-s&country=at&discount_code=WELLNESS",
        vahaX:
          "https://my-checkout.vaha.com/payment-options/?country=at&discount_code=WELLNESS",
      },
      current: {
        vahaS:
          "https://your-checkout.vaha.com/payment-options/?model=vaha-s&country=at",
        vahaX: "https://your-checkout.vaha.com/payment-options/?country=at",
      },
      transformationPackage: {
        vahaS:
          "https://your-checkout.vaha.com/payment-options/?model=vaha-s&country=at",
        vahaX: "https://your-checkout.vaha.com/payment-options/?country=at",
      },
      newYear: {
        vahaS:
          "https://your-checkout.vaha.com/payment-options/?model=vaha-s&discount_code=VAHANEUJAHR",
        vahaX:
          "https://your-checkout.vaha.com/payment-options/?discount_code=VAHANEUJAHR",
      },
      valentinesDay: {
        vahaS:
          "https://your-checkout.vaha.com/payment-options/?model=vaha-s&discount_code=VALENTINSTAG",
        vahaX:
          "https://your-checkout.vaha.com/payment-options/?discount_code=VALENTINSTAG",
      },
      achieveTheUnexpected: {
        vahaS:
          "https://your-checkout.vaha.com/payment-options/?model=vaha-s&discount_code=UNERWARTET",
        vahaX:
          "https://your-checkout.vaha.com/payment-options/?discount_code=UNERWARTET",
      },
      springIntoShape: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      goBeyond: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      easterSale: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      mayDaySale: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      vahaMoments: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      summerWithFlow: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      vahaPower: {
        vahaS:
          "https://vaha.com/cart/39356723003470:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
        vahaX:
          "https://vaha.com/cart/31644552659022:1,39530519625806:1,39575900389454:1,39530523426894:1,31809039663182:1,39664371531854:1",
      },
      noDiscount: {
        vahaS: dachVahaSsubscriptionPermalink,
        vahaX: dachVahaXsubscriptionPermalink,
      },
    },
  },
};

export const BASE_PRICES = {
  uk: {
    vahaX: "2250",
    vahaS: "1750",
  },
  de: {
    vahaX: "199",
    vahaS: "199",
  },
  at: {
    vahaX: "199",
    vahaS: "199",
  },
  ae: {
    vahaX: "12000",
    vahaS: "9000",
  }
};

export const SELECTED_PRODUCT = {
  id: "products.productS.id",
  type: "products.productS.type",
  promotions: {
    flashSale: {
      mainPromoHeadline:
        "products.productS.promotions.flashSale.mainPromoHeadline",
      heading: {
        image: "products.productS.promotions.flashSale.heading.image",
        title: "products.productS.promotions.flashSale.heading.title",
      },
      footer: "products.productS.promotions.flashSale.footer",
      total: {
        headline: "products.productS.promotions.flashSale.total.headline",
        priceBefore: "products.productS.promotions.flashSale.total.priceBefore",
        priceAfter: "products.productS.promotions.flashSale.total.priceAfter",
      },
      discount: {
        heading: "products.productS.promotions.flashSale.discount.heading",
        title: "products.productS.promotions.flashSale.discount.title",
        amount: "products.productS.promotions.flashSale.discount.amount",
        code: {
          description:
            "products.productS.promotions.flashSale.discount.code.description",
          promoCode:
            "products.productS.promotions.flashSale.discount.code.promoCode",
        },
      },
      items: {
        item1: {
          title: "products.productS.promotions.flashSale.items.item1.title",
          description:
            "products.productS.promotions.flashSale.items.item1.description",
          priceBefore:
            "products.productS.promotions.flashSale.items.item1.priceBefore",
          priceAfter:
            "products.productS.promotions.flashSale.items.item1.priceAfter",
        },
        item2: {
          title: "products.productS.promotions.flashSale.items.item2.title",
          description:
            "products.productS.promotions.flashSale.items.item2.description",
          priceBefore:
            "products.productS.promotions.flashSale.items.item2.priceBefore",
          priceAfter: "flashSale.items.products.productS.item2.priceAfter",
        },
        item3: {
          title: "products.productS.promotions.flashSale.items.item3.title",
          description:
            "products.productS.promotions.flashSale.items.item3.description",
          priceBefore:
            "products.productS.promotions.flashSale.items.item3.priceBefore",
          priceAfter:
            "products.productS.promotions.flashSale.items.item3.priceAfter",
        },
        item4: {
          title: "products.productS.promotions.flashSale.items.item4.title",
          description:
            "products.productS.promotions.flashSale.items.item4.description",
          priceBefore:
            "products.productS.promotions.flashSale.items.item4.priceBefore",
          priceAfter:
            "products.productS.promotions.flashSale.items.item4.priceAfter",
        },
        item5: {
          title: "products.productS.promotions.flashSale.items.item5.title",
          description:
            "products.productS.promotions.flashSale.items.item5.description",
          priceBefore:
            "products.productS.promotions.flashSale.items.item5.priceBefore",
          priceAfter:
            "products.productS.promotions.flashSale.items.item5.priceAfter",
        },
      },
    },
    flatrateFlashSale: {
      heading: {
        image: "products.productS.promotions.flatrateFlashSale.heading.image",
        title: "products.productS.promotions.flatrateFlashSale.heading.title",
      },
      mainPromoHeadline:
        "products.productS.promotions.flatrateFlashSale.mainPromoHeadline",
      total: {
        headline:
          "products.productS.promotions.flatrateFlashSale.total.headline",
        priceBefore:
          "products.productS.promotions.flatrateFlashSale.total.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateFlashSale.total.priceAfter",
      },
      footer: "products.productS.promotions.flatrateFlashSale.footer",
      discount: {
        title: "products.productS.promotions.flatrateFlashSale.discount.title",
        heading:
          "products.productS.promotions.flatrateFlashSale.discount.heading",
        amount:
          "products.productS.promotions.flatrateFlashSale.discount.amount",
        code: {
          description:
            "products.productS.promotions.flatrateFlashSale.discount.code.description",
          promoCode:
            "products.productS.promotions.flatrateFlashSale.discount.code.promoCode",
        },
      },
      items: {
        item1: {
          title:
            "products.productS.promotions.flatrateFlashSale.items.item1.title",
          description:
            "products.productS.promotions.flatrateFlashSale.items.item1.description",
          priceBefore:
            "products.productS.promotions.flatrateFlashSale.items.item1.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateFlashSale.items.item1.priceAfter",
        },
        item2: {
          title:
            "products.productS.promotions.flatrateFlashSale.items.item2.title",
          description:
            "products.productS.promotions.flatrateFlashSale.items.item2.description",
          priceBefore:
            "products.productS.promotions.flatrateFlashSale.items.item2.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateFlashSale.items.item2.priceAfter",
        },
        item3: {
          title:
            "products.productS.promotions.flatrateFlashSale.items.item3.title",
          description:
            "products.productS.promotions.flatrateFlashSale.items.item3.description",
          priceBefore:
            "products.productS.promotions.flatrateFlashSale.items.item3.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateFlashSale.items.item3.priceAfter",
        },
        item4: {
          title:
            "products.productS.promotions.flatrateFlashSale.items.item4.title",
          description:
            "products.productS.promotions.flatrateFlashSale.items.item4.description",
          priceBefore:
            "products.productS.promotions.flatrateFlashSale.items.item4.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateFlashSale.items.item4.priceAfter",
        },
        item5: {
          title:
            "products.productS.promotions.flatrateFlashSale.items.item5.title",
          description:
            "products.productS.promotions.flatrateFlashSale.items.item5.description",
          priceBefore:
            "products.productS.promotions.flatrateFlashSale.items.item5.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateFlashSale.items.item5.priceAfter",
        },
        item6: {
          title:
            "products.productS.promotions.flatrateFlashSale.items.item6.title",
          description:
            "products.productS.promotions.flatrateFlashSale.items.item6.description",
          priceBefore:
            "products.productS.promotions.flatrateFlashSale.items.item6.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateFlashSale.items.item6.priceAfter",
        },
      },
    },
    januarySale: {
      mainPromoHeadline:
        "products.productS.promotions.januarySale.mainPromoHeadline",
      heading: {
        image: "products.productS.promotions.januarySale.heading.image",
        title: "products.productS.promotions.januarySale.heading.title",
      },
      pricesBeforeAfter: {
        priceBefore:
          "products.productS.promotions.januarySale.pricesBeforeAfter.priceBefore",
        priceAfter:
          "products.productS.promotions.januarySale.pricesBeforeAfter.priceAfter",
      },
      footer: "products.productS.promotions.januarySale.footer",
      total: {
        headline: "products.productS.promotions.januarySale.total.headline",
        priceBefore:
          "products.productS.promotions.januarySale.total.priceBefore",
        priceAfter: "products.productS.promotions.januarySale.total.priceAfter",
      },
      discount: {
        heading: "products.productS.promotions.januarySale.discount.heading",
        title: "products.productS.promotions.januarySale.discount.title",
        amount: "products.productS.promotions.januarySale.discount.amount",
        code: {
          description:
            "products.productS.promotions.januarySale.discount.code.description",
          promoCode:
            "products.productS.promotions.januarySale.discount.code.promoCode",
        },
      },
      items: {
        item1: {
          title: "products.productS.promotions.januarySale.items.item1.title",
          description:
            "products.productS.promotions.januarySale.items.item1.description",
          priceBefore:
            "products.productS.promotions.januarySale.items.item1.priceBefore",
          priceAfter:
            "products.productS.promotions.januarySale.items.item1.priceAfter",
        },
        item2: {
          title: "products.productS.promotions.januarySale.items.item2.title",
          description:
            "products.productS.promotions.januarySale.items.item2.description",
          priceBefore:
            "products.productS.promotions.januarySale.items.item2.priceBefore",
          priceAfter: "januarySale.items.products.productS.item2.priceAfter",
        },
        item3: {
          title: "products.productS.promotions.januarySale.items.item3.title",
          description:
            "products.productS.promotions.januarySale.items.item3.description",
          priceBefore:
            "products.productS.promotions.januarySale.items.item3.priceBefore",
          priceAfter:
            "products.productS.promotions.januarySale.items.item3.priceAfter",
        },
        item4: {
          title: "products.productS.promotions.januarySale.items.item4.title",
          description:
            "products.productS.promotions.januarySale.items.item4.description",
          priceBefore:
            "products.productS.promotions.januarySale.items.item4.priceBefore",
          priceAfter:
            "products.productS.promotions.januarySale.items.item4.priceAfter",
        },
        item5: {
          title: "products.productS.promotions.januarySale.items.item5.title",
          description:
            "products.productS.promotions.januarySale.items.item5.description",
          priceBefore:
            "products.productS.promotions.januarySale.items.item5.priceBefore",
          priceAfter:
            "products.productS.promotions.januarySale.items.item5.priceAfter",
        },
      },
    },
    flatrateJanuarySale: {
      mainPromoHeadline:
        "products.productS.promotions.flatrateJanuarySale.mainPromoHeadline",
      heading: {
        image: "products.productS.promotions.flatrateJanuarySale.heading.image",
        title: "products.productS.promotions.flatrateJanuarySale.heading.title",
      },
      footer: "products.productS.promotions.flatrateJanuarySale.footer",
      total: {
        headline:
          "products.productS.promotions.flatrateJanuarySale.total.headline",
        priceBefore:
          "products.productS.promotions.flatrateJanuarySale.total.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateJanuarySale.total.priceAfter",
      },
      discount: {
        heading: "products.productS.promotions.januarySale.discount.heading",
        title:
          "products.productS.promotions.flatrateJanuarySale.discount.title",
        amount:
          "products.productS.promotions.flatrateJanuarySale.discount.amount",
        code: {
          description:
            "products.productS.promotions.flatrateJanuarySale.discount.code.description",
          promoCode:
            "products.productS.promotions.flatrateJanuarySale.discount.code.promoCode",
        },
      },
      items: {
        item1: {
          title:
            "products.productS.promotions.flatrateJanuarySale.items.item1.title",
          description:
            "products.productS.promotions.flatrateJanuarySale.items.item1.description",
          priceBefore:
            "products.productS.promotions.flatrateJanuarySale.items.item1.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateJanuarySale.items.item1.priceAfter",
        },
        item2: {
          title:
            "products.productS.promotions.flatrateJanuarySale.items.item2.title",
          description:
            "products.productS.promotions.flatrateJanuarySale.items.item2.description",
          priceBefore:
            "products.productS.promotions.flatrateJanuarySale.items.item2.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateJanuarySale.items.item2.priceAfter",
        },
        item3: {
          title:
            "products.productS.promotions.flatrateJanuarySale.items.item3.title",
          description:
            "products.productS.promotions.flatrateJanuarySale.items.item3.description",
          priceBefore:
            "products.productS.promotions.flatrateJanuarySale.items.item3.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateJanuarySale.items.item3.priceAfter",
        },
        item4: {
          title:
            "products.productS.promotions.flatrateJanuarySale.items.item4.title",
          description:
            "products.productS.promotions.flatrateJanuarySale.items.item4.description",
          priceBefore:
            "products.productS.promotions.flatrateJanuarySale.items.item4.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateJanuarySale.items.item4.priceAfter",
        },
        item5: {
          title:
            "products.productS.promotions.flatrateJanuarySale.items.item5.title",
          description:
            "products.productS.promotions.flatrateJanuarySale.items.item5.description",
          priceBefore:
            "products.productS.promotions.flatrateJanuarySale.items.item5.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateJanuarySale.items.item5.priceAfter",
        },
        item6: {
          title:
            "products.productS.promotions.flatrateJanuarySale.items.item6.title",
          description:
            "products.productS.promotions.flatrateJanuarySale.items.item6.description",
          priceBefore:
            "products.productS.promotions.flatrateJanuarySale.items.item6.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateJanuarySale.items.item6.priceAfter",
        },
      },
    },
    wellnessSale: wellnessSaleProductS,
    flatrateWellnessSale: flatrateWellnessSaleProductS,
    valentinesDay: valentinesDayProductS,
    flatrateValentinesDay: flatrateValentinesDayProductS,
    achieveTheUnexpected: achieveTheUnexpectedProductS,
    flatrateAchieveTheUnexpected: flatrateAchieveTheUnexpectedProductS,
    springIntoShape: springIntoShapeProductS,
    flatrateSpringIntoShape: flatrateSpringIntoShapeProductS,
    goBeyond: goBeyondProductS,
    flatrateGoBeyond: flatrateGoBeyondProductS,
    easterSale: easterSaleProductS,
    flatrateEasterSale: flatrateEasterSaleProductS,
    mayDaySale: mayDaySaleProductS,
    flatrateMayDaySale: flatrateMayDaySaleProductS,
    vahaMoments: vahaMomentsProductS,
    flatrateVahaMoments: flatrateVahaMomentsProductS,
    summerWithFlow: summerWithFlowProductS,
    flatrateSummerWithFlow: flatrateSummerWithFlowProductS,
    vahaPower: vahaPowerProductS,
    flatrateVahaPowerWithFlow: flatrateVahaPowerProductS,
    noDiscount: noDiscountProductS,
    flatrateNoDiscountWithFlow: flatrateNoDiscountProductS,
    blackVaha: blackVahaProductS,
    flatrateBlackVaha: flatrateBlackVahaProductS,
    vahaxmas: vahaxmasProductS,
    flatrateVahaxmas: flatrateVahaxmasProductS,
    vahanewyear: vahanewyearProductS,
    flatrateVahanewyear: flatrateVahaMomentsProductS
  },
  promotionPriceRow: {
    priceBefore: "products.productS.promotionPriceRow.priceBefore",
    priceAfter: "products.productS.promotionPriceRow.priceAfter",
  },
  basket3Image: "products.productS.basket3Image",
  basket3delivery: "products.productS.basket3delivery",
  basket3data: {
    row1: {
      id: "products.productS.basket3data.row1.id",
      titleRow1: "products.productS.basket3data.row1.titleRow1",
      titleRow2: "products.productS.basket3data.row1.titleRow2",
      priceFrom: "products.productS.basket3data.row1.priceFrom",
      priceBefore: "products.productS.basket3data.row1.priceBefore",
      priceAfter: "products.productS.basket3data.row1.priceAfter",
      priceRow2: "products.productS.basket3data.row1.priceRow2",
      content: "products.productS.basket3data.row1.content",
      list: {
        item1: "products.productS.basket3data.row1.list.item1",
        item2: "products.productS.basket3data.row1.list.item2",
        item3: "products.productS.basket3data.row1.list.item3",
        item4: "products.productS.basket3data.row1.list.item4",
      },
      image: "products.productS.basket3data.row1.image",
    },
    row2: {
      id: "products.productS.basket3data.row2.id",
      titleRow1: "products.productS.basket3data.row2.titleRow1",
      titleRow2: "products.productS.basket3data.row2.titleRow2",
      priceFrom: "products.productS.basket3data.row2.priceFrom",
      priceBefore: "products.productS.basket3data.row2.priceBefore",
      priceAfter: "products.productS.basket3data.row2.priceAfter",
      priceRow2: "products.productS.basket3data.row2.priceRow2",
      content: "products.productS.basket3data.row2.content",
      list: {
        item1: "products.productS.basket3data.row2.list.item1",
        item2: "products.productS.basket3data.row2.list.item2",
        item3: "products.productS.basket3data.row2.list.item3",
        item4: "products.productS.basket3data.row2.list.item4",
      },
      image: "products.productS.basket3data.row2.image",
    },
    row3: {
      id: "products.productS.basket3data.row3.id",
      titleRow1: "products.productS.basket3data.row3.titleRow1",
      titleRow2: "products.productS.basket3data.row3.titleRow2",
      priceFrom: "products.productS.basket3data.row3.priceFrom",
      priceBefore: "products.productS.basket3data.row3.priceBefore",
      priceAfter: "products.productS.basket3data.row3.priceAfter",
      priceRow2: "products.productS.basket3data.row3.priceRow2",
      content: "products.productS.basket3data.row3.content",
      list: {
        item1: "products.productS.basket3data.row3.list.item1",
        item2: "products.productS.basket3data.row3.list.item2",
        item3: "products.productS.basket3data.row3.list.item3",
        item4: "products.productS.basket3data.row3.list.item4",
      },
      image: "products.productS.basket3data.row3.image",
    },
    row4: {
      id: "products.productS.basket3data.row4.id",
      titleRow1: "products.productS.basket3data.row4.titleRow1",
      titleRow2: "products.productS.basket3data.row4.titleRow2",
      priceFrom: "products.productS.basket3data.row4.priceFrom",
      priceBefore: "products.productS.basket3data.row4.priceBefore",
      priceAfter: "products.productS.basket3data.row4.priceAfter",
      priceRow2: "products.productS.basket3data.row4.priceRow2",
      content: "products.productS.basket3data.row4.content",
      list: {
        item1: "products.productS.basket3data.row4.list.item1",
        item2: "products.productS.basket3data.row4.list.item2",
        item3: "products.productS.basket3data.row4.list.item3",
        item4: "products.productS.basket3data.row4.list.item4",
      },
      image: "products.productS.basket3data.row4.image",
    },
  },
  basket3Heading: {
    icon: "products.productS.basket3Heading.icon",
    text: "products.productS.basket3Heading.text",
  },
  basket3Headline: "Einkaufswagen",
  basket3footer: {
    prices: {
      priceLeft: {
        amount: "products.productS.basket3footer.prices.priceLeft.amount",
        text: "products.productS.basket3footer.prices.priceLeft.text",
      },
      priceRight: {
        amount: "products.productS.basket3footer.prices.priceRight.amount",
        text: "products.productS.basket3footer.prices.priceRight.text",
      },
    },
    bottomText: "products.productS.basket3footer.bottomText",
  },
  sound: "products.productS.sound",
  touchscreen: "products.productS.touchscreen",
  gtm: {
    price: "products.productS.gtm.price",
    currency: "products.productS.gtm.currency",
  },
  flatrate: {
    prices: {
      "36m": "products.productS.flatrate.prices.36m",
      "24m": "products.productS.flatrate.prices.24m",
      "12m": "products.productS.flatrate.prices.12m",
      christmasPromo: "products.productS.flatrate.prices.christmasPromo",
      christmasPromoBefore:
        "products.productS.flatrate.prices.christmasPromoBefore",
      wellnessSale: "products.productS.flatrate.prices.wellnessSale",
    },
  },
  dimensions: {
    size: "products.productS.dimensions.size",
    weight: "products.productS.dimensions.weight",
  },
  shipping: "products.productS.shipping",
  shippingExperimentwvSDVbi8R6KyxCB9T0CkrA:
    "products.productS.shippingExperimentwvSDVbi8R6KyxCB9T0CkrA",
  image:
    "https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/images/basket/VAHA_S_TRANSPARENT.png",
  imageBigger:
    "https://uk-vaha.s3.eu-central-1.amazonaws.com/static/assets/images/basket/VAHA_150_BIGGER.png",
  imageBasket: "https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/images/basket/vaha_s_basket.png",
  payUpfront: {
    prices: {
      promotional: "1.695 €",
      original: "1.995 €",
      monthly: "199 € mtl.",
      monthlyBare: "199 €"
    }
  },
  name: "products.productS.name",
  bulletPoints: {
    twoYearsWarranty: "products.productS.bulletPoints.twoYearsWarranty",
    inclWallBracket: "products.productS.bulletPoints.inclWallBracket",
    // inclFloorStand: 'products.productS.bulletPoints.inclFloorStand',
    zeroFinancing: "products.productS.bulletPoints.zeroFinancing",
  },
  freePremiumService: {
    label: "products.productS.freePremiumService.label",
    priceBefore: "products.productS.freePremiumService.priceBefore",
    priceAfter: "products.productS.freePremiumService.priceAfter",
  },
  prices: {
    "12m": {
      name: "products.productS.prices.12m.name",
      period: "products.productS.prices.12m.period",
      price: "products.productS.prices.12m.price",
    },
    "24m": {
      name: "products.productS.prices.24m.name",
      period: "products.productS.prices.24m.period",
      price: "products.productS.prices.24m.price",
    },
    "39m": {
      name: "products.productS.prices.39m.name",
      period: "products.productS.prices.39m.period",
      price: "products.productS.prices.39m.price",
    },
    direct: {
      name: "products.productS.prices.direct.name",
      price: "products.productS.prices.direct.price",
    },
    recommended: {
      name: "products.productS.prices.recommended.name",
      period: "products.productS.prices.recommended.period",
      price: "products.productS.prices.recommended.price",
    },
    basketRecommended: {
      name: "products.productS.prices.basketRecommended.name",
      period: "products.productS.prices.basketRecommended.period",
      price: "products.productS.prices.basketRecommended.price",
    },
    experimentwvSDVbi8R6KyxCB9T0CkrA: {
      "12m": {
        name:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.12m.name",
        period:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.12m.period",
        price:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.12m.price",
      },
      "24m": {
        name:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.24m.name",
        period:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.24m.period",
        price:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.24m.price",
      },
      "39m": {
        name:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.39m.name",
        period:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.39m.period",
        price:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.39m.price",
      },
      direct: {
        name:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.direct.name",
        price:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.direct.price",
      },
      recommended: {
        name:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA/recommended.name",
        period:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.recommended.period",
        price:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.recommended.price",
      },
      basketRecommended: {
        name:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.basketRecommended.name",
        period:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.basketRecommended.period",
        price:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.basketRecommended.price",
      },
    },
  },
  specialLaunch: {
    show: "products.productS.specialLaunch.show",
    headline: "products.productS.specialLaunch.headline",
    terms: "products.productS.specialLaunch.terms",
    bulletPoints: {
      jabra: {
        content: "products.productS.specialLaunch.bulletPoints.jabra.content",
        priceBefore:
          "products.productS.specialLaunch.bulletPoints.jabra.priceBefore",
        priceAfter:
          "products.productS.specialLaunch.bulletPoints.jabra.priceAfter",
      },
      delivery: {
        content:
          "products.productS.specialLaunch.bulletPoints.delivery.content",
        priceBefore:
          "products.productS.specialLaunch.bulletPoints.delivery.priceBefore",
        priceAfter:
          "products.productS.specialLaunch.bulletPoints.delivery.priceAfter",
      },
      testing: {
        content: "products.productS.specialLaunch.bulletPoints.testing.content",
        priceBefore:
          "products.productS.specialLaunch.bulletPoints.testing.priceBefore",
        priceAfter:
          "products.productS.specialLaunch.bulletPoints.testing.priceAfter",
      },
      warranty: {
        content:
          "products.productS.specialLaunch.bulletPoints.warranty.content",
        priceBefore:
          "products.productS.specialLaunch.bulletPoints.warranty.priceBefore",
        priceAfter:
          "products.productS.specialLaunch.bulletPoints.warranty.priceAfter",
      },
    },
  },
  membership: {
    headline: "products.productS.membership.headline",
    bulletPoints: {
      oneMonthFree: "products.productS.membership.bulletPoints.oneMonthFree",
      twelveMonthsTerm:
        "products.productS.membership.bulletPoints.twelveMonthsTerm",
      billingAfterMirrorActivation:
        "products.productS.membership.bulletPoints.billingAfterMirrorActivation",
    },
    price: "products.productS.membership.price",
    priceHint: "products.productS.membership.priceHint",
    priceLabel: "products.productS.membership.priceLabel",
    pricePeriod: "products.productS.membership.pricePeriod",
  },
  starterBox: {
    headline: "products.productS.starterBox.headline",
    bulletPoints: {
      heartRate: "products.productS.starterBox.bulletPoints.heartRate",
      elasticBands: "products.productS.starterBox.bulletPoints.elasticBands",
      microfiberCloth:
        "products.productS.starterBox.bulletPoints.microfiberCloth",
      cameraCover: "products.productS.starterBox.bulletPoints.cameraCover",
      userManual: "products.productS.starterBox.bulletPoints.userManual",
    },
    price: "products.productS.starterBox.price",
    priceHint: "products.productS.starterBox.priceHint",
    priceLabel: "products.productS.starterBox.priceLabel",
  },
  financingOptions: {
    items: {
      "12m": {
        id: "products.productS.financingOptions.items.12m.id",
        nameKey: "products.productS.financingOptions.items.12m.nameKey",
        priceKey: "products.productS.financingOptions.items.12m.priceKey",
        periodKey: "products.productS.financingOptions.items.12m.periodKey",
      },
      "24m": {
        id: "products.productS.financingOptions.items.24m.id",
        nameKey: "products.productS.financingOptions.items.24m.nameKey",
        priceKey: "products.productS.financingOptions.items.24m.priceKey",
        periodKey: "products.productS.financingOptions.items.24m.periodKey",
      },
      "36m": {
        id: "products.productS.financingOptions.items.36m.id",
        nameKey: "products.productS.financingOptions.items.36m.nameKey",
        priceKey: "products.productS.financingOptions.items.36m.priceKey",
        periodKey: "products.productS.financingOptions.items.36m.periodKey",
      },
      "39m": {
        id: "products.productS.financingOptions.items.39m.id",
        nameKey: "products.productS.financingOptions.items.39m.nameKey",
        priceKey: "products.productS.financingOptions.items.39m.priceKey",
        periodKey: "products.productS.financingOptions.items.39m.periodKey",
      },
      "48m": {
        id: "products.productS.financingOptions.items.48m.id",
        nameKey: "products.productS.financingOptions.items.48m.nameKey",
        priceKey: "products.productS.financingOptions.items.48m.priceKey",
        periodKey: "products.productS.financingOptions.items.48m.periodKey",
      },
      direct: {
        id: "products.productS.financingOptions.items.direct.id",
        nameKey: "products.productS.financingOptions.items.direct.nameKey",
        priceKey: "products.productS.financingOptions.items.direct.priceKey",
        periodKey: "products.productS.financingOptions.items.direct.periodKey",
      },
    },
    advantageKeys: {
      interest: "products.productS.financingOptions.advantageKeys.interest",
      totalPrice: "products.productS.financingOptions.advantageKeys.totalPrice",
      vat: "products.productS.financingOptions.advantageKeys.vat",
    },
  },
  financingOptionsSection: {
    mirror: {
      type: "products.productS.financingOptionsSection.mirror.type",
      headline: "products.productS.financingOptionsSection.mirror.headline",
      priceHint: "products.productS.financingOptionsSection.mirror.priceHint",
      price: "products.productS.financingOptionsSection.mirror.price",
      period: "products.productS.financingOptionsSection.mirror.period",
      linkText: "products.productS.financingOptionsSection.mirror.linkText",
    },
    membership: {
      type: "products.productS.financingOptionsSection.membership.type",
      headline: "products.productS.financingOptionsSection.membership.headline",
      price: "products.productS.financingOptionsSection.membership.price",
      period: "products.productS.financingOptionsSection.membership.period",
      linkText: "products.productS.financingOptionsSection.membership.linkText",
    },
    total: {
      totalPrice: "products.productS.financingOptionsSection.total.totalPrice",
      totalPeriod:
        "products.productS.financingOptionsSection.total.totalPeriod",
      disclaimerFirst:
        "products.productS.financingOptionsSection.total.disclaimerFirst",
      disclaimerSecond:
        "products.productS.financingOptionsSection.total.disclaimerSecond",
    },
  },
  paymentButton: {
    url: "products.productS.tmpData.paymentButton.url",
    label: "products.productS.tmpData.paymentButton.label",
    flatrateUrl: "products.productS.tmpData.paymentButton.flatrateUrl",
  },
  productDetails: {
    specification: {
      productName: "products.productS.productDetails.productName",
      productDescription: "products.productS.productDetails.productDescription",
      headlineKey: "products.productS.productDetails.specification.headlineKey",
      items: {
        item1: {
          nameKey:
            "products.productS.productDetails.specification.items.item1.nameKey",
          contentKey:
            "products.productS.productDetails.specification.items.item1.contentKey",
        },
        item2: {
          nameKey:
            "products.productS.productDetails.specification.items.item2.nameKey",
          contentKey:
            "products.productS.productDetails.specification.items.item2.contentKey",
        },
        item3: {
          nameKey:
            "products.productS.productDetails.specification.items.item3.nameKey",
          contentKey:
            "products.productS.productDetails.specification.items.item3.contentKey",
        },
        item4: {
          nameKey:
            "products.productS.productDetails.specification.items.item4.nameKey",
          contentKey:
            "products.productS.productDetails.specification.items.item4.contentKey",
        },
        item5: {
          nameKey:
            "products.productS.productDetails.specification.items.item5.nameKey",
          contentKey:
            "products.productS.productDetails.specification.items.item5.contentKey",
        },
      },
    },
    technical: {
      headlineKey: "products.productS.productDetails.technical.headlineKey",
      items: {
        item1: {
          nameKey:
            "products.productS.productDetails.technical.items.item1.nameKey",
          contentKey:
            "products.productS.productDetails.technical.items.item1.contentKey",
        },
        item2: {
          nameKey:
            "products.productS.productDetails.technical.items.item2.nameKey",
          contentKey:
            "products.productS.productDetails.technical.items.item2.contentKey",
        },
        item3: {
          nameKey:
            "products.productS.productDetails.technical.items.item3.nameKey",
          contentKey:
            "products.productS.productDetails.technical.items.item3.contentKey",
        },
        item4: {
          nameKey:
            "products.productS.productDetails.technical.items.item4.nameKey",
          contentKey:
            "products.productS.productDetails.technical.items.item4.contentKey",
        },
        item5: {
          nameKey:
            "products.productS.productDetails.technical.items.item5.nameKey",
          contentKey:
            "products.productS.productDetails.technical.items.item5.contentKey",
        },
      },
    },
    starterbox: {
      headlineKey: "products.productS.productDetails.starterbox.headlineKey",
      items: {
        item1: {
          nameKey:
            "products.productS.productDetails.starterbox.items.item1.nameKey",
          contentKey:
            "products.productS.productDetails.starterbox.items.item1.contentKey",
        },
        item2: {
          nameKey:
            "products.productS.productDetails.starterbox.items.item2.nameKey",
          contentKey:
            "products.productS.productDetails.starterbox.items.item2.contentKey",
        },
      },
    },
    buttonText: "products.productS.productDetails.buttonText",
  },
  featuredServices: {
    items: {
      delivery: {
        name: "products.productS.featuredServices.items.delivery.name",
        content: "products.productS.featuredServices.items.delivery.content",
        id: "products.productS.featuredServices.items.delivery.id",
      },
      testing: {
        name: "products.productS.featuredServices.items.testing.name",
        content: "products.productS.featuredServices.items.testing.content",
        id: "products.productS.featuredServices.items.testing.id",
      },
      warranty: {
        name: "products.productS.featuredServices.items.warranty.name",
        content: "products.productS.featuredServices.items.warranty.content",
        id: "products.productS.featuredServices.items.warranty.id",
      },
    },
  },
  experimentFeaturedServices: {
    items: {
      delivery: {
        name:
          "products.productS.experimentFeaturedServices.items.delivery.name",
        content:
          "products.productS.experimentFeaturedServices.items.delivery.content",
        id: "products.productS.experimentFeaturedServices.items.delivery.id",
      },
      testing: {
        name: "products.productS.experimentFeaturedServices.items.testing.name",
        content:
          "products.productS.experimentFeaturedServices.items.testing.content",
        id: "products.productS.experimentFeaturedServices.items.testing.id",
      },
      warranty: {
        name:
          "products.productS.experimentFeaturedServices.items.warranty.name",
        content:
          "products.productS.experimentFeaturedServices.items.warranty.content",
        id: "products.productS.experimentFeaturedServices.items.warranty.id",
      },
    },
  },
};

export const VAHA_X_PRODUCT = {
  id: "products.productX.id",
  type: "products.productX.type",
  basket3Image: "products.productX.basket3Image",
  basket3delivery: "products.productX.basket3delivery",
  promotions: {
    flashSale: {
      mainPromoHeadline:
        "products.productX.promotions.flashSale.mainPromoHeadline",
      heading: {
        image: "products.productX.promotions.flashSale.heading.image",
        title: "products.productX.promotions.flashSale.heading.title",
      },
      total: {
        headline: "products.productX.promotions.flashSale.total.headline",
        priceBefore: "products.productX.promotions.flashSale.total.priceBefore",
        priceAfter: "products.productX.promotions.flashSale.total.priceAfter",
      },
      footer: "products.productX.promotions.flashSale.footer",
      discount: {
        heading: "products.productX.promotions.flashSale.discount.heading",
        title: "products.productX.promotions.flashSale.discount.title",
        amount: "products.productX.promotions.flashSale.discount.amount",
        code: {
          description:
            "products.productX.promotions.flashSale.discount.code.description",
          promoCode:
            "products.productX.promotions.flashSale.discount.code.promoCode",
        },
      },
      items: {
        item1: {
          title: "products.productX.promotions.flashSale.items.item1.title",
          description:
            "products.productX.promotions.flashSale.items.item1.description",
          priceBefore:
            "products.productX.promotions.flashSale.items.item1.priceBefore",
          priceAfter:
            "products.productX.promotions.flashSale.items.item1.priceAfter",
        },
        item2: {
          title: "products.productX.promotions.flashSale.items.item2.title",
          description:
            "products.productX.promotions.flashSale.items.item2.description",
          priceBefore:
            "products.productX.promotions.flashSale.items.item2.priceBefore",
          priceAfter: "flashSale.items.products.productX.item2.priceAfter",
        },
        item3: {
          title: "products.productX.promotions.flashSale.items.item3.title",
          description:
            "products.productX.promotions.flashSale.items.item3.description",
          priceBefore:
            "products.productX.promotions.flashSale.items.item3.priceBefore",
          priceAfter:
            "products.productX.promotions.flashSale.items.item3.priceAfter",
        },
        item4: {
          title: "products.productX.promotions.flashSale.items.item4.title",
          description:
            "products.productX.promotions.flashSale.items.item4.description",
          priceBefore:
            "products.productX.promotions.flashSale.items.item4.priceBefore",
          priceAfter:
            "products.productX.promotions.flashSale.items.item4.priceAfter",
        },
        item5: {
          title: "products.productX.promotions.flashSale.items.item5.title",
          description:
            "products.productX.promotions.flashSale.items.item5.description",
          priceBefore:
            "products.productX.promotions.flashSale.items.item5.priceBefore",
          priceAfter:
            "products.productX.promotions.flashSale.items.item5.priceAfter",
        },
      },
    },
    flatrateFlashSale: {
      mainPromoHeadline:
        "products.productX.promotions.flatrateFlashSale.mainPromoHeadline",
      heading: {
        image: "products.productX.promotions.flatrateFlashSale.heading.image",
        title: "products.productX.promotions.flatrateFlashSale.heading.title",
      },
      total: {
        headline:
          "products.productX.promotions.flatrateFlashSale.total.headline",
        priceBefore:
          "products.productX.promotions.flatrateFlashSale.total.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateFlashSale.total.priceAfter",
      },
      footer: "products.productX.promotions.flatrateFlashSale.footer",
      discount: {
        title: "products.productX.promotions.flatrateFlashSale.discount.title",
        heading: "products.productX.promotions.flashSale.discount.heading",
        amount:
          "products.productX.promotions.flatrateFlashSale.discount.amount",
        code: {
          description:
            "products.productX.promotions.flatrateFlashSale.discount.code.description",
          promoCode:
            "products.productX.promotions.flatrateFlashSale.discount.code.promoCode",
        },
      },
      items: {
        item1: {
          title:
            "products.productX.promotions.flatrateFlashSale.items.item1.title",
          description:
            "products.productX.promotions.flatrateFlashSale.items.item1.description",
          priceBefore:
            "products.productX.promotions.flatrateFlashSale.items.item1.priceBefore",
          priceAfter:
            "products.productX.promotions.flatrateFlashSale.items.item1.priceAfter",
        },
        item2: {
          title:
            "products.productX.promotions.flatrateFlashSale.items.item2.title",
          description:
            "products.productX.promotions.flatrateFlashSale.items.item2.description",
          priceBefore:
            "products.productX.promotions.flatrateFlashSale.items.item2.priceBefore",
          priceAfter:
            "products.productX.promotions.flatrateFlashSale.items.item2.priceAfter",
        },
        item3: {
          title:
            "products.productX.promotions.flatrateFlashSale.items.item3.title",
          description:
            "products.productX.promotions.flatrateFlashSale.items.item3.description",
          priceBefore:
            "products.productX.promotions.flatrateFlashSale.items.item3.priceBefore",
          priceAfter:
            "products.productX.promotions.flatrateFlashSale.items.item3.priceAfter",
        },
        item4: {
          title:
            "products.productX.promotions.flatrateFlashSale.items.item4.title",
          description:
            "products.productX.promotions.flatrateFlashSale.items.item4.description",
          priceBefore:
            "products.productX.promotions.flatrateFlashSale.items.item4.priceBefore",
          priceAfter:
            "products.productX.promotions.flatrateFlashSale.items.item4.priceAfter",
        },
        item5: {
          title:
            "products.productX.promotions.flatrateFlashSale.items.item5.title",
          description:
            "products.productX.promotions.flatrateFlashSale.items.item5.description",
          priceBefore:
            "products.productX.promotions.flatrateFlashSale.items.item5.priceBefore",
          priceAfter:
            "products.productX.promotions.flatrateFlashSale.items.item5.priceAfter",
        },
        item6: {
          title:
            "products.productX.promotions.flatrateFlashSale.items.item6.title",
          description:
            "products.productX.promotions.flatrateFlashSale.items.item6.description",
          priceBefore:
            "products.productX.promotions.flatrateFlashSale.items.item6.priceBefore",
          priceAfter:
            "products.productX.promotions.flatrateFlashSale.items.item6.priceAfter",
        },
      },
    },
    januarySale: {
      mainPromoHeadline:
        "products.productX.promotions.januarySale.mainPromoHeadline",
      heading: {
        image: "products.productX.promotions.januarySale.heading.image",
        title: "products.productX.promotions.januarySale.heading.title",
      },
      pricesBeforeAfter: {
        priceBefore:
          "products.productX.promotions.januarySale.pricesBeforeAfter.priceBefore",
        priceAfter:
          "products.productX.promotions.januarySale.pricesBeforeAfter.priceAfter",
      },
      footer: "products.productX.promotions.januarySale.footer",
      total: {
        headline: "products.productX.promotions.januarySale.total.headline",
        priceBefore:
          "products.productX.promotions.januarySale.total.priceBefore",
        priceAfter: "products.productX.promotions.januarySale.total.priceAfter",
      },
      discount: {
        heading: "products.productX.promotions.januarySale.discount.heading",
        title: "products.productX.promotions.januarySale.discount.title",
        amount: "products.productX.promotions.januarySale.discount.amount",
        code: {
          description:
            "products.productX.promotions.januarySale.discount.code.description",
          promoCode:
            "products.productX.promotions.januarySale.discount.code.promoCode",
        },
      },
      items: {
        item1: {
          title: "products.productX.promotions.januarySale.items.item1.title",
          description:
            "products.productX.promotions.januarySale.items.item1.description",
          priceBefore:
            "products.productX.promotions.januarySale.items.item1.priceBefore",
          priceAfter:
            "products.productX.promotions.januarySale.items.item1.priceAfter",
        },
        item2: {
          title: "products.productX.promotions.januarySale.items.item2.title",
          description:
            "products.productX.promotions.januarySale.items.item2.description",
          priceBefore:
            "products.productX.promotions.januarySale.items.item2.priceBefore",
          priceAfter: "januarySale.items.productX.item2.priceAfter",
        },
        item3: {
          title: "products.productX.promotions.januarySale.items.item3.title",
          description:
            "products.productX.promotions.januarySale.items.item3.description",
          priceBefore:
            "products.productX.promotions.januarySale.items.item3.priceBefore",
          priceAfter:
            "products.productX.promotions.januarySale.items.item3.priceAfter",
        },
        item4: {
          title: "products.productX.promotions.januarySale.items.item4.title",
          description:
            "products.productX.promotions.januarySale.items.item4.description",
          priceBefore:
            "products.productX.promotions.januarySale.items.item4.priceBefore",
          priceAfter:
            "products.productX.promotions.januarySale.items.item4.priceAfter",
        },
        item5: {
          title: "products.productX.promotions.januarySale.items.item5.title",
          description:
            "products.productX.promotions.januarySale.items.item5.description",
          priceBefore:
            "products.productX.promotions.januarySale.items.item5.priceBefore",
          priceAfter:
            "products.productX.promotions.januarySale.items.item5.priceAfter",
        },
      },
    },
    flatrateJanuarySale: {
      mainPromoHeadline:
        "products.productX.promotions.flatrateJanuarySale.mainPromoHeadline",
      heading: {
        image: "products.productX.promotions.flatrateJanuarySale.heading.image",
        title: "products.productX.promotions.flatrateJanuarySale.heading.title",
      },
      footer: "products.productX.promotions.flatrateJanuarySale.footer",
      total: {
        headline:
          "products.productX.promotions.flatrateJanuarySale.total.headline",
        priceBefore:
          "products.productX.promotions.flatrateJanuarySale.total.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateJanuarySale.total.priceAfter",
      },
      discount: {
        heading: "products.productX.promotions.januarySale.discount.heading",
        title:
          "products.productX.promotions.flatrateJanuarySale.discount.title",
        amount:
          "products.productX.promotions.flatrateJanuarySale.discount.amount",
        code: {
          description:
            "products.productX.promotions.flatrateJanuarySale.discount.code.description",
          promoCode:
            "products.productX.promotions.flatrateJanuarySale.discount.code.promoCode",
        },
      },
      items: {
        item1: {
          title:
            "products.productX.promotions.flatrateJanuarySale.items.item1.title",
          description:
            "products.productX.promotions.flatrateJanuarySale.items.item1.description",
          priceBefore:
            "products.productX.promotions.flatrateJanuarySale.items.item1.priceBefore",
          priceAfter:
            "products.productX.promotions.flatrateJanuarySale.items.item1.priceAfter",
        },
        item2: {
          title:
            "products.productX.promotions.flatrateJanuarySale.items.item2.title",
          description:
            "products.productX.promotions.flatrateJanuarySale.items.item2.description",
          priceBefore:
            "products.productX.promotions.flatrateJanuarySale.items.item2.priceBefore",
          priceAfter:
            "products.productX.promotions.flatrateJanuarySale.items.item2.priceAfter",
        },
        item3: {
          title:
            "products.productX.promotions.flatrateJanuarySale.items.item3.title",
          description:
            "products.productX.promotions.flatrateJanuarySale.items.item3.description",
          priceBefore:
            "products.productX.promotions.flatrateJanuarySale.items.item3.priceBefore",
          priceAfter:
            "products.productX.promotions.flatrateJanuarySale.items.item3.priceAfter",
        },
        item4: {
          title:
            "products.productX.promotions.flatrateJanuarySale.items.item4.title",
          description:
            "products.productX.promotions.flatrateJanuarySale.items.item4.description",
          priceBefore:
            "products.productX.promotions.flatrateJanuarySale.items.item4.priceBefore",
          priceAfter:
            "products.productX.promotions.flatrateJanuarySale.items.item4.priceAfter",
        },
        item5: {
          title:
            "products.productX.promotions.flatrateJanuarySale.items.item5.title",
          description:
            "products.productX.promotions.flatrateJanuarySale.items.item5.description",
          priceBefore:
            "products.productX.promotions.flatrateJanuarySale.items.item5.priceBefore",
          priceAfter:
            "products.productX.promotions.flatrateJanuarySale.items.item5.priceAfter",
        },
        item6: {
          title:
            "products.productX.promotions.flatrateJanuarySale.items.item6.title",
          description:
            "products.productX.promotions.flatrateJanuarySale.items.item6.description",
          priceBefore:
            "products.productX.promotions.flatrateJanuarySale.items.item6.priceBefore",
          priceAfter:
            "products.productX.promotions.flatrateJanuarySale.items.item6.priceAfter",
        },
      },
    },
    wellnessSale: wellnessSaleProductX,
    flatrateWellnessSale: flatrateWellnessSaleProductX,
    valentinesDay: valentinesDayProductX,
    flatrateValentinesDayProductX: flatrateValentinesDayProductX,
    achieveTheUnexpected: achieveTheUnexpectedProductX,
    flatrateAchieveTheUnexpected: flatrateAchieveTheUnexpectedProductX,
    springIntoShape: springIntoShapeProductX,
    flatrateSpringIntoShape: flatrateSpringIntoShapeProductX,
    goBeyond: goBeyondProductX,
    flatrateGoBeyond: flatrateGoBeyondProductX,
    easterSale: easterSaleProductX,
    flatrateEasterSale: flatrateEasterSaleProductX,
    mayDaySale: mayDaySaleProductX,
    flatrateMayDaySale: flatrateMayDaySaleProductX,
    vahaMoments: vahaMomentsProductX,
    flatrateVahaMoments: flatrateVahaMomentsProductX,
    summerWithFlow: summerWithFlowProductX,
    flatrateSummerWithFlow: flatrateSummerWithFlowProductX,
    vahaPower: vahaPowerProductX,
    flatrateVahaPowerWithFlow: flatrateVahaPowerProductX,
    noDiscount: noDiscountProductX,
    flatrateNoDiscountWithFlow: flatrateNoDiscountProductX,
    blackVaha: blackVahaProductX,
    flatrateBlackVaha: flatrateBlackVahaProductX,
    vahaxmas: vahaxmasProductX,
    flatrateVahaxmas: flatrateVahaxmasProductX,
    vahanewyear: vahanewyearProductX,
    flatrateVahanewyear: flatrateVahanewyearProductX
  },
  promotionPriceRow: {
    priceBefore: "products.productX.promotionPriceRow.priceBefore",
    priceAfter: "products.productX.promotionPriceRow.priceAfter",
  },
  basket3data: {
    row1: {
      id: "products.productX.basket3data.row1.id",
      titleRow1: "products.productX.basket3data.row1.titleRow1",
      titleRow2: "products.productX.basket3data.row1.titleRow2",
      priceFrom: "products.productX.basket3data.row1.priceFrom",
      priceBefore: "products.productX.basket3data.row1.priceBefore",
      priceAfter: "products.productX.basket3data.row1.priceAfter",
      priceRow2: "products.productX.basket3data.row1.priceRow2",
      content: "products.productX.basket3data.row1.content",
      list: {
        item1: "products.productX.basket3data.row1.list.item1",
        item2: "products.productX.basket3data.row1.list.item2",
        item3: "products.productX.basket3data.row1.list.item3",
        item4: "products.productX.basket3data.row1.list.item4",
      },
      image: "products.productX.basket3data.row1.image",
    },
    row2: {
      id: "products.productX.basket3data.row2.id",
      titleRow1: "products.productX.basket3data.row2.titleRow1",
      titleRow2: "products.productX.basket3data.row2.titleRow2",
      priceFrom: "products.productX.basket3data.row2.priceFrom",
      priceBefore: "products.productX.basket3data.row2.priceBefore",
      priceAfter: "products.productX.basket3data.row2.priceAfter",
      priceRow2: "products.productX.basket3data.row2.priceRow2",
      content: "products.productX.basket3data.row2.content",
      list: {
        item1: "products.productX.basket3data.row2.list.item1",
        item2: "products.productX.basket3data.row2.list.item2",
        item3: "products.productX.basket3data.row2.list.item3",
        item4: "products.productX.basket3data.row2.list.item4",
      },
      image: "products.productX.basket3data.row2.image",
    },
    row3: {
      id: "products.productX.basket3data.row3.id",
      titleRow1: "products.productX.basket3data.row3.titleRow1",
      titleRow2: "products.productX.basket3data.row3.titleRow2",
      priceFrom: "products.productX.basket3data.row3.priceFrom",
      priceBefore: "products.productX.basket3data.row3.priceBefore",
      priceAfter: "products.productX.basket3data.row3.priceAfter",
      priceRow2: "products.productX.basket3data.row3.priceRow2",
      content: "products.productX.basket3data.row3.content",
      list: {
        item1: "products.productX.basket3data.row3.list.item1",
        item2: "products.productX.basket3data.row3.list.item2",
        item3: "products.productX.basket3data.row3.list.item3",
        item4: "products.productX.basket3data.row3.list.item4",
      },
      image: "products.productX.basket3data.row3.image",
    },
    row4: {
      id: "products.productX.basket3data.row4.id",
      titleRow1: "products.productX.basket3data.row4.titleRow1",
      titleRow2: "products.productX.basket3data.row4.titleRow2",
      priceFrom: "products.productX.basket3data.row4.priceFrom",
      priceBefore: "products.productX.basket3data.row4.priceBefore",
      priceAfter: "products.productX.basket3data.row4.priceAfter",
      priceRow2: "products.productX.basket3data.row4.priceRow2",
      content: "products.productX.basket3data.row4.content",
      list: {
        item1: "products.productX.basket3data.row4.list.item1",
        item2: "products.productX.basket3data.row4.list.item2",
        item3: "products.productX.basket3data.row4.list.item3",
        item4: "products.productX.basket3data.row4.list.item4",
      },
      image: "products.productX.basket3data.row4.image",
    },
  },
  basket3Headline: "Einkaufswagen",
  basket3Heading: {
    icon: "products.productX.basket3Heading.icon",
    text: "products.productX.basket3Heading.text",
  },
  basket3LegalTooltip: "products.productX.basket3LegalTooltip",
  basket3footer: {
    prices: {
      priceLeft: {
        amount: "products.productX.basket3footer.prices.priceLeft.amount",
        text: "products.productX.basket3footer.prices.priceLeft.text",
      },
      priceRight: {
        amount: "products.productX.basket3footer.prices.priceRight.amount",
        text: "products.productX.basket3footer.prices.priceRight.text",
      },
    },
    bottomText: "products.productX.basket3footer.bottomText",
  },
  gtm: {
    price: "products.productX.gtm.price",
    currency: "products.productX.gtm.currency",
  },
  flatrate: {
    prices: {
      "36m": "products.productX.flatrate.prices.36m",
      "24m": "products.productX.flatrate.prices.24m",
      "12m": "products.productX.flatrate.prices.12m",
      christmasPromo: "products.productX.flatrate.prices.christmasPromo",
      christmasPromoBefore:
        "products.productX.flatrate.prices.christmasPromoBefore",
      wellnessSale: "products.productX.flatrate.prices.wellnessSale",
    },
  },
  sound: "products.productX.sound",
  touchscreen: "products.productX.touchscreen",
  dimensions: {
    size: "products.productX.dimensions.size",
    weight: "products.productX.dimensions.weight",
  },
  shipping: "products.productX.shipping",
  shippingExperimentwvSDVbi8R6KyxCB9T0CkrA:
    "products.productX.shippingExperimentwvSDVbi8R6KyxCB9T0CkrA",
  image:
    "https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/images/basket/VAHA_X_TRANSPARENT.png",
  imageBigger:
    "https://uk-vaha.s3.eu-central-1.amazonaws.com/static/assets/images/basket/VAHA_170_BIGGER.png",
  imageBasket: "https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/images/basket/vaha_x_basket.png",
  payUpfront: {
    prices: {
      promotional: "1.996 €",
      original: "2.495 €",
      monthly: "249 € mtl.",
      monthlyBare: "249 €"
    }
  },
  name: "products.productX.name",
  bulletPoints: {
    twoYearsWarranty: "products.productX.bulletPoints.twoYearsWarranty",
    inclWallBracket: "products.productX.bulletPoints.inclWallBracket",
    // inclFloorStand: 'products.productX.bulletPoints.inclFloorStand',
    zeroFinancing: "products.productX.bulletPoints.zeroFinancing",
  },
  freePremiumService: {
    label: "products.productX.freePremiumService.label",
    priceBefore: "products.productX.freePremiumService.priceBefore",
    priceAfter: "products.productX.freePremiumService.priceAfter",
  },
  prices: {
    "12m": {
      name: "products.productX.prices.12m.name",
      period: "products.productX.prices.12m.period",
      price: "products.productX.prices.12m.price",
    },
    "24m": {
      name: "products.productX.prices.24m.name",
      period: "products.productX.prices.24m.period",
      price: "products.productX.prices.24m.price",
    },
    "39m": {
      name: "products.productX.prices.39m.name",
      period: "products.productX.prices.39m.period",
      price: "products.productX.prices.39m.price",
    },
    direct: {
      name: "products.productX.prices.direct.name",
      price: "products.productX.prices.direct.price",
      discountPrice: "products.productX.prices.direct.discountPrice",
    },
    recommended: {
      name: "products.productX.prices.recommended.name",
      period: "products.productX.prices.recommended.period",
      price: "products.productX.prices.recommended.price",
    },
    basketRecommended: {
      name: "products.productX.prices.basketRecommended.name",
      period: "products.productX.prices.basketRecommended.period",
      price: "products.productX.prices.basketRecommended.price",
    },
    experimentwvSDVbi8R6KyxCB9T0CkrA: {
      "12m": {
        name:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.12m.name",
        period:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.12m.period",
        price:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.12m.price",
      },
      "24m": {
        name:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.24m.name",
        period:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.24m.period",
        price:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.24m.price",
      },
      "39m": {
        name:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.39m.name",
        period:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.39m.period",
        price:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.39m.price",
      },
      direct: {
        name:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.direct.name",
        price:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.direct.price",
      },
      recommended: {
        name:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA/recommended.name",
        period:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.recommended.period",
        price:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.recommended.price",
      },
      basketRecommended: {
        name:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.basketRecommended.name",
        period:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.basketRecommended.period",
        price:
          "products.productX.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.basketRecommended.price",
      },
    },
  },
  specialLaunch: {
    show: "products.productX.specialLaunch.show",
    headline: "products.productX.specialLaunch.headline",
    terms: "products.productX.specialLaunch.terms",
    bulletPoints: {
      jabra: {
        content: "products.productX.specialLaunch.bulletPoints.jabra.content",
        priceBefore:
          "products.productX.specialLaunch.bulletPoints.jabra.priceBefore",
        priceAfter:
          "products.productX.specialLaunch.bulletPoints.jabra.priceAfter",
      },
      delivery: {
        content:
          "products.productX.specialLaunch.bulletPoints.delivery.content",
        priceBefore:
          "products.productX.specialLaunch.bulletPoints.delivery.priceBefore",
        priceAfter:
          "products.productX.specialLaunch.bulletPoints.delivery.priceAfter",
      },
      testing: {
        content: "products.productX.specialLaunch.bulletPoints.testing.content",
        priceBefore:
          "products.productX.specialLaunch.bulletPoints.testing.priceBefore",
        priceAfter:
          "products.productX.specialLaunch.bulletPoints.testing.priceAfter",
      },
      warranty: {
        content:
          "products.productX.specialLaunch.bulletPoints.warranty.content",
        priceBefore:
          "products.productX.specialLaunch.bulletPoints.warranty.priceBefore",
        priceAfter:
          "products.productX.specialLaunch.bulletPoints.warranty.priceAfter",
      },
    },
  },
  membership: {
    headline: "products.productX.membership.headline",
    bulletPoints: {
      oneMonthFree: "products.productX.membership.bulletPoints.oneMonthFree",
      twelveMonthsTerm:
        "products.productX.membership.bulletPoints.twelveMonthsTerm",
      billingAfterMirrorActivation:
        "products.productX.membership.bulletPoints.billingAfterMirrorActivation",
    },
    price: "products.productX.membership.price",
    priceHint: "products.productX.membership.priceHint",
    priceLabel: "products.productX.membership.priceLabel",
    pricePeriod: "products.productX.membership.pricePeriod",
    priceExperimentwvSDVbi8R6KyxCB9T0CkrA:
      "products.productX.membership.priceExperimentwvSDVbi8R6KyxCB9T0CkrA",
  },
  starterBox: {
    headline: "products.productX.starterBox.headline",
    bulletPoints: {
      heartRate: "products.productX.starterBox.bulletPoints.heartRate",
      elasticBands: "products.productX.starterBox.bulletPoints.elasticBands",
      microfiberCloth:
        "products.productX.starterBox.bulletPoints.microfiberCloth",
      cameraCover: "products.productX.starterBox.bulletPoints.cameraCover",
      userManual: "products.productX.starterBox.bulletPoints.userManual",
    },
    price: "products.productX.starterBox.price",
    priceHint: "products.productX.starterBox.priceHint",
    priceLabel: "products.productX.starterBox.priceLabel",
  },
  financingOptions: {
    items: {
      "12m": {
        id: "products.productX.financingOptions.items.12m.id",
        nameKey: "products.productX.financingOptions.items.12m.nameKey",
        priceKey: "products.productX.financingOptions.items.12m.priceKey",
        periodKey: "products.productX.financingOptions.items.12m.periodKey",
      },
      "24m": {
        id: "products.productX.financingOptions.items.24m.id",
        nameKey: "products.productX.financingOptions.items.24m.nameKey",
        priceKey: "products.productX.financingOptions.items.24m.priceKey",
        periodKey: "products.productX.financingOptions.items.24m.periodKey",
      },
      "36m": {
        id: "products.productX.financingOptions.items.36m.id",
        nameKey: "products.productX.financingOptions.items.36m.nameKey",
        priceKey: "products.productX.financingOptions.items.36m.priceKey",
        periodKey: "products.productX.financingOptions.items.36m.periodKey",
      },
      "39m": {
        id: "products.productX.financingOptions.items.39m.id",
        nameKey: "products.productX.financingOptions.items.39m.nameKey",
        priceKey: "products.productX.financingOptions.items.39m.priceKey",
        periodKey: "products.productX.financingOptions.items.39m.periodKey",
      },
      "48m": {
        id: "products.productX.financingOptions.items.48m.id",
        nameKey: "products.productX.financingOptions.items.48m.nameKey",
        priceKey: "products.productX.financingOptions.items.48m.priceKey",
        periodKey: "products.productX.financingOptions.items.48m.periodKey",
      },
      direct: {
        id: "products.productX.financingOptions.items.direct.id",
        nameKey: "products.productX.financingOptions.items.direct.nameKey",
        priceKey: "products.productX.financingOptions.items.direct.priceKey",
        periodKey: "products.productX.financingOptions.items.direct.periodKey",
      },
    },
    advantageKeys: {
      interest: "products.productX.financingOptions.advantageKeys.interest",
      totalPrice: "products.productX.financingOptions.advantageKeys.totalPrice",
      vat: "products.productX.financingOptions.advantageKeys.vat",
    },
  },
  financingOptionsSection: {
    mirror: {
      type: "products.productX.financingOptionsSection.mirror.type",
      headline: "products.productX.financingOptionsSection.mirror.headline",
      priceHint: "products.productX.financingOptionsSection.mirror.priceHint",
      price: "products.productX.financingOptionsSection.mirror.price",
      period: "products.productX.financingOptionsSection.mirror.period",
      linkText: "products.productX.financingOptionsSection.mirror.linkText",
    },
    membership: {
      type: "products.productX.financingOptionsSection.membership.type",
      headline: "products.productX.financingOptionsSection.membership.headline",
      price: "products.productX.financingOptionsSection.membership.price",
      period: "products.productX.financingOptionsSection.membership.period",
      linkText: "products.productX.financingOptionsSection.membership.linkText",
    },
    total: {
      totalPrice: "products.productX.financingOptionsSection.total.totalPrice",
      totalPeriod:
        "products.productX.financingOptionsSection.total.totalPeriod",
      disclaimerFirst:
        "products.productX.financingOptionsSection.total.disclaimerFirst",
      disclaimerSecond:
        "products.productX.financingOptionsSection.total.disclaimerSecond",
    },
  },
  paymentButton: {
    url: "products.productX.tmpData.paymentButton.url",
    label: "products.productX.tmpData.paymentButton.label",
    flatrateUrl: "products.productX.tmpData.paymentButton.flatrateUrl",
  },
  productDetails: {
    specification: {
      productName: "products.productX.productDetails.productName",
      productDescription: "products.productX.productDetails.productDescription",
      headlineKey: "products.productX.productDetails.specification.headlineKey",
      items: {
        item1: {
          nameKey:
            "products.productX.productDetails.specification.items.item1.nameKey",
          contentKey:
            "products.productX.productDetails.specification.items.item1.contentKey",
        },
        item2: {
          nameKey:
            "products.productX.productDetails.specification.items.item2.nameKey",
          contentKey:
            "products.productX.productDetails.specification.items.item2.contentKey",
        },
        item3: {
          nameKey:
            "products.productX.productDetails.specification.items.item3.nameKey",
          contentKey:
            "products.productX.productDetails.specification.items.item3.contentKey",
        },
        item4: {
          nameKey:
            "products.productX.productDetails.specification.items.item4.nameKey",
          contentKey:
            "products.productX.productDetails.specification.items.item4.contentKey",
        },
        item5: {
          nameKey:
            "products.productX.productDetails.specification.items.item5.nameKey",
          contentKey:
            "products.productX.productDetails.specification.items.item5.contentKey",
        },
      },
    },
    technical: {
      headlineKey: "products.productX.productDetails.technical.headlineKey",
      items: {
        item1: {
          nameKey:
            "products.productX.productDetails.technical.items.item1.nameKey",
          contentKey:
            "products.productX.productDetails.technical.items.item1.contentKey",
        },
        item2: {
          nameKey:
            "products.productX.productDetails.technical.items.item2.nameKey",
          contentKey:
            "products.productX.productDetails.technical.items.item2.contentKey",
        },
        item3: {
          nameKey:
            "products.productX.productDetails.technical.items.item3.nameKey",
          contentKey:
            "products.productX.productDetails.technical.items.item3.contentKey",
        },
        item4: {
          nameKey:
            "products.productX.productDetails.technical.items.item4.nameKey",
          contentKey:
            "products.productX.productDetails.technical.items.item4.contentKey",
        },
        item5: {
          nameKey:
            "products.productX.productDetails.technical.items.item5.nameKey",
          contentKey:
            "products.productX.productDetails.technical.items.item5.contentKey",
        },
      },
    },
    starterbox: {
      headlineKey: "products.productX.productDetails.starterbox.headlineKey",
      items: {
        item1: {
          nameKey:
            "products.productX.productDetails.starterbox.items.item1.nameKey",
          contentKey:
            "products.productX.productDetails.starterbox.items.item1.contentKey",
        },
        item2: {
          nameKey:
            "products.productX.productDetails.starterbox.items.item2.nameKey",
          contentKey:
            "products.productX.productDetails.starterbox.items.item2.contentKey",
        },
      },
    },
    buttonText: "products.productX.productDetails.buttonText",
  },
  featuredServices: {
    items: {
      delivery: {
        name: "products.productX.featuredServices.items.delivery.name",
        content: "products.productX.featuredServices.items.delivery.content",
        id: "products.productX.featuredServices.items.delivery.id",
      },
      testing: {
        name: "products.productX.featuredServices.items.testing.name",
        content: "products.productX.featuredServices.items.testing.content",
        id: "products.productX.featuredServices.items.testing.id",
      },
      warranty: {
        name: "products.productX.featuredServices.items.warranty.name",
        content: "products.productX.featuredServices.items.warranty.content",
        id: "products.productX.featuredServices.items.warranty.id",
      },
    },
  },
  experimentFeaturedServices: {
    items: {
      delivery: {
        name:
          "products.productX.experimentFeaturedServices.items.delivery.name",
        content:
          "products.productX.experimentFeaturedServices.items.delivery.content",
        id: "products.productX.experimentFeaturedServices.items.delivery.id",
      },
      testing: {
        name: "products.productX.experimentFeaturedServices.items.testing.name",
        content:
          "products.productX.experimentFeaturedServices.items.testing.content",
        id: "products.productX.experimentFeaturedServices.items.testing.id",
      },
      warranty: {
        name:
          "products.productX.experimentFeaturedServices.items.warranty.name",
        content:
          "products.productX.experimentFeaturedServices.items.warranty.content",
        id: "products.productX.experimentFeaturedServices.items.warranty.id",
      },
    },
  },
};

export const VAHA_S_PRODUCT = {
  id: "products.productS.id",
  type: "products.productS.type",
  promotions: {
    flashSale: {
      mainPromoHeadline:
        "products.productS.promotions.flashSale.mainPromoHeadline",
      heading: {
        image: "products.productS.promotions.flashSale.heading.image",
        title: "products.productS.promotions.flashSale.heading.title",
      },
      footer: "products.productS.promotions.flashSale.footer",
      total: {
        headline: "products.productS.promotions.flashSale.total.headline",
        priceBefore: "products.productS.promotions.flashSale.total.priceBefore",
        priceAfter: "products.productS.promotions.flashSale.total.priceAfter",
      },
      discount: {
        heading: "products.productS.promotions.flashSale.discount.heading",
        title: "products.productS.promotions.flashSale.discount.title",
        amount: "products.productS.promotions.flashSale.discount.amount",
        code: {
          description:
            "products.productS.promotions.flashSale.discount.code.description",
          promoCode:
            "products.productS.promotions.flashSale.discount.code.promoCode",
        },
      },
      items: {
        item1: {
          title: "products.productS.promotions.flashSale.items.item1.title",
          description:
            "products.productS.promotions.flashSale.items.item1.description",
          priceBefore:
            "products.productS.promotions.flashSale.items.item1.priceBefore",
          priceAfter:
            "products.productS.promotions.flashSale.items.item1.priceAfter",
        },
        item2: {
          title: "products.productS.promotions.flashSale.items.item2.title",
          description:
            "products.productS.promotions.flashSale.items.item2.description",
          priceBefore:
            "products.productS.promotions.flashSale.items.item2.priceBefore",
          priceAfter: "flashSale.items.products.productS.item2.priceAfter",
        },
        item3: {
          title: "products.productS.promotions.flashSale.items.item3.title",
          description:
            "products.productS.promotions.flashSale.items.item3.description",
          priceBefore:
            "products.productS.promotions.flashSale.items.item3.priceBefore",
          priceAfter:
            "products.productS.promotions.flashSale.items.item3.priceAfter",
        },
        item4: {
          title: "products.productS.promotions.flashSale.items.item4.title",
          description:
            "products.productS.promotions.flashSale.items.item4.description",
          priceBefore:
            "products.productS.promotions.flashSale.items.item4.priceBefore",
          priceAfter:
            "products.productS.promotions.flashSale.items.item4.priceAfter",
        },
        item5: {
          title: "products.productS.promotions.flashSale.items.item5.title",
          description:
            "products.productS.promotions.flashSale.items.item5.description",
          priceBefore:
            "products.productS.promotions.flashSale.items.item5.priceBefore",
          priceAfter:
            "products.productS.promotions.flashSale.items.item5.priceAfter",
        },
      },
    },
    flatrateFlashSale: {
      heading: {
        image: "products.productS.promotions.flatrateFlashSale.heading.image",
        title: "products.productS.promotions.flatrateFlashSale.heading.title",
      },
      mainPromoHeadline:
        "products.productS.promotions.flatrateFlashSale.mainPromoHeadline",
      total: {
        headline:
          "products.productS.promotions.flatrateFlashSale.total.headline",
        priceBefore:
          "products.productS.promotions.flatrateFlashSale.total.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateFlashSale.total.priceAfter",
      },
      footer: "products.productS.promotions.flatrateFlashSale.footer",
      discount: {
        title: "products.productS.promotions.flatrateFlashSale.discount.title",
        heading:
          "products.productS.promotions.flatrateFlashSale.discount.heading",
        amount:
          "products.productS.promotions.flatrateFlashSale.discount.amount",
        code: {
          description:
            "products.productS.promotions.flatrateFlashSale.discount.code.description",
          promoCode:
            "products.productS.promotions.flatrateFlashSale.discount.code.promoCode",
        },
      },
      items: {
        item1: {
          title:
            "products.productS.promotions.flatrateFlashSale.items.item1.title",
          description:
            "products.productS.promotions.flatrateFlashSale.items.item1.description",
          priceBefore:
            "products.productS.promotions.flatrateFlashSale.items.item1.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateFlashSale.items.item1.priceAfter",
        },
        item2: {
          title:
            "products.productS.promotions.flatrateFlashSale.items.item2.title",
          description:
            "products.productS.promotions.flatrateFlashSale.items.item2.description",
          priceBefore:
            "products.productS.promotions.flatrateFlashSale.items.item2.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateFlashSale.items.item2.priceAfter",
        },
        item3: {
          title:
            "products.productS.promotions.flatrateFlashSale.items.item3.title",
          description:
            "products.productS.promotions.flatrateFlashSale.items.item3.description",
          priceBefore:
            "products.productS.promotions.flatrateFlashSale.items.item3.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateFlashSale.items.item3.priceAfter",
        },
        item4: {
          title:
            "products.productS.promotions.flatrateFlashSale.items.item4.title",
          description:
            "products.productS.promotions.flatrateFlashSale.items.item4.description",
          priceBefore:
            "products.productS.promotions.flatrateFlashSale.items.item4.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateFlashSale.items.item4.priceAfter",
        },
        item5: {
          title:
            "products.productS.promotions.flatrateFlashSale.items.item5.title",
          description:
            "products.productS.promotions.flatrateFlashSale.items.item5.description",
          priceBefore:
            "products.productS.promotions.flatrateFlashSale.items.item5.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateFlashSale.items.item5.priceAfter",
        },
        item6: {
          title:
            "products.productS.promotions.flatrateFlashSale.items.item6.title",
          description:
            "products.productS.promotions.flatrateFlashSale.items.item6.description",
          priceBefore:
            "products.productS.promotions.flatrateFlashSale.items.item6.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateFlashSale.items.item6.priceAfter",
        },
      },
    },
    januarySale: {
      mainPromoHeadline:
        "products.productS.promotions.januarySale.mainPromoHeadline",
      heading: {
        image: "products.productS.promotions.januarySale.heading.image",
        title: "products.productS.promotions.januarySale.heading.title",
      },
      pricesBeforeAfter: {
        priceBefore:
          "products.productS.promotions.januarySale.pricesBeforeAfter.priceBefore",
        priceAfter:
          "products.productS.promotions.januarySale.pricesBeforeAfter.priceAfter",
      },
      footer: "products.productS.promotions.januarySale.footer",
      total: {
        headline: "products.productS.promotions.januarySale.total.headline",
        priceBefore:
          "products.productS.promotions.januarySale.total.priceBefore",
        priceAfter: "products.productS.promotions.januarySale.total.priceAfter",
      },
      discount: {
        heading: "products.productS.promotions.januarySale.discount.heading",
        title: "products.productS.promotions.januarySale.discount.title",
        amount: "products.productS.promotions.januarySale.discount.amount",
        code: {
          description:
            "products.productS.promotions.januarySale.discount.code.description",
          promoCode:
            "products.productS.promotions.januarySale.discount.code.promoCode",
        },
      },
      items: {
        item1: {
          title: "products.productS.promotions.januarySale.items.item1.title",
          description:
            "products.productS.promotions.januarySale.items.item1.description",
          priceBefore:
            "products.productS.promotions.januarySale.items.item1.priceBefore",
          priceAfter:
            "products.productS.promotions.januarySale.items.item1.priceAfter",
        },
        item2: {
          title: "products.productS.promotions.januarySale.items.item2.title",
          description:
            "products.productS.promotions.januarySale.items.item2.description",
          priceBefore:
            "products.productS.promotions.januarySale.items.item2.priceBefore",
          priceAfter: "januarySale.items.products.productS.item2.priceAfter",
        },
        item3: {
          title: "products.productS.promotions.januarySale.items.item3.title",
          description:
            "products.productS.promotions.januarySale.items.item3.description",
          priceBefore:
            "products.productS.promotions.januarySale.items.item3.priceBefore",
          priceAfter:
            "products.productS.promotions.januarySale.items.item3.priceAfter",
        },
        item4: {
          title: "products.productS.promotions.januarySale.items.item4.title",
          description:
            "products.productS.promotions.januarySale.items.item4.description",
          priceBefore:
            "products.productS.promotions.januarySale.items.item4.priceBefore",
          priceAfter:
            "products.productS.promotions.januarySale.items.item4.priceAfter",
        },
        item5: {
          title: "products.productS.promotions.januarySale.items.item5.title",
          description:
            "products.productS.promotions.januarySale.items.item5.description",
          priceBefore:
            "products.productS.promotions.januarySale.items.item5.priceBefore",
          priceAfter:
            "products.productS.promotions.januarySale.items.item5.priceAfter",
        },
      },
    },
    flatrateJanuarySale: {
      mainPromoHeadline:
        "products.productS.promotions.flatrateJanuarySale.mainPromoHeadline",
      heading: {
        image: "products.productS.promotions.flatrateJanuarySale.heading.image",
        title: "products.productS.promotions.flatrateJanuarySale.heading.title",
      },
      footer: "products.productS.promotions.flatrateJanuarySale.footer",
      total: {
        headline:
          "products.productS.promotions.flatrateJanuarySale.total.headline",
        priceBefore:
          "products.productS.promotions.flatrateJanuarySale.total.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateJanuarySale.total.priceAfter",
      },
      discount: {
        heading: "products.productS.promotions.januarySale.discount.heading",
        title:
          "products.productS.promotions.flatrateJanuarySale.discount.title",
        amount:
          "products.productS.promotions.flatrateJanuarySale.discount.amount",
        code: {
          description:
            "products.productS.promotions.flatrateJanuarySale.discount.code.description",
          promoCode:
            "products.productS.promotions.flatrateJanuarySale.discount.code.promoCode",
        },
      },
      items: {
        item1: {
          title:
            "products.productS.promotions.flatrateJanuarySale.items.item1.title",
          description:
            "products.productS.promotions.flatrateJanuarySale.items.item1.description",
          priceBefore:
            "products.productS.promotions.flatrateJanuarySale.items.item1.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateJanuarySale.items.item1.priceAfter",
        },
        item2: {
          title:
            "products.productS.promotions.flatrateJanuarySale.items.item2.title",
          description:
            "products.productS.promotions.flatrateJanuarySale.items.item2.description",
          priceBefore:
            "products.productS.promotions.flatrateJanuarySale.items.item2.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateJanuarySale.items.item2.priceAfter",
        },
        item3: {
          title:
            "products.productS.promotions.flatrateJanuarySale.items.item3.title",
          description:
            "products.productS.promotions.flatrateJanuarySale.items.item3.description",
          priceBefore:
            "products.productS.promotions.flatrateJanuarySale.items.item3.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateJanuarySale.items.item3.priceAfter",
        },
        item4: {
          title:
            "products.productS.promotions.flatrateJanuarySale.items.item4.title",
          description:
            "products.productS.promotions.flatrateJanuarySale.items.item4.description",
          priceBefore:
            "products.productS.promotions.flatrateJanuarySale.items.item4.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateJanuarySale.items.item4.priceAfter",
        },
        item5: {
          title:
            "products.productS.promotions.flatrateJanuarySale.items.item5.title",
          description:
            "products.productS.promotions.flatrateJanuarySale.items.item5.description",
          priceBefore:
            "products.productS.promotions.flatrateJanuarySale.items.item5.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateJanuarySale.items.item5.priceAfter",
        },
        item6: {
          title:
            "products.productS.promotions.flatrateJanuarySale.items.item6.title",
          description:
            "products.productS.promotions.flatrateJanuarySale.items.item6.description",
          priceBefore:
            "products.productS.promotions.flatrateJanuarySale.items.item6.priceBefore",
          priceAfter:
            "products.productS.promotions.flatrateJanuarySale.items.item6.priceAfter",
        },
      },
    },
    wellnessSale: wellnessSaleProductS,
    flatrateWellnessSale: flatrateWellnessSaleProductS,
    valentinesDay: valentinesDayProductS,
    flatrateValentinesDay: flatrateValentinesDayProductS,
    achieveTheUnexpected: achieveTheUnexpectedProductS,
    flatrateAchieveTheUnexpected: flatrateAchieveTheUnexpectedProductS,
    springIntoShape: springIntoShapeProductS,
    flatrateSpringIntoShape: flatrateSpringIntoShapeProductS,
    goBeyond: goBeyondProductS,
    flatrateGoBeyond: flatrateGoBeyondProductS,
    easterSale: easterSaleProductS,
    flatrateEasterSale: flatrateEasterSaleProductS,
    mayDaySale: mayDaySaleProductS,
    flatrateMayDaySale: flatrateMayDaySaleProductS,
    vahaMoments: vahaMomentsProductS,
    flatrateVahaMoments: flatrateVahaMomentsProductS,
    summerWithFlow: summerWithFlowProductS,
    flatrateSummerWithFlow: flatrateSummerWithFlowProductS,
    vahaPower: vahaPowerProductS,
    flatrateVahaPowerWithFlow: flatrateVahaPowerProductS,
    noDiscount: noDiscountProductS,
    flatrateNoDiscountWithFlow: flatrateNoDiscountProductS,
    blackVaha: blackVahaProductS,
    flatrateBlackVaha: flatrateBlackVahaProductS,
    vahaxmas: vahaxmasProductS,
    flatrateVahaxmas: flatrateVahaxmasProductS,
    vahanewyear: vahanewyearProductS,
    flatrateVahanewyear: flatrateVahanewyearProductS
  },
  promotionPriceRow: {
    priceBefore: "products.productS.promotionPriceRow.priceBefore",
    priceAfter: "products.productS.promotionPriceRow.priceAfter",
  },
  basket3Image: "products.productS.basket3Image",
  basket3delivery: "products.productS.basket3delivery",
  basket3data: {
    row1: {
      id: "products.productS.basket3data.row1.id",
      titleRow1: "products.productS.basket3data.row1.titleRow1",
      titleRow2: "products.productS.basket3data.row1.titleRow2",
      priceFrom: "products.productS.basket3data.row1.priceFrom",
      priceBefore: "products.productS.basket3data.row1.priceBefore",
      priceAfter: "products.productS.basket3data.row1.priceAfter",
      priceRow2: "products.productS.basket3data.row1.priceRow2",
      content: "products.productS.basket3data.row1.content",
      list: {
        item1: "products.productS.basket3data.row1.list.item1",
        item2: "products.productS.basket3data.row1.list.item2",
        item3: "products.productS.basket3data.row1.list.item3",
        item4: "products.productS.basket3data.row1.list.item4",
      },
      image: "products.productS.basket3data.row1.image",
    },
    row2: {
      id: "products.productS.basket3data.row2.id",
      titleRow1: "products.productS.basket3data.row2.titleRow1",
      titleRow2: "products.productS.basket3data.row2.titleRow2",
      priceFrom: "products.productS.basket3data.row2.priceFrom",
      priceBefore: "products.productS.basket3data.row2.priceBefore",
      priceAfter: "products.productS.basket3data.row2.priceAfter",
      priceRow2: "products.productS.basket3data.row2.priceRow2",
      content: "products.productS.basket3data.row2.content",
      list: {
        item1: "products.productS.basket3data.row2.list.item1",
        item2: "products.productS.basket3data.row2.list.item2",
        item3: "products.productS.basket3data.row2.list.item3",
        item4: "products.productS.basket3data.row2.list.item4",
      },
      image: "products.productS.basket3data.row2.image",
    },
    row3: {
      id: "products.productS.basket3data.row3.id",
      titleRow1: "products.productS.basket3data.row3.titleRow1",
      titleRow2: "products.productS.basket3data.row3.titleRow2",
      priceFrom: "products.productS.basket3data.row3.priceFrom",
      priceBefore: "products.productS.basket3data.row3.priceBefore",
      priceAfter: "products.productS.basket3data.row3.priceAfter",
      priceRow2: "products.productS.basket3data.row3.priceRow2",
      content: "products.productS.basket3data.row3.content",
      list: {
        item1: "products.productS.basket3data.row3.list.item1",
        item2: "products.productS.basket3data.row3.list.item2",
        item3: "products.productS.basket3data.row3.list.item3",
        item4: "products.productS.basket3data.row3.list.item4",
      },
      image: "products.productS.basket3data.row3.image",
    },
    row4: {
      id: "products.productS.basket3data.row4.id",
      titleRow1: "products.productS.basket3data.row4.titleRow1",
      titleRow2: "products.productS.basket3data.row4.titleRow2",
      priceFrom: "products.productS.basket3data.row4.priceFrom",
      priceBefore: "products.productS.basket3data.row4.priceBefore",
      priceAfter: "products.productS.basket3data.row4.priceAfter",
      priceRow2: "products.productS.basket3data.row4.priceRow2",
      content: "products.productS.basket3data.row4.content",
      list: {
        item1: "products.productS.basket3data.row4.list.item1",
        item2: "products.productS.basket3data.row4.list.item2",
        item3: "products.productS.basket3data.row4.list.item3",
        item4: "products.productS.basket3data.row4.list.item4",
      },
      image: "products.productS.basket3data.row4.image",
    },
  },
  basket3Heading: {
    icon: "products.productS.basket3Heading.icon",
    text: "products.productS.basket3Heading.text",
  },
  basket3Headline: "Einkaufswagen",
  basket3footer: {
    prices: {
      priceLeft: {
        amount: "products.productS.basket3footer.prices.priceLeft.amount",
        text: "products.productS.basket3footer.prices.priceLeft.text",
      },
      priceRight: {
        amount: "products.productS.basket3footer.prices.priceRight.amount",
        text: "products.productS.basket3footer.prices.priceRight.text",
      },
    },
    bottomText: "products.productS.basket3footer.bottomText",
  },
  sound: "products.productS.sound",
  touchscreen: "products.productS.touchscreen",
  gtm: {
    price: "products.productS.gtm.price",
    currency: "products.productS.gtm.currency",
  },
  flatrate: {
    prices: {
      "36m": "products.productS.flatrate.prices.36m",
      "24m": "products.productS.flatrate.prices.24m",
      "12m": "products.productS.flatrate.prices.12m",
      christmasPromo: "products.productS.flatrate.prices.christmasPromo",
      christmasPromoBefore:
        "products.productS.flatrate.prices.christmasPromoBefore",
      wellnessSale: "products.productS.flatrate.prices.wellnessSale",
    },
  },
  dimensions: {
    size: "products.productS.dimensions.size",
    weight: "products.productS.dimensions.weight",
  },
  shipping: "products.productS.shipping",
  shippingExperimentwvSDVbi8R6KyxCB9T0CkrA:
    "products.productS.shippingExperimentwvSDVbi8R6KyxCB9T0CkrA",
  image:
    "https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/images/basket/VAHA_S_TRANSPARENT.png",
  imageBigger:
    "https://uk-vaha.s3.eu-central-1.amazonaws.com/static/assets/images/basket/VAHA_150_BIGGER.png",
  imageBasket: "https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/images/basket/vaha_s_basket.png",
  payUpfront: {
    prices: {
      promotional: "1.695 €",
      original: "1.995 €",
      monthly: "199 € mtl.",
      monthlyBare: "199 €"
    }
  },
  name: "products.productS.name",
  bulletPoints: {
    twoYearsWarranty: "products.productS.bulletPoints.twoYearsWarranty",
    inclWallBracket: "products.productS.bulletPoints.inclWallBracket",
    // inclFloorStand: 'products.productS.bulletPoints.inclFloorStand',
    zeroFinancing: "products.productS.bulletPoints.zeroFinancing",
  },
  freePremiumService: {
    label: "products.productS.freePremiumService.label",
    priceBefore: "products.productS.freePremiumService.priceBefore",
    priceAfter: "products.productS.freePremiumService.priceAfter",
  },
  prices: {
    "12m": {
      name: "products.productS.prices.12m.name",
      period: "products.productS.prices.12m.period",
      price: "products.productS.prices.12m.price",
    },
    "24m": {
      name: "products.productS.prices.24m.name",
      period: "products.productS.prices.24m.period",
      price: "products.productS.prices.24m.price",
    },
    "39m": {
      name: "products.productS.prices.39m.name",
      period: "products.productS.prices.39m.period",
      price: "products.productS.prices.39m.price",
    },
    direct: {
      name: "products.productS.prices.direct.name",
      price: "products.productS.prices.direct.price",
      discountPrice: "products.productS.prices.direct.discountPrice",
    },
    recommended: {
      name: "products.productS.prices.recommended.name",
      period: "products.productS.prices.recommended.period",
      price: "products.productS.prices.recommended.price",
    },
    basketRecommended: {
      name: "products.productS.prices.basketRecommended.name",
      period: "products.productS.prices.basketRecommended.period",
      price: "products.productS.prices.basketRecommended.price",
    },
    experimentwvSDVbi8R6KyxCB9T0CkrA: {
      "12m": {
        name:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.12m.name",
        period:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.12m.period",
        price:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.12m.price",
      },
      "24m": {
        name:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.24m.name",
        period:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.24m.period",
        price:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.24m.price",
      },
      "39m": {
        name:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.39m.name",
        period:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.39m.period",
        price:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.39m.price",
      },
      direct: {
        name:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.direct.name",
        price:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.direct.price",
      },
      recommended: {
        name:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA/recommended.name",
        period:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.recommended.period",
        price:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.recommended.price",
      },
      basketRecommended: {
        name:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.basketRecommended.name",
        period:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.basketRecommended.period",
        price:
          "products.productS.prices.experimentwvSDVbi8R6KyxCB9T0CkrA.basketRecommended.price",
      },
    },
  },
  specialLaunch: {
    show: "products.productS.specialLaunch.show",
    headline: "products.productS.specialLaunch.headline",
    terms: "products.productS.specialLaunch.terms",
    bulletPoints: {
      jabra: {
        content: "products.productS.specialLaunch.bulletPoints.jabra.content",
        priceBefore:
          "products.productS.specialLaunch.bulletPoints.jabra.priceBefore",
        priceAfter:
          "products.productS.specialLaunch.bulletPoints.jabra.priceAfter",
      },
      delivery: {
        content:
          "products.productS.specialLaunch.bulletPoints.delivery.content",
        priceBefore:
          "products.productS.specialLaunch.bulletPoints.delivery.priceBefore",
        priceAfter:
          "products.productS.specialLaunch.bulletPoints.delivery.priceAfter",
      },
      testing: {
        content: "products.productS.specialLaunch.bulletPoints.testing.content",
        priceBefore:
          "products.productS.specialLaunch.bulletPoints.testing.priceBefore",
        priceAfter:
          "products.productS.specialLaunch.bulletPoints.testing.priceAfter",
      },
      warranty: {
        content:
          "products.productS.specialLaunch.bulletPoints.warranty.content",
        priceBefore:
          "products.productS.specialLaunch.bulletPoints.warranty.priceBefore",
        priceAfter:
          "products.productS.specialLaunch.bulletPoints.warranty.priceAfter",
      },
    },
  },
  membership: {
    headline: "products.productS.membership.headline",
    bulletPoints: {
      oneMonthFree: "products.productS.membership.bulletPoints.oneMonthFree",
      twelveMonthsTerm:
        "products.productS.membership.bulletPoints.twelveMonthsTerm",
      billingAfterMirrorActivation:
        "products.productS.membership.bulletPoints.billingAfterMirrorActivation",
    },
    price: "products.productS.membership.price",
    priceHint: "products.productS.membership.priceHint",
    priceLabel: "products.productS.membership.priceLabel",
    pricePeriod: "products.productS.membership.pricePeriod",
  },
  starterBox: {
    headline: "products.productS.starterBox.headline",
    bulletPoints: {
      heartRate: "products.productS.starterBox.bulletPoints.heartRate",
      elasticBands: "products.productS.starterBox.bulletPoints.elasticBands",
      microfiberCloth:
        "products.productS.starterBox.bulletPoints.microfiberCloth",
      cameraCover: "products.productS.starterBox.bulletPoints.cameraCover",
      userManual: "products.productS.starterBox.bulletPoints.userManual",
    },
    price: "products.productS.starterBox.price",
    priceHint: "products.productS.starterBox.priceHint",
    priceLabel: "products.productS.starterBox.priceLabel",
  },
  financingOptions: {
    items: {
      "12m": {
        id: "products.productS.financingOptions.items.12m.id",
        nameKey: "products.productS.financingOptions.items.12m.nameKey",
        priceKey: "products.productS.financingOptions.items.12m.priceKey",
        periodKey: "products.productS.financingOptions.items.12m.periodKey",
      },
      "24m": {
        id: "products.productS.financingOptions.items.24m.id",
        nameKey: "products.productS.financingOptions.items.24m.nameKey",
        priceKey: "products.productS.financingOptions.items.24m.priceKey",
        periodKey: "products.productS.financingOptions.items.24m.periodKey",
      },
      "36m": {
        id: "products.productS.financingOptions.items.36m.id",
        nameKey: "products.productS.financingOptions.items.36m.nameKey",
        priceKey: "products.productS.financingOptions.items.36m.priceKey",
        periodKey: "products.productS.financingOptions.items.36m.periodKey",
      },
      "39m": {
        id: "products.productS.financingOptions.items.39m.id",
        nameKey: "products.productS.financingOptions.items.39m.nameKey",
        priceKey: "products.productS.financingOptions.items.39m.priceKey",
        periodKey: "products.productS.financingOptions.items.39m.periodKey",
      },
      "48m": {
        id: "products.productS.financingOptions.items.48m.id",
        nameKey: "products.productS.financingOptions.items.48m.nameKey",
        priceKey: "products.productS.financingOptions.items.48m.priceKey",
        periodKey: "products.productS.financingOptions.items.48m.periodKey",
      },
      direct: {
        id: "products.productS.financingOptions.items.direct.id",
        nameKey: "products.productS.financingOptions.items.direct.nameKey",
        priceKey: "products.productS.financingOptions.items.direct.priceKey",
        periodKey: "products.productS.financingOptions.items.direct.periodKey",
      },
    },
    advantageKeys: {
      interest: "products.productS.financingOptions.advantageKeys.interest",
      totalPrice: "products.productS.financingOptions.advantageKeys.totalPrice",
      vat: "products.productS.financingOptions.advantageKeys.vat",
    },
  },
  financingOptionsSection: {
    mirror: {
      type: "products.productS.financingOptionsSection.mirror.type",
      headline: "products.productS.financingOptionsSection.mirror.headline",
      priceHint: "products.productS.financingOptionsSection.mirror.priceHint",
      price: "products.productS.financingOptionsSection.mirror.price",
      period: "products.productS.financingOptionsSection.mirror.period",
      linkText: "products.productS.financingOptionsSection.mirror.linkText",
    },
    membership: {
      type: "products.productS.financingOptionsSection.membership.type",
      headline: "products.productS.financingOptionsSection.membership.headline",
      price: "products.productS.financingOptionsSection.membership.price",
      period: "products.productS.financingOptionsSection.membership.period",
      linkText: "products.productS.financingOptionsSection.membership.linkText",
    },
    total: {
      totalPrice: "products.productS.financingOptionsSection.total.totalPrice",
      totalPeriod:
        "products.productS.financingOptionsSection.total.totalPeriod",
      disclaimerFirst:
        "products.productS.financingOptionsSection.total.disclaimerFirst",
      disclaimerSecond:
        "products.productS.financingOptionsSection.total.disclaimerSecond",
    },
  },
  paymentButton: {
    url: "products.productS.tmpData.paymentButton.url",
    label: "products.productS.tmpData.paymentButton.label",
    flatrateUrl: "products.productS.tmpData.paymentButton.flatrateUrl",
  },
  productDetails: {
    specification: {
      productName: "products.productS.productDetails.productName",
      productDescription: "products.productS.productDetails.productDescription",
      headlineKey: "products.productS.productDetails.specification.headlineKey",
      items: {
        item1: {
          nameKey:
            "products.productS.productDetails.specification.items.item1.nameKey",
          contentKey:
            "products.productS.productDetails.specification.items.item1.contentKey",
        },
        item2: {
          nameKey:
            "products.productS.productDetails.specification.items.item2.nameKey",
          contentKey:
            "products.productS.productDetails.specification.items.item2.contentKey",
        },
        item3: {
          nameKey:
            "products.productS.productDetails.specification.items.item3.nameKey",
          contentKey:
            "products.productS.productDetails.specification.items.item3.contentKey",
        },
        item4: {
          nameKey:
            "products.productS.productDetails.specification.items.item4.nameKey",
          contentKey:
            "products.productS.productDetails.specification.items.item4.contentKey",
        },
        item5: {
          nameKey:
            "products.productS.productDetails.specification.items.item5.nameKey",
          contentKey:
            "products.productS.productDetails.specification.items.item5.contentKey",
        },
      },
    },
    technical: {
      headlineKey: "products.productS.productDetails.technical.headlineKey",
      items: {
        item1: {
          nameKey:
            "products.productS.productDetails.technical.items.item1.nameKey",
          contentKey:
            "products.productS.productDetails.technical.items.item1.contentKey",
        },
        item2: {
          nameKey:
            "products.productS.productDetails.technical.items.item2.nameKey",
          contentKey:
            "products.productS.productDetails.technical.items.item2.contentKey",
        },
        item3: {
          nameKey:
            "products.productS.productDetails.technical.items.item3.nameKey",
          contentKey:
            "products.productS.productDetails.technical.items.item3.contentKey",
        },
        item4: {
          nameKey:
            "products.productS.productDetails.technical.items.item4.nameKey",
          contentKey:
            "products.productS.productDetails.technical.items.item4.contentKey",
        },
        item5: {
          nameKey:
            "products.productS.productDetails.technical.items.item5.nameKey",
          contentKey:
            "products.productS.productDetails.technical.items.item5.contentKey",
        },
      },
    },
    starterbox: {
      headlineKey: "products.productS.productDetails.starterbox.headlineKey",
      items: {
        item1: {
          nameKey:
            "products.productS.productDetails.starterbox.items.item1.nameKey",
          contentKey:
            "products.productS.productDetails.starterbox.items.item1.contentKey",
        },
        item2: {
          nameKey:
            "products.productS.productDetails.starterbox.items.item2.nameKey",
          contentKey:
            "products.productS.productDetails.starterbox.items.item2.contentKey",
        },
      },
    },
    buttonText: "products.productS.productDetails.buttonText",
  },
  featuredServices: {
    items: {
      delivery: {
        name: "products.productS.featuredServices.items.delivery.name",
        content: "products.productS.featuredServices.items.delivery.content",
        id: "products.productS.featuredServices.items.delivery.id",
      },
      testing: {
        name: "products.productS.featuredServices.items.testing.name",
        content: "products.productS.featuredServices.items.testing.content",
        id: "products.productS.featuredServices.items.testing.id",
      },
      warranty: {
        name: "products.productS.featuredServices.items.warranty.name",
        content: "products.productS.featuredServices.items.warranty.content",
        id: "products.productS.featuredServices.items.warranty.id",
      },
    },
  },
  experimentFeaturedServices: {
    items: {
      delivery: {
        name:
          "products.productS.experimentFeaturedServices.items.delivery.name",
        content:
          "products.productS.experimentFeaturedServices.items.delivery.content",
        id: "products.productS.experimentFeaturedServices.items.delivery.id",
      },
      testing: {
        name: "products.productS.experimentFeaturedServices.items.testing.name",
        content:
          "products.productS.experimentFeaturedServices.items.testing.content",
        id: "products.productS.experimentFeaturedServices.items.testing.id",
      },
      warranty: {
        name:
          "products.productS.experimentFeaturedServices.items.warranty.name",
        content:
          "products.productS.experimentFeaturedServices.items.warranty.content",
        id: "products.productS.experimentFeaturedServices.items.warranty.id",
      },
    },
  },
};

export const PRODUCTS = [VAHA_S_PRODUCT, VAHA_X_PRODUCT];
