<template>
  <section class="promo-box-container">
    <!-- UK -->
    <div v-if="region == 'UK'">
      <!-- PROMO BOX UK NO REVIEWS -->
      <!-- <div v-if="variant === 'NO_REVIEWS'">
        <div class="basket-wrapper yellow-wrapper">
          <div class="basket-title">
            <p>{{ $t(selectedProduct.specialLaunch.headline) }}</p>
          </div>
          <div class="basket-list-price-wrapper special-launch-wrapper">
            <ul class="basket-price-summary">
              <li
                v-for="bulletpoint in selectedProduct.specialLaunch
                  .bulletPoints"
                :key="bulletpoint.content"
                :class="{ dnone: $te(bulletpoint.content) != true }"
              >
                <span class="list-content">{{ $t(bulletpoint.content) }}</span>
                <div class="price">
                  <span class="crossed-price">{{
                    $t(bulletpoint.priceBefore)
                  }}</span>
                  <span class="recent-price">{{
                    $t(bulletpoint.priceAfter)
                  }}</span>
                </div>
              </li>
            </ul>

            <div class="basket-special-launch-terms">
              <div
                class="vaha-text--underlined has-text-carbon-black"
                v-html="$t(selectedProduct.specialLaunch.terms)"
              />
            </div>
          </div>
        </div>
      </div> -->
      <div class="with-reviews" v-if="variant === 'WITH_REVIEWS'">
        <!-- PROMO BOX UK WITH REVIEWS -->
        <!-- class = "yellow-wrapper"-->
        <div class="basket-wrapper reviews" v-if="showYellowBox">
          <div class="basket-title">
            Pay £0 today. Try before you buy for 30 days
          </div>
        </div>

        <div class="reviews" :class="{ 'mt-2': showYellowBox }">
          <p @click="handleReviewsClick()" class="text-underline">
            {{ reviewsStats.total_reviews }} Reviews
          </p>
          <div class="rating">
            <IconStar />
            <IconStar />
            <IconStar />
            <IconStar />
            <IconStar />
            <p>{{ reviewsStats.average_rating }} / 5</p>
          </div>
        </div>
        <div class="free-trial mt-3 mb-3 p-4">
          <p class="font-20 font-weight-700 mb-0 has-text-centered">
            30-day free trial
          </p>
        </div>
      </div>
    </div>

    <!-- DE/AT -->
    <div v-else-if="region === 'DE' || region === 'AT'">
      <!-- DE/AT VAHA S NO REVIEWS -->
      <!--
      <div
        v-if="
          $t(selectedProduct.id) == 2 &&
            region === 'DE' || region === 'AT' &&
            variant == 'NO_REVIEWS'
        "
        class="basket-promo-box"
      >
        <h6 class="font-16">
          <strong style="font-size:0.95rem"
            >Exklusives Angebot für das VAHA S bis 31.8.2021</strong
          >
        </h6>
        <div class="basket-promo-box-items">
          <div class="basket-promo-box-item">
            <div class="basket-promo-box-item-name">Bodenständer</div>
            <div class="basket-promo-box-item-price-holder">
              <div class="basket-promo-box-item-price-before">80</div>
              <div class="basket-promo-box-item-price-currency">€</div>
              <div class="basket-promo-box-item-price-after">Gratis</div>
            </div>
          </div>
          <div class="basket-promo-box-item">
            <div class="basket-promo-box-item-name">Lieferung</div>
            <div class="basket-promo-box-item-price-holder">
              <div class="basket-promo-box-item-price-before">99</div>
              <div class="basket-promo-box-item-price-currency">€</div>
              <div class="basket-promo-box-item-price-after">Gratis</div>
            </div>
          </div>
        </div>
        <div class="basket-promo-box-link">
          <a
            href="https://lp.vaha.com/vahas-bedingungen-und-konditionen/"
            target="_blank"
            >Bedingungen und Konditionen VAHA S Launch Angebot
          </a>
        </div>
      </div>
      -->
      <!-- DE/AT WITH REVIEWS -->
      <div class="with-reviews" v-if="variant === 'WITH_REVIEWS'">
        <div class="basket-wrapper reviews mt-4 mb-4" v-if="showYellowBox">
          <div class="basket-title" style="font-size: 14px!important">
            30 Tage ohne Risiko testen<br />
            mit Geld-zurück-Garantie
          </div>
        </div>
        <div class="reviews">
          <p @click="handleReviewsClick()" class="text-underline">
            {{ reviewsStats.total_reviews }} Reviews
          </p>
          <div class="rating">
            <IconStar />
            <IconStar />
            <IconStar />
            <IconStar />
            <IconStar />
            <p>{{ reviewsStats.average_rating }} / 5</p>
          </div>
        </div>
        <div class="free-trial mt-3 mb-3 p-4">
          <p class="font-20 font-weight-500 mb-0 has-text-centered">
            30 Tage <span class="font-weight-700">ohne Risiko</span> testen
            <br />
            mit Geld-zurück-Garantie
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IconStar from "../icons/star";
import axios from "axios";

export default {
  components: { IconStar },
  name: "PromoBox",
  props: {
    variant: { type: String, default: "NO_REVIEWS" },
    selectedProduct: { type: Object },
    region: { type: String, default: "UK" },
    showYellowBox: { type: Boolean, default: true },
  },
  data() {
    return {
      reviewsStats: { total_reviews: 118, average_rating: "4.79" },
    };
  },
  methods: {
    handleReviewsClick() {
      this.$root.$emit("reviewsLinkClicked");
    },
    async getReviews() {
      try {
        const response = await axios.get(
          "https://api.reviews.co.uk/merchant/reviews?store=www.vaha.com&apikey=ddfea7cf62bdbc89caf2f3e41946e74f"
        );
        const responseStats = response.data.stats;
        // parse string to one decimal place number. API returns String
        const averageRating =
          Math.round(parseFloat(responseStats.average_rating) * 10) / 10;

        responseStats.average_rating = averageRating;
        this.reviewsStats = responseStats;
      } catch (e) {
        // if api fail, fallback to last known result
        this.reviewsStats = { total_reviews: 118, average_rating: "4.8" };
      }
    },
  },
  mounted() {
    this.getReviews();
  },
};
</script>

<style lang="scss" scoped>
.reviews {
  font-weight: 700;

  .text-underline {
    text-decoration: underline;
    cursor: pointer;
  }
}

.yellow-wrapper {
  background: #ffd26e;
  border-radius: 4px;
  padding: 10px;

  .basket-price-summary {
    width: 100%;
    li {
      margin-bottom: 10px;
      align-items: flex-end;
      display: flex;
      justify-content: space-between;
      &::before {
        background: url("https://uk-vaha.s3.eu-central-1.amazonaws.com/assets/icons/check-white.svg")
          center no-repeat;
      }

      .list-content {
        width: 180px;
      }
    }
  }

  .special-launch-wrapper {
    flex-direction: column;

    .basket-special-launch-terms {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-right: 1rem;
      color: #222222 !important;

      .vaha-text--underlined {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
}

.with-reviews,
.no-reviews {
  width: 100%;

  .yellow-wrapper {
    padding: 10px;
    font-size: 18px;
  }
}

.reviews,
.free-trial {
  color: #222;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  padding: 10px 10px 15px 10px;
  border: 1px solid black;
  border-radius: 4px;

  p {
    margin-bottom: 10px;
    font-size: 18px;
  }

  .rating {
    display: flex;
    p {
      margin-bottom: 0;
      margin-left: 15px;
    }
  }

  svg {
    width: 22px;
    height: auto;
    margin-right: 5px;
  }
}

.free-trial {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #222;
  color: #fff;
}

.font-20 {
  font-size: 20px;
}

.font-weight-700 {
  font-weight: 700;
}
</style>
