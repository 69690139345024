export const vahaxmasProductS = {
  mainPromoHeadline: "products.product.promotions.vahaxmas.mainPromoHeadline",
  heading: {
    image: "products.productS.promotions.vahaxmas.heading.image",
    title: "products.productS.promotions.vahaxmas.heading.title",
  },
  pricesBeforeAfter: {
    priceBefore:
      "products.productS.promotions.vahaxmas.pricesBeforeAfter.priceBefore",
    priceAfter:
      "products.productS.promotions.vahaxmas.pricesBeforeAfter.priceAfter",
  },
  footer: "products.productS.promotions.vahaxmas.footer",
  total: {
    headline: "products.productS.promotions.vahaxmas.total.headline",
    priceBefore: "products.productS.promotions.vahaxmas.total.priceBefore",
    priceAfter: "products.productS.promotions.vahaxmas.total.priceAfter",
  },
  discount: {
    heading: "products.productS.promotions.vahaxmas.discount.heading",
    title: "products.productS.promotions.vahaxmas.discount.title",
    amount: "products.productS.promotions.vahaxmas.discount.amount",
    code: {
      description:
        "products.productS.promotions.vahaxmas.discount.code.description",
      promoCode:
        "products.productS.promotions.vahaxmas.discount.code.promoCode",
    },
  },
  items: {
    item1: {
      title: "products.productS.promotions.vahaxmas.items.item1.title",
      description:
        "products.productS.promotions.vahaxmas.items.item1.description",
      priceBefore:
        "products.productS.promotions.vahaxmas.items.item1.priceBefore",
      priceAfter:
        "products.productS.promotions.vahaxmas.items.item1.priceAfter",
    },
    item2: {
      title: "products.productS.promotions.vahaxmas.items.item2.title",
      description:
        "products.productS.promotions.vahaxmas.items.item2.description",
      priceBefore:
        "products.productS.promotions.vahaxmas.items.item2.priceBefore",
      priceAfter:
        "products.productS.promotions.vahaxmas.items.item2.priceAfter",
    },
    item3: {
      title: "products.productS.promotions.vahaxmas.items.item3.title",
      description:
        "products.productS.promotions.vahaxmas.items.item3.description",
      priceBefore:
        "products.productS.promotions.vahaxmas.items.item3.priceBefore",
      priceAfter:
        "products.productS.promotions.vahaxmas.items.item3.priceAfter",
    },
    item4: {
      title: "products.productS.promotions.vahaxmas.items.item4.title",
      description:
        "products.productS.promotions.vahaxmas.items.item4.description",
      priceBefore:
        "products.productS.promotions.vahaxmas.items.item4.priceBefore",
      priceAfter:
        "products.productS.promotions.vahaxmas.items.item4.priceAfter",
    },
    item5: {
      title: "products.productS.promotions.vahaxmas.items.item5.title",
      description:
        "products.productS.promotions.vahaxmas.items.item5.description",
      priceBefore:
        "products.productS.promotions.vahaxmas.items.item5.priceBefore",
      priceAfter:
        "products.productS.promotions.vahaxmas.items.item5.priceAfter",
    },
    item6: {
      title: "products.productS.promotions.vahaxmas.items.item6.title",
      description:
        "products.productS.promotions.vahaxmas.items.item6.description",
      priceBefore:
        "products.productS.promotions.vahaxmas.items.item6.priceBefore",
      priceAfter:
        "products.productS.promotions.vahaxmas.items.item6.priceAfter",
    },
    item7: {
      title: "products.productS.promotions.vahaxmas.items.item7.title",
      description:
        "products.productS.promotions.vahaxmas.items.item7.description",
      priceBefore:
        "products.productS.promotions.vahaxmas.items.item7.priceBefore",
      priceAfter:
        "products.productS.promotions.vahaxmas.items.item7.priceAfter",
    },
    item8: {
      title: "products.productS.promotions.vahaxmas.items.item8.title",
      description:
        "products.productS.promotions.vahaxmas.items.item8.description",
      priceBefore:
        "products.productS.promotions.vahaxmas.items.item8.priceBefore",
      priceAfter:
        "products.productS.promotions.vahaxmas.items.item8.priceAfter",
    },
  },
};

export const flatrateVahaxmasProductS = {
  mainPromoHeadline:
    "products.productS.promotions.flatrateVahaxmas.mainPromoHeadline",
  heading: {
    image: "products.productS.promotions.flatrateVahaxmas.heading.image",
    title: "products.productS.promotions.flatrateVahaxmas.heading.title",
  },
  footer: "products.productS.promotions.flatrateVahaxmas.footer",
  total: {
    headline: "products.productS.promotions.flatrateVahaxmas.total.headline",
    priceBefore:
      "products.productS.promotions.flatrateVahaxmas.total.priceBefore",
    priceAfter:
      "products.productS.promotions.flatrateVahaxmas.total.priceAfter",
  },
  discount: {
    heading: "products.productS.promotions.vahaxmas.discount.heading",
    title: "products.productS.promotions.flatrateVahaxmas.discount.title",
    amount: "products.productS.promotions.flatrateVahaxmas.discount.amount",
    code: {
      description:
        "products.productS.promotions.flatrateVahaxmas.discount.code.description",
      promoCode:
        "products.productS.promotions.flatrateVahaxmas.discount.code.promoCode",
    },
  },
  items: {
    item1: {
      title: "products.productS.promotions.flatrateVahaxmas.items.item1.title",
      description:
        "products.productS.promotions.flatrateVahaxmas.items.item1.description",
      priceBefore:
        "products.productS.promotions.flatrateVahaxmas.items.item1.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateVahaxmas.items.item1.priceAfter",
    },
    item2: {
      title: "products.productS.promotions.flatrateVahaxmas.items.item2.title",
      description:
        "products.productS.promotions.flatrateVahaxmas.items.item2.description",
      priceBefore:
        "products.productS.promotions.flatrateVahaxmas.items.item2.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateVahaxmas.items.item2.priceAfter",
    },
    item3: {
      title: "products.productS.promotions.flatrateVahaxmas.items.item3.title",
      description:
        "products.productS.promotions.flatrateVahaxmas.items.item3.description",
      priceBefore:
        "products.productS.promotions.flatrateVahaxmas.items.item3.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateVahaxmas.items.item3.priceAfter",
    },
    item4: {
      title: "products.productS.promotions.flatrateVahaxmas.items.item4.title",
      description:
        "products.productS.promotions.flatrateVahaxmas.items.item4.description",
      priceBefore:
        "products.productS.promotions.flatrateVahaxmas.items.item4.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateVahaxmas.items.item4.priceAfter",
    },
    item5: {
      title: "products.productS.promotions.flatrateVahaxmas.items.item5.title",
      description:
        "products.productS.promotions.flatrateVahaxmas.items.item5.description",
      priceBefore:
        "products.productS.promotions.flatrateVahaxmas.items.item5.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateVahaxmas.items.item5.priceAfter",
    },
    item6: {
      title: "products.productS.promotions.flatrateVahaxmas.items.item6.title",
      description:
        "products.productS.promotions.flatrateVahaxmas.items.item6.description",
      priceBefore:
        "products.productS.promotions.flatrateVahaxmas.items.item6.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateVahaxmas.items.item6.priceAfter",
    },
    item7: {
      title: "products.productS.promotions.flatrateVahaxmas.items.item7.title",
      description:
        "products.productS.promotions.flatrateVahaxmas.items.item7.description",
      priceBefore:
        "products.productS.promotions.flatrateVahaxmas.items.item7.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateVahaxmas.items.item7.priceAfter",
    },
    item8: {
      title: "products.productS.promotions.flatrateVahaxmas.items.item8.title",
      description:
        "products.productS.promotions.flatrateVahaxmas.items.item8.description",
      priceBefore:
        "products.productS.promotions.flatrateVahaxmas.items.item8.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateVahaxmas.items.item8.priceAfter",
    },
  },
};

export const vahaxmasProductX = {
  mainPromoHeadline: "products.productX.promotions.vahaxmas.mainPromoHeadline",
  heading: {
    image: "products.productX.promotions.vahaxmas.heading.image",
    title: "products.productX.promotions.vahaxmas.heading.title",
  },
  pricesBeforeAfter: {
    priceBefore:
      "products.productX.promotions.vahaxmas.pricesBeforeAfter.priceBefore",
    priceAfter:
      "products.productX.promotions.vahaxmas.pricesBeforeAfter.priceAfter",
  },
  footer: "products.productX.promotions.vahaxmas.footer",
  total: {
    headline: "products.productX.promotions.vahaxmas.total.headline",
    priceBefore: "products.productX.promotions.vahaxmas.total.priceBefore",
    priceAfter: "products.productX.promotions.vahaxmas.total.priceAfter",
  },
  discount: {
    heading: "products.productX.promotions.vahaxmas.discount.heading",
    title: "products.productX.promotions.vahaxmas.discount.title",
    amount: "products.productX.promotions.vahaxmas.discount.amount",
    code: {
      description:
        "products.productX.promotions.vahaxmas.discount.code.description",
      promoCode:
        "products.productX.promotions.vahaxmas.discount.code.promoCode",
    },
  },
  items: {
    item1: {
      title: "products.productX.promotions.vahaxmas.items.item1.title",
      description:
        "products.productX.promotions.vahaxmas.items.item1.description",
      priceBefore:
        "products.productX.promotions.vahaxmas.items.item1.priceBefore",
      priceAfter:
        "products.productX.promotions.vahaxmas.items.item1.priceAfter",
    },
    item2: {
      title: "products.productX.promotions.vahaxmas.items.item2.title",
      description:
        "products.productX.promotions.vahaxmas.items.item2.description",
      priceBefore:
        "products.productX.promotions.vahaxmas.items.item2.priceBefore",
      priceAfter:
        "products.productX.promotions.vahaxmas.items.item2.priceAfter",
    },
    item3: {
      title: "products.productX.promotions.vahaxmas.items.item3.title",
      description:
        "products.productX.promotions.vahaxmas.items.item3.description",
      priceBefore:
        "products.productX.promotions.vahaxmas.items.item3.priceBefore",
      priceAfter:
        "products.productX.promotions.vahaxmas.items.item3.priceAfter",
    },
    item4: {
      title: "products.productX.promotions.vahaxmas.items.item4.title",
      description:
        "products.productX.promotions.vahaxmas.items.item4.description",
      priceBefore:
        "products.productX.promotions.vahaxmas.items.item4.priceBefore",
      priceAfter:
        "products.productX.promotions.vahaxmas.items.item4.priceAfter",
    },
    item5: {
      title: "products.productX.promotions.vahaxmas.items.item5.title",
      description:
        "products.productX.promotions.vahaxmas.items.item5.description",
      priceBefore:
        "products.productX.promotions.vahaxmas.items.item5.priceBefore",
      priceAfter:
        "products.productX.promotions.vahaxmas.items.item5.priceAfter",
    },
    item6: {
      title: "products.productX.promotions.vahaxmas.items.item6.title",
      description:
        "products.productX.promotions.vahaxmas.items.item6.description",
      priceBefore:
        "products.productX.promotions.vahaxmas.items.item6.priceBefore",
      priceAfter:
        "products.productX.promotions.vahaxmas.items.item6.priceAfter",
    },
    item7: {
      title: "products.productX.promotions.vahaxmas.items.item7.title",
      description:
        "products.productX.promotions.vahaxmas.items.item7.description",
      priceBefore:
        "products.productX.promotions.vahaxmas.items.item7.priceBefore",
      priceAfter:
        "products.productX.promotions.vahaxmas.items.item7.priceAfter",
    },
    item8: {
      title: "products.productX.promotions.vahaxmas.items.item8.title",
      description:
        "products.productX.promotions.vahaxmas.items.item8.description",
      priceBefore:
        "products.productX.promotions.vahaxmas.items.item8.priceBefore",
      priceAfter:
        "products.productX.promotions.vahaxmas.items.item8.priceAfter",
    },
  },
};

export const flatrateVahaxmasProductX = {
  mainPromoHeadline:
    "products.productX.promotions.flatrateVahaxmas.mainPromoHeadline",
  heading: {
    image: "products.productX.promotions.flatrateVahaxmas.heading.image",
    title: "products.productX.promotions.flatrateVahaxmas.heading.title",
  },
  footer: "products.productX.promotions.flatrateVahaxmas.footer",
  total: {
    headline: "products.productX.promotions.flatrateVahaxmas.total.headline",
    priceBefore:
      "products.productX.promotions.flatrateVahaxmas.total.priceBefore",
    priceAfter:
      "products.productX.promotions.flatrateVahaxmas.total.priceAfter",
  },
  discount: {
    heading: "products.productX.promotions.vahaxmas.discount.heading",
    title: "products.productX.promotions.flatrateVahaxmas.discount.title",
    amount: "products.productX.promotions.flatrateVahaxmas.discount.amount",
    code: {
      description:
        "products.productX.promotions.flatrateVahaxmas.discount.code.description",
      promoCode:
        "products.productX.promotions.flatrateVahaxmas.discount.code.promoCode",
    },
  },
  items: {
    item1: {
      title: "products.productX.promotions.flatrateVahaxmas.items.item1.title",
      description:
        "products.productX.promotions.flatrateVahaxmas.items.item1.description",
      priceBefore:
        "products.productX.promotions.flatrateVahaxmas.items.item1.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateVahaxmas.items.item1.priceAfter",
    },
    item2: {
      title: "products.productX.promotions.flatrateVahaxmas.items.item2.title",
      description:
        "products.productX.promotions.flatrateVahaxmas.items.item2.description",
      priceBefore:
        "products.productX.promotions.flatrateVahaxmas.items.item2.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateVahaxmas.items.item2.priceAfter",
    },
    item3: {
      title: "products.productX.promotions.flatrateVahaxmas.items.item3.title",
      description:
        "products.productX.promotions.flatrateVahaxmas.items.item3.description",
      priceBefore:
        "products.productX.promotions.flatrateVahaxmas.items.item3.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateVahaxmas.items.item3.priceAfter",
    },
    item4: {
      title: "products.productX.promotions.flatrateVahaxmas.items.item4.title",
      description:
        "products.productX.promotions.flatrateVahaxmas.items.item4.description",
      priceBefore:
        "products.productX.promotions.flatrateVahaxmas.items.item4.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateVahaxmas.items.item4.priceAfter",
    },
    item5: {
      title: "products.productX.promotions.flatrateVahaxmas.items.item5.title",
      description:
        "products.productX.promotions.flatrateVahaxmas.items.item5.description",
      priceBefore:
        "products.productX.promotions.flatrateVahaxmas.items.item5.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateVahaxmas.items.item5.priceAfter",
    },
    item6: {
      title: "products.productX.promotions.flatrateVahaxmas.items.item6.title",
      description:
        "products.productX.promotions.flatrateVahaxmas.items.item6.description",
      priceBefore:
        "products.productX.promotions.flatrateVahaxmas.items.item6.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateVahaxmas.items.item6.priceAfter",
    },
    item7: {
      title: "products.productX.promotions.flatrateVahaxmas.items.item7.title",
      description:
        "products.productX.promotions.flatrateVahaxmas.items.item7.description",
      priceBefore:
        "products.productX.promotions.flatrateVahaxmas.items.item7.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateVahaxmas.items.item7.priceAfter",
    },
    item8: {
      title: "products.productX.promotions.flatrateVahaxmas.items.item8.title",
      description:
        "products.productX.promotions.flatrateVahaxmas.items.item8.description",
      priceBefore:
        "products.productX.promotions.flatrateVahaxmas.items.item8.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateVahaxmas.items.item8.priceAfter",
    },
  },
};
