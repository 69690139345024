// import ShopifyClient from "shopify-buy";
import axios from "axios";
import {
  stagingDACHhardwareLineItems,
  stagingDACHvahaShardwareVariantID,
  stagingDACHvahaXhardwareVariantID,
  stagingUKhardwareLineItems,
  stagingUKvahaXclassicVariantID,
  stagingUKvahaSclassicVariantID,
  productionDACHhardwareLineItems,
  productionDACHvahaShardwareVariantID,
  productionDACHvahaXhardwareVariantID,
  productionUKhardwareLineItems,
  productionUKvahaXclassicVariantID,
  productionUKvahaSclassicVariantID,
  stagingDACHvahaXhardwareDeviceVariantID,
  stagingDACHvahaShardwareDeviceVariantID,
  productionDACHvahaShardwareDeviceVariantID,
  productionDACHvahaXhardwareDeviceVariantID,
  stagingDACHmembershipVariantID,
  productionDACHvahaPlan12MonthUpfrontVahaXVariantID,
  productionDACHvahaPlan12MonthUpfrontVahaSVariantID,
  productionAEvahaXclassicVariantID,
  productionAELineItems,
  // productionVahaXblackFriday,
  // productionVahaSblackFriday,
  // productionDACHmembershipVariantID,
  // productionDACHHardwareLineItemsArray,
} from "../constants/new-basket";
import { store } from "../store";

export default {
  methods: {
    prepareVariables(selectedRegion) {
      console.log("here", selectedRegion);
      console.log("variables");
      console.log(
        this.shopifyDomainDACH,
        this.shopifyStorefrontTokenDACH,
        this.shopifyDomainUK,
        this.shopifyStorefrontTokenUK
      );
      if (selectedRegion === "UK") {
        this.shopifyDomain = this.shopifyDomainUK;
        this.shopifyStorefrontAccessToken = this.shopifyStorefrontTokenUK;
      } else if (selectedRegion === "AE") {
        this.shopifyDomain = this.shopifyDomainAE;
        this.shopifyStorefrontAccessToken = this.shopifyStorefrontTokenAE;
      } else if (selectedRegion === "AT" || selectedRegion === "DE") {
        this.shopifyDomain = this.shopifyDomainDACH;
        this.shopifyStorefrontAccessToken = this.shopifyStorefrontTokenDACH;
      }
    },
    async prepareShopifyUrl(productId, selectedRegion) {
      try {
        this.$root.$emit("checkoutButtonClicked", true);
        const subdomain = window.location.host.split(".")[1]
          ? window.location.host.split(".")[0]
          : false;
        let environment = "production";
        if (subdomain != false) {
          console.log(1);
          if (subdomain.includes("dev")) {
            console.log(2);
            environment = "development";
          }
          if (subdomain.includes("stage")) {
            console.log(3);
            environment = "staging";
          }
        }

        if (!subdomain) {
          environment = "production";
        }
        environment = "production";
        console.log("subdomain: ", subdomain);
        console.log("env: ", environment);
        console.log("domain", this.shopifyDomain);
        console.log("token", this.shopifyStorefrontAccessToken);
        await this.prepareVariables(selectedRegion);
        // const shopifyClient = ShopifyClient.buildClient({
        //   domain: this.shopifyDomain,
        //   storefrontAccessToken: this.shopifyStorefrontAccessToken,
        //   apiVersion: "2022-07",
        // });
        // const checkoutObject = await shopifyClient.checkout.create();

        const isVahaX = productId === "products.productX.id" ?? true;
        console.log(productId, isVahaX);
        let lineItemsToAdd = [];
        if (environment !== "production") {
          if (selectedRegion === "UK") {
            // staging uk
            console.log(
              stagingUKvahaXclassicVariantID,
              stagingUKvahaSclassicVariantID
            );
            lineItemsToAdd = stagingUKhardwareLineItems.slice();

            if (isVahaX) {
              lineItemsToAdd.unshift({
                variantId: `gid://shopify/ProductVariant/${stagingUKvahaXclassicVariantID}`,
                quantity: 1,
              });
            } else if (!isVahaX) {
              lineItemsToAdd.unshift({
                variantId: `gid://shopify/ProductVariant/${stagingUKvahaSclassicVariantID}`,
                quantity: 1,
              });
            }
          } else if (selectedRegion === "AT" || selectedRegion === "DE") {
            lineItemsToAdd = stagingDACHhardwareLineItems.slice();
            if (isVahaX) {
              lineItemsToAdd.unshift({
                variantId: `gid://shopify/ProductVariant/${stagingDACHvahaXhardwareDeviceVariantID}`,
                quantity: 1,
              });
              lineItemsToAdd.unshift({
                variantId: `gid://shopify/ProductVariant/${stagingDACHmembershipVariantID}`,
                quantity: 1,
              });
              lineItemsToAdd.unshift({
                variantId: `gid://shopify/ProductVariant/${stagingDACHvahaXhardwareVariantID}`,
                quantity: 1,
              });
            } else if (!isVahaX) {
              lineItemsToAdd.unshift({
                variantId: `gid://shopify/ProductVariant/${stagingDACHvahaShardwareDeviceVariantID}`,
                quantity: 1,
              });
              lineItemsToAdd.unshift({
                variantId: `gid://shopify/ProductVariant/${stagingDACHmembershipVariantID}`,
                quantity: 1,
              });
              lineItemsToAdd.unshift({
                variantId: `gid://shopify/ProductVariant/${stagingDACHvahaShardwareVariantID}`,
                quantity: 1,
              });
            }
          }
        }

        if (environment === "production") {
          if (selectedRegion === "UK") {
            // production uk
            console.log(
              productionUKvahaXclassicVariantID,
              productionUKvahaSclassicVariantID
            );
            lineItemsToAdd = productionUKhardwareLineItems.slice();
            console.log(isVahaX);
            if (isVahaX) {
              lineItemsToAdd.unshift({
                variantId: `gid://shopify/ProductVariant/${productionUKvahaXclassicVariantID}`,
                quantity: 1,
              });
            } else if (!isVahaX) {
              lineItemsToAdd.unshift({
                variantId: `gid://shopify/ProductVariant/${productionUKvahaSclassicVariantID}`,
                quantity: 1,
              });
            }
          } else if (selectedRegion === "AE") {
            lineItemsToAdd = productionAELineItems.slice();
            if (isVahaX) {
              lineItemsToAdd.unshift({
                variantId: `gid://shopify/ProductVariant/${productionAEvahaXclassicVariantID}`,
                quantity: 1,
              });
            }
          } else if (selectedRegion === "AT" || selectedRegion === "DE") {
            // await shopifyClient.checkoutLineItemsRemove(
            //   checkoutObject.id,
            //   productionDACHHardwareLineItemsArray
            // );
            console.log(`determine line items here`);
            console.log(isVahaX, this.selectedPricingModel);

            lineItemsToAdd = productionDACHhardwareLineItems.slice();
            if (isVahaX) {
              lineItemsToAdd.unshift({
                variantId: `gid://shopify/ProductVariant/${productionDACHvahaXhardwareDeviceVariantID}`,
                quantity: 1,
              });
              if (this.selectedPricingModel === "INSTALLMENTS") {
                lineItemsToAdd.unshift({
                  variantId: `gid://shopify/ProductVariant/${productionDACHvahaXhardwareVariantID}`,
                  quantity: 1,
                });
              }
              if (this.selectedPricingModel === "ONE_TIME") {
                lineItemsToAdd.unshift({
                  variantId: `gid://shopify/ProductVariant/${productionDACHvahaPlan12MonthUpfrontVahaXVariantID}`,
                  quantity: 1,
                });
              }
            } else if (!isVahaX) {
              lineItemsToAdd.unshift({
                variantId: `gid://shopify/ProductVariant/${productionDACHvahaShardwareDeviceVariantID}`,
                quantity: 1,
              });
              if (this.selectedPricingModel === "INSTALLMENTS") {
                lineItemsToAdd.unshift({
                  variantId: `gid://shopify/ProductVariant/${productionDACHvahaShardwareVariantID}`,
                  quantity: 1,
                });
              }
              if (this.selectedPricingModel === "ONE_TIME") {
                lineItemsToAdd.unshift({
                  variantId: `gid://shopify/ProductVariant/${productionDACHvahaPlan12MonthUpfrontVahaSVariantID}`,
                  quantity: 1,
                });
              }
            }
          }
        }

        console.log(lineItemsToAdd);
        console.log(productionDACHhardwareLineItems);

        // await shopifyClient.checkout.addLineItems(
        //   checkoutObject.id,
        //   lineItemsToAdd
        // );
        const mutation = `
        mutation {
          cartCreate(input: {
            lines: [
              ${lineItemsToAdd
                .map(
                  ({ variantId, quantity }) =>
                    `{ merchandiseId: "${variantId}", quantity: ${quantity} }`
                )
                .join(",")}
            ]
          }) {
            cart {
              id
              checkoutUrl
            }
            userErrors {
              code
              field
              message
            }
          }
        }
      `;
        //vaha-shopify-cart-staging.herokuapp.com/cart/46542351532371:1,46542015201619:1

        const query = JSON.stringify({ query: mutation });

        const response = await axios({
          url: `https://${this.shopifyDomain}/api/2021-07/graphql.json`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "X-Shopify-Storefront-Access-Token": this
              .shopifyStorefrontAccessToken,
          },
          data: query,
        });

        console.log(response.data);
        let webUrl = null;
        if (response.data.data.cartCreate.cart) {
          webUrl = response.data.data.cartCreate.cart.checkoutUrl;
        }
        // console.log(checkoutObject);
        // console.log(checkoutObject.webUrl);
        let id = "";
        let name = "";
        if (window.dataLayer) {
          if (isVahaX) {
            id = "2";
            name = "VAHA X";
          } else if (!isVahaX) {
            id = "1";
            name = "VAHA S";
          }

          let selectedCurrency = "EUR";

          if (selectedRegion === "UK") {
            selectedCurrency = "GBP";
          } else if (selectedRegion === "DE" || selectedRegion === "AT") {
            selectedCurrency = "EUR";
          } else if (selectedRegion === "AE") {
            selectedCurrency = "AED";
          }

          window.dataLayer.push({
            event: "addToCart",
            ecommerce: {
              currencyCode: selectedCurrency, // currency of the shop (uk -> GBP)
              add: {
                // 'add' actionFieldObject measures.
                products: [{ id, name }],
              },
            },
          });

          let label = "";
          if (parseInt(id, 10) === 2) {
            label = "side_panel_vaha_x";
          } else if (parseInt(id, 10) === 1) {
            label = "side_panel_vaha_s";
          }

          window.dataLayer.push({
            event: "generic_event",
            category: "Checkout",
            action: "initiateCheckOut",
            label,
          });
        }
        console.log(lineItemsToAdd);
        lineItemsToAdd = [];

        const webUrlParsed = webUrl.includes("vaha-sports-store.myshopify.com")
          ? webUrl.replace("vaha-sports-store.myshopify.com", "vaha.com")
          : webUrl.replace("vaha-uk.myshopify.com", "uk-checkout.vaha.com");

        console.log(webUrl, webUrlParsed);

        setTimeout(window.open(webUrlParsed, "_self"), 100);
        return false;
        // return checkoutObject.webUrl;
      } catch (e) {
        console.log(e);
      } finally {
        this.$root.$emit("checkoutButtonClicked", false);
      }
    },
  },
  data() {
    return {
      shopifyStorefrontAccessToken: "",
      shopifyDomain: "",
    };
  },
  computed: {
    selectedPricingModel() {
      return store.state.selectedPricingModel;
    },
    shopifyDomainDACH() {
      return process.env.VUE_APP_SHOPIFY_DOMAIN_DACH || "vaha.com";
    },
    shopifyStorefrontTokenDACH() {
      return (
        process.env.VUE_APP_SHOPIFY_STOREFRONT_TOKEN_DACH ||
        "86aa76e490c91b3c7fc0cca8362de7d7"
      );
    },
    shopifyDomainUK() {
      return process.env.VUE_APP_SHOPIFY_DOMAIN_UK || "vaha-uk.myshopify.com";
    },
    shopifyStorefrontTokenUK() {
      return (
        process.env.VUE_APP_SHOPIFY_STOREFRONT_TOKEN_UK ||
        "880dc44bbd835cbefe2004411d7a0964"
      );
    },
    shopifyDomainAE() {
      return process.env.VUE_APP_SHOPIFY_DOMAIN_AE || "vaha-uae.myshopify.com";
    },
    shopifyStorefrontTokenAE() {
      return (
        process.env.VUE_APP_SHOPIFY_STOREFRONT_TOKEN_AE ||
        "dcce188688f2c34a6faf96153b0b517a"
      );
    },
    environment() {
      return store.environment;
    },
  },
};
