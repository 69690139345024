<template>
  <section class="new-year-container">
    <div
      class="is-flex is-justify-content-space-between is-align-items-flex-end is-flex-direction-column"
    >
      <div class="basket-wrapper mt-3 mb-3 is-align-items-center">
        <!-- <img
          :src="$t(content.newYear.heading.image)"
          class="new-year-image mr-3"
        /> -->
        <h3 class="font-18 font-700">
          {{ $t(content.newYear.heading.title) }}
        </h3>
      </div>
      <div
        class="basket-wrapper mt-2 mb-2"
        v-for="item of content.newYear.items"
        :key="item.title"
      >
        <div class="basket-title">
          <p v-if="$te(item.title)" class="font-700">{{ $t(item.title) }}</p>
          <p class="font-400 font-grey font-12" v-html="$t(item.description)" />
        </div>

        <div
          class="basket-list-price-wrapper is-justify-content-space-between is-align-items-flex-start"
        >
          <p class="font-700 has-text-right min-width-50">
            <span class="text-strikethrough">{{ $t(item.priceBefore) }}</span>
            <br />
            <span v-if="$te(item.priceAfter)">{{ $t(item.priceAfter) }}</span>
          </p>
        </div>
      </div>
      <!-- <div :class="'separator mt-4 mb-4'" />
      <div class="basket-wrapper mt-3 mb-3 total-gain">
        <div class="total-worth">
          {{ $t(content.newYear.total.headline) }}
        </div>
        <div class="total-worth-price-before font-700">
          <span class="text-strikethrough">{{
            $t(content.newYear.total.priceBefore)
          }}</span
          ><span>&nbsp;{{ $t(content.newYear.total.priceAfter) }}</span>
        </div>
      </div> -->
      <div :class="'separator mt-4 mb-4'" />
      <div class="basket-wrapper mt-3 mb-3 total-gain" v-if="!this.flatrate">
        <div class="total-worth">
          {{ $t(content.newYear.discount.title) }} <br />
          <span v-html="$t(content.newYear.discount.code.description)" />
          <strong class="has-text-black">{{
            $t(content.newYear.discount.code.promoCode)
          }}</strong>
        </div>
        <div class="total-worth-price-before font-700">
          <span>&nbsp;{{ $t(content.newYear.discount.amount) }}</span>
        </div>
      </div>
      <!-- <div :class="'separator mt-4 mb-4'" />
      <div class="basket-wrapper mt-2 mb-2" v-if="this.flatrate === true">
        <div class="basket-title">
          <p class="font-700">Rabatt für das VAHA X 36 Monats-Paket</p>
          <p class="font-400 font-grey font-12">
            Für den besten Weihnachts-Deal wähle das 36 Monats-Paket für das
            VAHA X.
          </p>
        </div>

        <div
          class="basket-list-price-wrapper is-justify-content-space-between is-align-items-flex-start"
        >
          <p class="font-700">-204€</p>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
export default {
  name: "NewYearPromo",
  props: {
    region: { type: String, default: "UK" },
    flatrate: { type: Boolean, default: false },
  },
  computed: {
    content() {
      if (this.flatrate) {
        return {
          newYear: {
            heading: {
              image: "flatrateNewYear.heading.image",
              title: "flatrateNewYear.heading.title",
            },
            total: {
              headline: "flatrateNewYear.total.headline",
              priceBefore: "flatrateNewYear.total.priceBefore",
              priceAfter: "flatrateNewYear.total.priceAfter",
            },
            discount: {
              title: "flatrateNewYear.discount.title",
              amount: "flatrateNewYear.discount.amount",
              code: {
                description: "flatrateNewYear.discount.code.description",
                promoCode: "flatrateNewYear.discount.code.promoCode",
              },
            },
            items: {
              item1: {
                title: "flatrateNewYear.items.item1.title",
                description: "flatrateNewYear.items.item1.description",
                priceBefore: "flatrateNewYear.items.item1.priceBefore",
                priceAfter: "flatrateNewYear.items.item1.priceAfter",
              },
              item2: {
                title: "flatrateNewYear.items.item2.title",
                description: "flatrateNewYear.items.item2.description",
                priceBefore: "flatrateNewYear.items.item2.priceBefore",
                priceAfter: "flatrateNewYear.items.item2.priceAfter",
              },
              item3: {
                title: "flatrateNewYear.items.item3.title",
                description: "flatrateNewYear.items.item3.description",
                priceBefore: "flatrateNewYear.items.item3.priceBefore",
                priceAfter: "flatrateNewYear.items.item3.priceAfter",
              },
              item4: {
                title: "flatrateNewYear.items.item4.title",
                description: "flatrateNewYear.items.item4.description",
                priceBefore: "flatrateNewYear.items.item4.priceBefore",
                priceAfter: "flatrateNewYear.items.item4.priceAfter",
              },
              item5: {
                title: "flatrateNewYear.items.item5.title",
                description: "flatrateNewYear.items.item5.description",
                priceBefore: "flatrateNewYear.items.item5.priceBefore",
                priceAfter: "flatrateNewYear.items.item5.priceAfter",
              },
              item6: {
                title: "flatrateNewYear.items.item6.title",
                description: "flatrateNewYear.items.item6.description",
                priceBefore: "flatrateNewYear.items.item6.priceBefore",
                priceAfter: "flatrateNewYear.items.item6.priceAfter",
              },
            },
          },
        };
      } else {
        return {
          newYear: {
            heading: {
              image: "newYear.heading.image",
              title: "newYear.heading.title",
            },
            total: {
              headline: "newYear.total.headline",
              priceBefore: "newYear.total.priceBefore",
              priceAfter: "newYear.total.priceAfter",
            },
            discount: {
              title: "newYear.discount.title",
              amount: "newYear.discount.amount",
              code: {
                description: "newYear.discount.code.description",
                promoCode: "newYear.discount.code.promoCode",
              },
            },
            items: {
              item1: {
                title: "newYear.items.item1.title",
                description: "newYear.items.item1.description",
                priceBefore: "newYear.items.item1.priceBefore",
                priceAfter: "newYear.items.item1.priceAfter",
              },
              item2: {
                title: "newYear.items.item2.title",
                description: "newYear.items.item2.description",
                priceBefore: "newYear.items.item2.priceBefore",
                priceAfter: "newYear.items.item2.priceAfter",
              },
              item3: {
                title: "newYear.items.item3.title",
                description: "newYear.items.item3.description",
                priceBefore: "newYear.items.item3.priceBefore",
                priceAfter: "newYear.items.item3.priceAfter",
              },
              item4: {
                title: "newYear.items.item4.title",
                description: "newYear.items.item4.description",
                priceBefore: "newYear.items.item4.priceBefore",
                priceAfter: "newYear.items.item4.priceAfter",
              },
              item5: {
                title: "newYear.items.item5.title",
                description: "newYear.items.item5.description",
                priceBefore: "newYear.items.item5.priceBefore",
                priceAfter: "newYear.items.item5.priceAfter",
              },
            },
          },
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.new-year-container {
  .font-18 {
    font-size: 18px;
    line-height: 27px;
  }

  .font-16 {
    font-size: 16px;
    line-height: 24px;
  }

  .font-15 {
    font-size: 15px;
    line-height: 24px;
  }

  .basket-wrapper {
    display: flex;
    justify-content: space-between;

    .basket-title {
      flex-direction: column;
      padding-right: 20px;
      margin-top: 0;
    }
  }

  .new-year-image {
    max-height: 64px;
    width: auto;
  }

  .text-strikethrough {
    text-decoration: line-through;
  }

  .font-12 {
    font-size: 12px;
    line-height: 150%;
  }

  .font-700 {
    font-weight: 700;
  }

  .font-400 {
    font-weight: 400;
  }
  .font-grey {
    color: #828282;
  }

  .min-width-50 {
    min-width: 50px;
  }
}
</style>
