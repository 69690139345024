<template>
  <component
    :is="component"
    v-bind="componentProps"
    :class="[$style.default, $style[theme]]"
    @click="$emit('click')"
  >
    <slot />
    <span v-if="title">
      {{ title }}
    </span>
    <slot name="icon">
      <span class="icon">
        <IconArrowRight height="12px" width="7px" />
      </span>
    </slot>
  </component>
</template>

<script>
import IconArrowRight from "../icons/arrow-right";

export default {
  components: {
    IconArrowRight,
  },
  props: {
    title: { type: String, default: "" },
    theme: { type: String, default: "yellow" },
    component: { type: String, default: "button" },
    componentProps: { type: Object, default: () => ({}) },
  },
};
</script>
<style lang="scss" module>
@import "../static/styles/bulma-variables";
@import "../static/styles/borders";

.default {
  @include border(1.5px, transparent);
  align-items: center;
  box-shadow: none;
  color: $color-carbon-black;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: $size-6 !important;
  font-weight: 600 !important;
  height: 50px;
  justify-content: center;
  letter-spacing: 0.02em;
  line-height: 30px;
  min-width: 183px;
  white-space: nowrap;
  padding: calc(0.5em - 1px) 1em;
}

.carbon {
  @include border(1.5px, $color-royal-blue);
  background-color: transparent;
  &:hover {
    @include border();
    color: $color-pure-white;
    background-color: $color-royal-blue;
  }
}

.yellow {
  background-color: #FFD26E;
  color: $color-carbon-black;
  &:hover {
    @include border(2px, inherit);
    background-color: $color-honey;
    color: $color-carbon-black;
  }
}

.transparent {
  background-color: transparent;
}
</style>
