export const blackVahaProductS = {
    mainPromoHeadline: "products.product.promotions.blackVaha.mainPromoHeadline",
    heading: {
      image: "products.productS.promotions.blackVaha.heading.image",
      title: "products.productS.promotions.blackVaha.heading.title",
    },
    pricesBeforeAfter: {
      priceBefore:
        "products.productS.promotions.blackVaha.pricesBeforeAfter.priceBefore",
      priceAfter:
        "products.productS.promotions.blackVaha.pricesBeforeAfter.priceAfter",
    },
    footer: "products.productS.promotions.blackVaha.footer",
    total: {
      headline: "products.productS.promotions.blackVaha.total.headline",
      priceBefore: "products.productS.promotions.blackVaha.total.priceBefore",
      priceAfter: "products.productS.promotions.blackVaha.total.priceAfter",
    },
    discount: {
      heading: "products.productS.promotions.blackVaha.discount.heading",
      title: "products.productS.promotions.blackVaha.discount.title",
      amount: "products.productS.promotions.blackVaha.discount.amount",
      code: {
        description:
          "products.productS.promotions.blackVaha.discount.code.description",
        promoCode:
          "products.productS.promotions.blackVaha.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title: "products.productS.promotions.blackVaha.items.item1.title",
        description:
          "products.productS.promotions.blackVaha.items.item1.description",
        priceBefore:
          "products.productS.promotions.blackVaha.items.item1.priceBefore",
        priceAfter:
          "products.productS.promotions.blackVaha.items.item1.priceAfter",
      },
      item2: {
        title: "products.productS.promotions.blackVaha.items.item2.title",
        description:
          "products.productS.promotions.blackVaha.items.item2.description",
        priceBefore:
          "products.productS.promotions.blackVaha.items.item2.priceBefore",
        priceAfter:
          "products.productS.promotions.blackVaha.items.item2.priceAfter",
      },
      item3: {
        title: "products.productS.promotions.blackVaha.items.item3.title",
        description:
          "products.productS.promotions.blackVaha.items.item3.description",
        priceBefore:
          "products.productS.promotions.blackVaha.items.item3.priceBefore",
        priceAfter:
          "products.productS.promotions.blackVaha.items.item3.priceAfter",
      },
      item4: {
        title: "products.productS.promotions.blackVaha.items.item4.title",
        description:
          "products.productS.promotions.blackVaha.items.item4.description",
        priceBefore:
          "products.productS.promotions.blackVaha.items.item4.priceBefore",
        priceAfter:
          "products.productS.promotions.blackVaha.items.item4.priceAfter",
      },
      item5: {
        title: "products.productS.promotions.blackVaha.items.item5.title",
        description:
          "products.productS.promotions.blackVaha.items.item5.description",
        priceBefore:
          "products.productS.promotions.blackVaha.items.item5.priceBefore",
        priceAfter:
          "products.productS.promotions.blackVaha.items.item5.priceAfter",
      },
      item6: {
        title: "products.productS.promotions.blackVaha.items.item6.title",
        description:
          "products.productS.promotions.blackVaha.items.item6.description",
        priceBefore:
          "products.productS.promotions.blackVaha.items.item6.priceBefore",
        priceAfter:
          "products.productS.promotions.blackVaha.items.item6.priceAfter",
      },
      item7: {
        title: "products.productS.promotions.blackVaha.items.item7.title",
        description:
          "products.productS.promotions.blackVaha.items.item7.description",
        priceBefore:
          "products.productS.promotions.blackVaha.items.item7.priceBefore",
        priceAfter:
          "products.productS.promotions.blackVaha.items.item7.priceAfter",
      },
    },
  };
  
  export const flatrateBlackVahaProductS = {
    mainPromoHeadline:
      "products.productS.promotions.flatrateblackVaha.mainPromoHeadline",
    heading: {
      image: "products.productS.promotions.flatrateblackVaha.heading.image",
      title: "products.productS.promotions.flatrateblackVaha.heading.title",
    },
    footer: "products.productS.promotions.flatrateblackVaha.footer",
    total: {
      headline: "products.productS.promotions.flatrateblackVaha.total.headline",
      priceBefore:
        "products.productS.promotions.flatrateblackVaha.total.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateblackVaha.total.priceAfter",
    },
    discount: {
      heading: "products.productS.promotions.blackVaha.discount.heading",
      title: "products.productS.promotions.flatrateblackVaha.discount.title",
      amount: "products.productS.promotions.flatrateblackVaha.discount.amount",
      code: {
        description:
          "products.productS.promotions.flatrateblackVaha.discount.code.description",
        promoCode:
          "products.productS.promotions.flatrateblackVaha.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title:
          "products.productS.promotions.flatrateblackVaha.items.item1.title",
        description:
          "products.productS.promotions.flatrateblackVaha.items.item1.description",
        priceBefore:
          "products.productS.promotions.flatrateblackVaha.items.item1.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateblackVaha.items.item1.priceAfter",
      },
      item2: {
        title:
          "products.productS.promotions.flatrateblackVaha.items.item2.title",
        description:
          "products.productS.promotions.flatrateblackVaha.items.item2.description",
        priceBefore:
          "products.productS.promotions.flatrateblackVaha.items.item2.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateblackVaha.items.item2.priceAfter",
      },
      item3: {
        title:
          "products.productS.promotions.flatrateblackVaha.items.item3.title",
        description:
          "products.productS.promotions.flatrateblackVaha.items.item3.description",
        priceBefore:
          "products.productS.promotions.flatrateblackVaha.items.item3.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateblackVaha.items.item3.priceAfter",
      },
      item4: {
        title:
          "products.productS.promotions.flatrateblackVaha.items.item4.title",
        description:
          "products.productS.promotions.flatrateblackVaha.items.item4.description",
        priceBefore:
          "products.productS.promotions.flatrateblackVaha.items.item4.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateblackVaha.items.item4.priceAfter",
      },
      item5: {
        title:
          "products.productS.promotions.flatrateblackVaha.items.item5.title",
        description:
          "products.productS.promotions.flatrateblackVaha.items.item5.description",
        priceBefore:
          "products.productS.promotions.flatrateblackVaha.items.item5.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateblackVaha.items.item5.priceAfter",
      },
      item6: {
        title:
          "products.productS.promotions.flatrateblackVaha.items.item6.title",
        description:
          "products.productS.promotions.flatrateblackVaha.items.item6.description",
        priceBefore:
          "products.productS.promotions.flatrateblackVaha.items.item6.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateblackVaha.items.item6.priceAfter",
      },
      item7: {
        title:
          "products.productS.promotions.flatrateblackVaha.items.item7.title",
        description:
          "products.productS.promotions.flatrateblackVaha.items.item7.description",
        priceBefore:
          "products.productS.promotions.flatrateblackVaha.items.item7.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateblackVaha.items.item7.priceAfter",
      },
    },
  };
  
  export const blackVahaProductX = {
    mainPromoHeadline:
      "products.productX.promotions.blackVaha.mainPromoHeadline",
    heading: {
      image: "products.productX.promotions.blackVaha.heading.image",
      title: "products.productX.promotions.blackVaha.heading.title",
    },
    pricesBeforeAfter: {
      priceBefore:
        "products.productX.promotions.blackVaha.pricesBeforeAfter.priceBefore",
      priceAfter:
        "products.productX.promotions.blackVaha.pricesBeforeAfter.priceAfter",
    },
    footer: "products.productX.promotions.blackVaha.footer",
    total: {
      headline: "products.productX.promotions.blackVaha.total.headline",
      priceBefore: "products.productX.promotions.blackVaha.total.priceBefore",
      priceAfter: "products.productX.promotions.blackVaha.total.priceAfter",
    },
    discount: {
      heading: "products.productX.promotions.blackVaha.discount.heading",
      title: "products.productX.promotions.blackVaha.discount.title",
      amount: "products.productX.promotions.blackVaha.discount.amount",
      code: {
        description:
          "products.productX.promotions.blackVaha.discount.code.description",
        promoCode:
          "products.productX.promotions.blackVaha.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title: "products.productX.promotions.blackVaha.items.item1.title",
        description:
          "products.productX.promotions.blackVaha.items.item1.description",
        priceBefore:
          "products.productX.promotions.blackVaha.items.item1.priceBefore",
        priceAfter:
          "products.productX.promotions.blackVaha.items.item1.priceAfter",
      },
      item2: {
        title: "products.productX.promotions.blackVaha.items.item2.title",
        description:
          "products.productX.promotions.blackVaha.items.item2.description",
        priceBefore:
          "products.productX.promotions.blackVaha.items.item2.priceBefore",
        priceAfter:
          "products.productX.promotions.blackVaha.items.item2.priceAfter",
      },
      item3: {
        title: "products.productX.promotions.blackVaha.items.item3.title",
        description:
          "products.productX.promotions.blackVaha.items.item3.description",
        priceBefore:
          "products.productX.promotions.blackVaha.items.item3.priceBefore",
        priceAfter:
          "products.productX.promotions.blackVaha.items.item3.priceAfter",
      },
      item4: {
        title: "products.productX.promotions.blackVaha.items.item4.title",
        description:
          "products.productX.promotions.blackVaha.items.item4.description",
        priceBefore:
          "products.productX.promotions.blackVaha.items.item4.priceBefore",
        priceAfter:
          "products.productX.promotions.blackVaha.items.item4.priceAfter",
      },
      item5: {
        title: "products.productX.promotions.blackVaha.items.item5.title",
        description:
          "products.productX.promotions.blackVaha.items.item5.description",
        priceBefore:
          "products.productX.promotions.blackVaha.items.item5.priceBefore",
        priceAfter:
          "products.productX.promotions.blackVaha.items.item5.priceAfter",
      },
      item6: {
        title: "products.productX.promotions.blackVaha.items.item6.title",
        description:
          "products.productX.promotions.blackVaha.items.item6.description",
        priceBefore:
          "products.productX.promotions.blackVaha.items.item6.priceBefore",
        priceAfter:
          "products.productX.promotions.blackVaha.items.item6.priceAfter",
      },
      item7: {
        title: "products.productX.promotions.blackVaha.items.item7.title",
        description:
          "products.productX.promotions.blackVaha.items.item7.description",
        priceBefore:
          "products.productX.promotions.blackVaha.items.item7.priceBefore",
        priceAfter:
          "products.productX.promotions.blackVaha.items.item7.priceAfter",
      },
    },
  };
  
  export const flatrateBlackVahaProductX = {
    mainPromoHeadline:
      "products.productX.promotions.flatrateblackVaha.mainPromoHeadline",
    heading: {
      image: "products.productX.promotions.flatrateblackVaha.heading.image",
      title: "products.productX.promotions.flatrateblackVaha.heading.title",
    },
    footer: "products.productX.promotions.flatrateblackVaha.footer",
    total: {
      headline: "products.productX.promotions.flatrateblackVaha.total.headline",
      priceBefore:
        "products.productX.promotions.flatrateblackVaha.total.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateblackVaha.total.priceAfter",
    },
    discount: {
      heading: "products.productX.promotions.blackVaha.discount.heading",
      title: "products.productX.promotions.flatrateblackVaha.discount.title",
      amount: "products.productX.promotions.flatrateblackVaha.discount.amount",
      code: {
        description:
          "products.productX.promotions.flatrateblackVaha.discount.code.description",
        promoCode:
          "products.productX.promotions.flatrateblackVaha.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title:
          "products.productX.promotions.flatrateblackVaha.items.item1.title",
        description:
          "products.productX.promotions.flatrateblackVaha.items.item1.description",
        priceBefore:
          "products.productX.promotions.flatrateblackVaha.items.item1.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateblackVaha.items.item1.priceAfter",
      },
      item2: {
        title:
          "products.productX.promotions.flatrateblackVaha.items.item2.title",
        description:
          "products.productX.promotions.flatrateblackVaha.items.item2.description",
        priceBefore:
          "products.productX.promotions.flatrateblackVaha.items.item2.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateblackVaha.items.item2.priceAfter",
      },
      item3: {
        title:
          "products.productX.promotions.flatrateblackVaha.items.item3.title",
        description:
          "products.productX.promotions.flatrateblackVaha.items.item3.description",
        priceBefore:
          "products.productX.promotions.flatrateblackVaha.items.item3.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateblackVaha.items.item3.priceAfter",
      },
      item4: {
        title:
          "products.productX.promotions.flatrateblackVaha.items.item4.title",
        description:
          "products.productX.promotions.flatrateblackVaha.items.item4.description",
        priceBefore:
          "products.productX.promotions.flatrateblackVaha.items.item4.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateblackVaha.items.item4.priceAfter",
      },
      item5: {
        title:
          "products.productX.promotions.flatrateblackVaha.items.item5.title",
        description:
          "products.productX.promotions.flatrateblackVaha.items.item5.description",
        priceBefore:
          "products.productX.promotions.flatrateblackVaha.items.item5.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateblackVaha.items.item5.priceAfter",
      },
      item6: {
        title:
          "products.productX.promotions.flatrateblackVaha.items.item6.title",
        description:
          "products.productX.promotions.flatrateblackVaha.items.item6.description",
        priceBefore:
          "products.productX.promotions.flatrateblackVaha.items.item6.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateblackVaha.items.item6.priceAfter",
      },
      item7: {
        title:
          "products.productX.promotions.flatrateblackVaha.items.item7.title",
        description:
          "products.productX.promotions.flatrateblackVaha.items.item7.description",
        priceBefore:
          "products.productX.promotions.flatrateblackVaha.items.item7.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateblackVaha.items.item7.priceAfter",
      },
    },
  };
  