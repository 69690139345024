export const valentinesDayProductS = {
    mainPromoHeadline:
      "products.product.promotions.valentinesDay.mainPromoHeadline",
    heading: {
      image: "products.productS.promotions.valentinesDay.heading.image",
      title: "products.productS.promotions.valentinesDay.heading.title",
    },
    pricesBeforeAfter: {
      priceBefore:
        "products.productS.promotions.valentinesDay.pricesBeforeAfter.priceBefore",
      priceAfter:
        "products.productS.promotions.valentinesDay.pricesBeforeAfter.priceAfter",
    },
    footer: "products.productS.promotions.valentinesDay.footer",
    total: {
      headline: "products.productS.promotions.valentinesDay.total.headline",
      priceBefore: "products.productS.promotions.valentinesDay.total.priceBefore",
      priceAfter: "products.productS.promotions.valentinesDay.total.priceAfter",
    },
    discount: {
      heading: "products.productS.promotions.valentinesDay.discount.heading",
      title: "products.productS.promotions.valentinesDay.discount.title",
      amount: "products.productS.promotions.valentinesDay.discount.amount",
      code: {
        description:
          "products.productS.promotions.valentinesDay.discount.code.description",
        promoCode:
          "products.productS.promotions.valentinesDay.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title: "products.productS.promotions.valentinesDay.items.item1.title",
        description:
          "products.productS.promotions.valentinesDay.items.item1.description",
        priceBefore:
          "products.productS.promotions.valentinesDay.items.item1.priceBefore",
        priceAfter:
          "products.productS.promotions.valentinesDay.items.item1.priceAfter",
      },
      item2: {
        title: "products.productS.promotions.valentinesDay.items.item2.title",
        description:
          "products.productS.promotions.valentinesDay.items.item2.description",
        priceBefore:
          "products.productS.promotions.valentinesDay.items.item2.priceBefore",
        priceAfter: "products.productS.promotions.valentinesDay.items.item2.priceAfter",
      },
      item3: {
        title: "products.productS.promotions.valentinesDay.items.item3.title",
        description:
          "products.productS.promotions.valentinesDay.items.item3.description",
        priceBefore:
          "products.productS.promotions.valentinesDay.items.item3.priceBefore",
        priceAfter:
          "products.productS.promotions.valentinesDay.items.item3.priceAfter",
      },
      item4: {
        title: "products.productS.promotions.valentinesDay.items.item4.title",
        description:
          "products.productS.promotions.valentinesDay.items.item4.description",
        priceBefore:
          "products.productS.promotions.valentinesDay.items.item4.priceBefore",
        priceAfter:
          "products.productS.promotions.valentinesDay.items.item4.priceAfter",
      },
      item5: {
        title: "products.productS.promotions.valentinesDay.items.item5.title",
        description:
          "products.productS.promotions.valentinesDay.items.item5.description",
        priceBefore:
          "products.productS.promotions.valentinesDay.items.item5.priceBefore",
        priceAfter:
          "products.productS.promotions.valentinesDay.items.item5.priceAfter",
      },
    },
  };
  
  export const flatrateValentinesDayProductS = {
    mainPromoHeadline:
      "products.productS.promotions.flatrateValentinesDay.mainPromoHeadline",
    heading: {
      image: "products.productS.promotions.flatrateValentinesDay.heading.image",
      title: "products.productS.promotions.flatrateValentinesDay.heading.title",
    },
    footer: "products.productS.promotions.flatrateValentinesDay.footer",
    total: {
      headline:
        "products.productS.promotions.flatrateValentinesDay.total.headline",
      priceBefore:
        "products.productS.promotions.flatrateValentinesDay.total.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateValentinesDay.total.priceAfter",
    },
    discount: {
      heading: "products.productS.promotions.valentinesDay.discount.heading",
      title: "products.productS.promotions.flatrateValentinesDay.discount.title",
      amount: "products.productS.promotions.flatrateValentinesDay.discount.amount",
      code: {
        description:
          "products.productS.promotions.flatrateValentinesDay.discount.code.description",
        promoCode:
          "products.productS.promotions.flatrateValentinesDay.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title:
          "products.productS.promotions.flatrateValentinesDay.items.item1.title",
        description:
          "products.productS.promotions.flatrateValentinesDay.items.item1.description",
        priceBefore:
          "products.productS.promotions.flatrateValentinesDay.items.item1.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateValentinesDay.items.item1.priceAfter",
      },
      item2: {
        title:
          "products.productS.promotions.flatrateValentinesDay.items.item2.title",
        description:
          "products.productS.promotions.flatrateValentinesDay.items.item2.description",
        priceBefore:
          "products.productS.promotions.flatrateValentinesDay.items.item2.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateValentinesDay.items.item2.priceAfter",
      },
      item3: {
        title:
          "products.productS.promotions.flatrateValentinesDay.items.item3.title",
        description:
          "products.productS.promotions.flatrateValentinesDay.items.item3.description",
        priceBefore:
          "products.productS.promotions.flatrateValentinesDay.items.item3.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateValentinesDay.items.item3.priceAfter",
      },
      item4: {
        title:
          "products.productS.promotions.flatrateValentinesDay.items.item4.title",
        description:
          "products.productS.promotions.flatrateValentinesDay.items.item4.description",
        priceBefore:
          "products.productS.promotions.flatrateValentinesDay.items.item4.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateValentinesDay.items.item4.priceAfter",
      },
      item5: {
        title:
          "products.productS.promotions.flatrateValentinesDay.items.item5.title",
        description:
          "products.productS.promotions.flatrateValentinesDay.items.item5.description",
        priceBefore:
          "products.productS.promotions.flatrateValentinesDay.items.item5.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateValentinesDay.items.item5.priceAfter",
      },
      item6: {
        title:
          "products.productS.promotions.flatrateValentinesDay.items.item6.title",
        description:
          "products.productS.promotions.flatrateValentinesDay.items.item6.description",
        priceBefore:
          "products.productS.promotions.flatrateValentinesDay.items.item6.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateValentinesDay.items.item6.priceAfter",
      },
    },
  };
  
  export const valentinesDayProductX = {
    mainPromoHeadline:
      "products.productX.promotions.valentinesDay.mainPromoHeadline",
    heading: {
      image: "products.productX.promotions.valentinesDay.heading.image",
      title: "products.productX.promotions.valentinesDay.heading.title",
    },
    pricesBeforeAfter: {
      priceBefore:
        "products.productX.promotions.valentinesDay.pricesBeforeAfter.priceBefore",
      priceAfter:
        "products.productX.promotions.valentinesDay.pricesBeforeAfter.priceAfter",
    },
    footer: "products.productX.promotions.valentinesDay.footer",
    total: {
      headline: "products.productX.promotions.valentinesDay.total.headline",
      priceBefore: "products.productX.promotions.valentinesDay.total.priceBefore",
      priceAfter: "products.productX.promotions.valentinesDay.total.priceAfter",
    },
    discount: {
      heading: "products.productX.promotions.valentinesDay.discount.heading",
      title: "products.productX.promotions.valentinesDay.discount.title",
      amount: "products.productX.promotions.valentinesDay.discount.amount",
      code: {
        description:
          "products.productX.promotions.valentinesDay.discount.code.description",
        promoCode:
          "products.productX.promotions.valentinesDay.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title: "products.productX.promotions.valentinesDay.items.item1.title",
        description:
          "products.productX.promotions.valentinesDay.items.item1.description",
        priceBefore:
          "products.productX.promotions.valentinesDay.items.item1.priceBefore",
        priceAfter:
          "products.productX.promotions.valentinesDay.items.item1.priceAfter",
      },
      item2: {
        title: "products.productX.promotions.valentinesDay.items.item2.title",
        description:
          "products.productX.promotions.valentinesDay.items.item2.description",
        priceBefore:
          "products.productX.promotions.valentinesDay.items.item2.priceBefore",
        priceAfter: "products.productX.promotions.valentinesDay.items.item2.priceAfter",
      },
      item3: {
        title: "products.productX.promotions.valentinesDay.items.item3.title",
        description:
          "products.productX.promotions.valentinesDay.items.item3.description",
        priceBefore:
          "products.productX.promotions.valentinesDay.items.item3.priceBefore",
        priceAfter:
          "products.productX.promotions.valentinesDay.items.item3.priceAfter",
      },
      item4: {
        title: "products.productX.promotions.valentinesDay.items.item4.title",
        description:
          "products.productX.promotions.valentinesDay.items.item4.description",
        priceBefore:
          "products.productX.promotions.valentinesDay.items.item4.priceBefore",
        priceAfter:
          "products.productX.promotions.valentinesDay.items.item4.priceAfter",
      },
      item5: {
        title: "products.productX.promotions.valentinesDay.items.item5.title",
        description:
          "products.productX.promotions.valentinesDay.items.item5.description",
        priceBefore:
          "products.productX.promotions.valentinesDay.items.item5.priceBefore",
        priceAfter:
          "products.productX.promotions.valentinesDay.items.item5.priceAfter",
      },
    },
  };
  
  export const flatrateValentinesDayProductX = {
    mainPromoHeadline:
      "products.productX.promotions.flatrateValentinesDay.mainPromoHeadline",
    heading: {
      image: "products.productX.promotions.flatrateValentinesDay.heading.image",
      title: "products.productX.promotions.flatrateValentinesDay.heading.title",
    },
    footer: "products.productX.promotions.flatrateValentinesDay.footer",
    total: {
      headline:
        "products.productX.promotions.flatrateValentinesDay.total.headline",
      priceBefore:
        "products.productX.promotions.flatrateValentinesDay.total.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateValentinesDay.total.priceAfter",
    },
    discount: {
      heading: "products.productX.promotions.valentinesDay.discount.heading",
      title: "products.productX.promotions.flatrateValentinesDay.discount.title",
      amount: "products.productX.promotions.flatrateValentinesDay.discount.amount",
      code: {
        description:
          "products.productX.promotions.flatrateValentinesDay.discount.code.description",
        promoCode:
          "products.productX.promotions.flatrateValentinesDay.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title:
          "products.productX.promotions.flatrateValentinesDay.items.item1.title",
        description:
          "products.productX.promotions.flatrateValentinesDay.items.item1.description",
        priceBefore:
          "products.productX.promotions.flatrateValentinesDay.items.item1.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateValentinesDay.items.item1.priceAfter",
      },
      item2: {
        title:
          "products.productX.promotions.flatrateValentinesDay.items.item2.title",
        description:
          "products.productX.promotions.flatrateValentinesDay.items.item2.description",
        priceBefore:
          "products.productX.promotions.flatrateValentinesDay.items.item2.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateValentinesDay.items.item2.priceAfter",
      },
      item3: {
        title:
          "products.productX.promotions.flatrateValentinesDay.items.item3.title",
        description:
          "products.productX.promotions.flatrateValentinesDay.items.item3.description",
        priceBefore:
          "products.productX.promotions.flatrateValentinesDay.items.item3.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateValentinesDay.items.item3.priceAfter",
      },
      item4: {
        title:
          "products.productX.promotions.flatrateValentinesDay.items.item4.title",
        description:
          "products.productX.promotions.flatrateValentinesDay.items.item4.description",
        priceBefore:
          "products.productX.promotions.flatrateValentinesDay.items.item4.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateValentinesDay.items.item4.priceAfter",
      },
      item5: {
        title:
          "products.productX.promotions.flatrateValentinesDay.items.item5.title",
        description:
          "products.productX.promotions.flatrateValentinesDay.items.item5.description",
        priceBefore:
          "products.productX.promotions.flatrateValentinesDay.items.item5.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateValentinesDay.items.item5.priceAfter",
      },
      item6: {
        title:
          "products.productX.promotions.flatrateValentinesDay.items.item6.title",
        description:
          "products.productX.promotions.flatrateValentinesDay.items.item6.description",
        priceBefore:
          "products.productX.promotions.flatrateValentinesDay.items.item6.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateValentinesDay.items.item6.priceAfter",
      },
    },
  };
  