<template>
  <PageSectionContainer class="has-background-white" container>
    <h2
      v-if="headlineKey"
      class="vaha-headline-h2 has-text-centered-desktop mb-5 pr-6"
    >
      {{ $t(headlineKey) }}
    </h2>
    <ul class="columns is-multiline is-mobile mt-2">
      <li
        v-for="item in items"
        :key="item"
        class="column is-12-mobile is-10-tablet is-4-desktop"
      >
        <span class="vaha-item">
          <span class="vaha-icon mb-2">
            <component :is="`Icon${item}`" :class="`vaha-icon-${item}`" />
          </span>
          <span class="vaha-text has-text-centered-desktop">
            {{ $t(`product.service.feature${item}`) }}
          </span>
        </span>
      </li>
    </ul>
  </PageSectionContainer>
</template>

<script>
import PageSectionContainer from './container/PageSectionContainer';
import IconTesting from '../icons/trial';
import IconWarranty from '../icons/guarantee';
import IconDelivery from '../icons/delivery';

export default {
  components: {
    IconDelivery,
    IconTesting,
    IconWarranty,
    PageSectionContainer,
  },
  props: {
    headlineKey: { type: String, default: '' },
  },
  computed: {
    items() {
      return ['Delivery', 'Testing', 'Warranty'];
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'node_modules/bulma/sass/utilities/_all';

.vaha-item {
  display: flex;
  align-items: center;

  @include desktop() {
    flex-direction: column;
    justify-content: center;
  }
}

.vaha-icon {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 70px;
  height: 45px;

  &-Delivery {
    width: 51px;
  }
  &-Testing {
    width: 41px;
  }
  &-Warranty {
    width: 43px;
  }
}

.vaha-text {
  @include desktop() {
    max-width: 280px;
  }
}
</style>
