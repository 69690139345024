<template>
  <section class="basket-collapse">
    <div
      class="basket-wrapper mt-3 mb-3 is-align-items-center is-justify-content-space-between"
      @click="toggle"
    >
      <div class="icon-heading-wrapper">
        <IconInfo v-if="icon === 'info'" />
        <IconMedal v-else-if="icon === 'medal'" />
        <IconStar v-else-if="icon === 'star-promo'" />
        <h3 class="font-18 font-700 text-uppercase">
          {{ title }}
        </h3>
      </div>
      <div class="vaha-accordion-icon">
        <ArrowRightPromo v-if="!open" />
        <ArrowDownPromo v-else />
      </div>
    </div>
    <b-collapse :id="id" class="mt-2" :open="open" animation="slide">
      <slot />
    </b-collapse>
  </section>
</template>

<script>
import IconInfo from "../icons/info";
import IconMedal from "../icons/medal";
import IconStar from "../icons/star-promo";
import ArrowDownPromo from "../icons/arrow-down-promo";
import ArrowRightPromo from "../icons/arrow-right-promo";
export default {
  name: "BasketCollapse",
  components: {
    ArrowDownPromo,
    ArrowRightPromo,
    IconInfo,
    IconMedal,
    IconStar,
  },
  props: {
    id: { type: String, required: false },
    title: { type: String, required: true, default: "Open" },
    icon: { type: String, required: false },
  },

  data() {
    return {
      open: true,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../static/styles/text-sizing";

.basket-collapse {
  width: 100%;

  .icon-heading-wrapper {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
      margin-top: -2px;
    }
  }

  .basket-wrapper {
    display: flex;
    width: 100%;
    cursor: pointer;
  }

  .font-18 {
    font-size: 18px;
    line-height: 27px;
  }

  .font-700 {
    font-weight: 700;
  }

  .text-uppercase {
    text-transform: uppercase;
  }
}
.vaha-accordion-icon {
  display: flex;
  align-items: center;
}
</style>
