<template>
  <section class="basket-accordion">
    <AppSeparator class="mt-4" color-variant="black-ter" />
    <template v-for="item in items">
      <b-collapse
        :key="item.id"
        animation="slide"
        :open="item.id === openItemId"
        @close="toggle(item.id)"
        @open="toggle(item.id)"
      >
        <template #trigger="props">
          <div
            class="is-flex is-justify-content-space-between is-align-items-center py-4"
          >
            <h4 class="vaha-accordion-item-headline is-half">
              <span
                class="vaha-accordion-item-headline-row-1"
                v-if="$te(item.titleRow1)"
                >{{ $t(item.titleRow1) }}</span
              >
              <span
                class="vaha-accordion-item-headline-row-2"
                v-if="$te(item.titleRow2)"
                >{{ $t(item.titleRow2) }}</span
              >
            </h4>
            <div class="prices-accordion">
              <div class="prices-wrapper">
                <div class="prices-row-1">
                  <span
                    class="vaha-accordion-item-price-from is-half"
                    v-if="$te(item.priceFrom)"
                  >
                    {{ $t(item.priceFrom) }}
                  </span>
                  <span
                    class="vaha-accordion-item-price-before"
                    v-if="$te(item.priceBefore)"
                  >
                    {{ $t(item.priceBefore) }}
                  </span>
                  <span
                    class="vaha-accordion-item-price-after"
                    v-if="$te(item.priceAfter)"
                  >
                    {{ $t(item.priceAfter) }}
                  </span>
                </div>
                <div class="prices-row-2">
                  <span
                    class="vaha-accordion-item-price-row-2"
                    v-if="$te(item.priceRow2)"
                  >
                    {{ $t(item.priceRow2) }}
                  </span>
                </div>
              </div>
              <div class="vaha-accordion-icon">
                <ArrowDown v-if="props.open" />
                <ArrowUp v-if="!props.open" />
              </div>
            </div>
          </div>
        </template>
        <div class="vaha-accordion-content" @click="toggle(item.id)">
          <p
            class="vaha-accordion-content-description is-two-thirds"
            v-if="$te(item.content)"
          >
            {{ $t(item.content) }}
          </p>
          <ul
            class="vaha-accordion-content-description is-two-thirds"
            v-if="$te(item.list.item1)"
          >
            <li v-for="listElement in item.list" :key="listElement">
              <span v-if="$te(listElement)">{{ $t(listElement) }}</span>
            </li>
          </ul>
          <div class="vaha-accordion-content-image is-one-third">
            <img :src="$t(item.image)" />
          </div>
        </div>
      </b-collapse>
    </template>
  </section>
</template>

<script>
import ArrowDown from "../icons/arrow-down";
import ArrowUp from "../icons/arrow-up";
export default {
  name: "BasketAccordion",
  components: {
    ArrowDown,
    ArrowUp,
  },
  props: {
    items: { type: Object, required: true },
  },

  data() {
    return {
      openItemId: null,
    };
  },
  methods: {
    toggle(itemId) {
      if (this.openItemId === itemId) {
        this.openItemId = null;
      } else {
        this.openItemId = itemId;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../static/styles/text-sizing";

.basket-accordion {
  background: white;
  max-width: 100%;
  width: 100%;
  .collapse {
    box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 0 20px;
    border-radius: 4px;
    .button {
      outline: none;
      svg {
        outline: none;
        transition: all 0.4s;
        path {
          fill: #ffd26e;
        }
        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }

  .prices-accordion {
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    display: flex;

    .prices-wrapper {
      display: flex;
      flex-direction: column;
      margin-right: 10px;
    }
  }

  .vaha-accordion-item-headline {
    width: 50%;

    &-row-1 {
      font-size: 20px;
      font-weight: 700;
      display: block;
      width: 100%;
    }

    &-row-2 {
      font-size: 18px;
      font-weight: 700;
    }
  }
}

.vaha-accordion {
  &-item-headline {
    @include font-18;
  }
  &-content {
    cursor: pointer;
    padding-bottom: 1rem;
    text-align: justify;
    display: flex;
    justify-content: space-between;

    &-description {
      max-width: 66.67%;
      padding-right: 10px;
      text-align: left;
    }
  }

  &-price-after,
  &-price-before {
    font-size: 15px;
  }
}
.vaha-accordion-item-price-before {
  color: #666666;
  text-decoration: line-through;
  text-align: center;
  margin-right: 5px;
}

.vaha-accordion-icon {
  display: flex;
  align-items: center;
}
</style>
