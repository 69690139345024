<template>
  <section class="width-100 is-flex is-flex-direction-column">
    <p
      v-if="$te(discount.heading)"
      class="strong font-18 basket-price mt-3 mb-3"
    >
      {{ $t(discount.heading) }}
    </p>
    <div
      class="basket-wrapper mt-3 mb-3 total-gain is-flex is-justify-content-space-between is-align-items-flex-end"
    >
      <div class="total-worth">
        {{ $t(discount.title) }} <br />
        <span v-html="$t(discount.code.description)" />
        <strong class="has-text-black">{{
          $t(discount.code.promoCode)
        }}</strong>
      </div>
      <div class="total-worth-price-before font-700">
        <span>&nbsp;{{ $t(discount.amount) }}</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BasketDiscountRow",
  props: {
    discount: { type: Object, default: () => {} },
  },
};
</script>

<style lang="scss">
.width-100 {
  width: 100%;
}
</style>
