export const vahaPowerProductS = {
    mainPromoHeadline:
      "products.product.promotions.vahaPower.mainPromoHeadline",
    heading: {
      image: "products.productS.promotions.vahaPower.heading.image",
      title: "products.productS.promotions.vahaPower.heading.title",
    },
    pricesBeforeAfter: {
      priceBefore:
        "products.productS.promotions.vahaPower.pricesBeforeAfter.priceBefore",
      priceAfter:
        "products.productS.promotions.vahaPower.pricesBeforeAfter.priceAfter",
    },
    footer: "products.productS.promotions.vahaPower.footer",
    total: {
      headline: "products.productS.promotions.vahaPower.total.headline",
      priceBefore: "products.productS.promotions.vahaPower.total.priceBefore",
      priceAfter: "products.productS.promotions.vahaPower.total.priceAfter",
    },
    discount: {
      heading: "products.productS.promotions.vahaPower.discount.heading",
      title: "products.productS.promotions.vahaPower.discount.title",
      amount: "products.productS.promotions.vahaPower.discount.amount",
      code: {
        description:
          "products.productS.promotions.vahaPower.discount.code.description",
        promoCode:
          "products.productS.promotions.vahaPower.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title: "products.productS.promotions.vahaPower.items.item1.title",
        description:
          "products.productS.promotions.vahaPower.items.item1.description",
        priceBefore:
          "products.productS.promotions.vahaPower.items.item1.priceBefore",
        priceAfter:
          "products.productS.promotions.vahaPower.items.item1.priceAfter",
      },
      item2: {
        title: "products.productS.promotions.vahaPower.items.item2.title",
        description:
          "products.productS.promotions.vahaPower.items.item2.description",
        priceBefore:
          "products.productS.promotions.vahaPower.items.item2.priceBefore",
        priceAfter: "products.productS.promotions.vahaPower.items.item2.priceAfter",
      },
      item3: {
        title: "products.productS.promotions.vahaPower.items.item3.title",
        description:
          "products.productS.promotions.vahaPower.items.item3.description",
        priceBefore:
          "products.productS.promotions.vahaPower.items.item3.priceBefore",
        priceAfter:
          "products.productS.promotions.vahaPower.items.item3.priceAfter",
      },
      item4: {
        title: "products.productS.promotions.vahaPower.items.item4.title",
        description:
          "products.productS.promotions.vahaPower.items.item4.description",
        priceBefore:
          "products.productS.promotions.vahaPower.items.item4.priceBefore",
        priceAfter:
          "products.productS.promotions.vahaPower.items.item4.priceAfter",
      },
      item5: {
        title: "products.productS.promotions.vahaPower.items.item5.title",
        description:
          "products.productS.promotions.vahaPower.items.item5.description",
        priceBefore:
          "products.productS.promotions.vahaPower.items.item5.priceBefore",
        priceAfter:
          "products.productS.promotions.vahaPower.items.item5.priceAfter",
      },
    },
  };
  
  export const flatrateVahaPowerProductS = {
    mainPromoHeadline:
      "products.productS.promotions.flatrateVahaPower.mainPromoHeadline",
    heading: {
      image: "products.productS.promotions.flatrateVahaPower.heading.image",
      title: "products.productS.promotions.flatrateVahaPower.heading.title",
    },
    footer: "products.productS.promotions.flatrateVahaPower.footer",
    total: {
      headline:
        "products.productS.promotions.flatrateVahaPower.total.headline",
      priceBefore:
        "products.productS.promotions.flatrateVahaPower.total.priceBefore",
      priceAfter:
        "products.productS.promotions.flatrateVahaPower.total.priceAfter",
    },
    discount: {
      heading: "products.productS.promotions.vahaPower.discount.heading",
      title: "products.productS.promotions.flatrateVahaPower.discount.title",
      amount: "products.productS.promotions.flatrateVahaPower.discount.amount",
      code: {
        description:
          "products.productS.promotions.flatrateVahaPower.discount.code.description",
        promoCode:
          "products.productS.promotions.flatrateVahaPower.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title:
          "products.productS.promotions.flatrateVahaPower.items.item1.title",
        description:
          "products.productS.promotions.flatrateVahaPower.items.item1.description",
        priceBefore:
          "products.productS.promotions.flatrateVahaPower.items.item1.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateVahaPower.items.item1.priceAfter",
      },
      item2: {
        title:
          "products.productS.promotions.flatrateVahaPower.items.item2.title",
        description:
          "products.productS.promotions.flatrateVahaPower.items.item2.description",
        priceBefore:
          "products.productS.promotions.flatrateVahaPower.items.item2.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateVahaPower.items.item2.priceAfter",
      },
      item3: {
        title:
          "products.productS.promotions.flatrateVahaPower.items.item3.title",
        description:
          "products.productS.promotions.flatrateVahaPower.items.item3.description",
        priceBefore:
          "products.productS.promotions.flatrateVahaPower.items.item3.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateVahaPower.items.item3.priceAfter",
      },
      item4: {
        title:
          "products.productS.promotions.flatrateVahaPower.items.item4.title",
        description:
          "products.productS.promotions.flatrateVahaPower.items.item4.description",
        priceBefore:
          "products.productS.promotions.flatrateVahaPower.items.item4.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateVahaPower.items.item4.priceAfter",
      },
      item5: {
        title:
          "products.productS.promotions.flatrateVahaPower.items.item5.title",
        description:
          "products.productS.promotions.flatrateVahaPower.items.item5.description",
        priceBefore:
          "products.productS.promotions.flatrateVahaPower.items.item5.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateVahaPower.items.item5.priceAfter",
      },
      item6: {
        title:
          "products.productS.promotions.flatrateVahaPower.items.item6.title",
        description:
          "products.productS.promotions.flatrateVahaPower.items.item6.description",
        priceBefore:
          "products.productS.promotions.flatrateVahaPower.items.item6.priceBefore",
        priceAfter:
          "products.productS.promotions.flatrateVahaPower.items.item6.priceAfter",
      },
    },
  };
  
  export const vahaPowerProductX = {
    mainPromoHeadline:
      "products.productX.promotions.vahaPower.mainPromoHeadline",
    heading: {
      image: "products.productX.promotions.vahaPower.heading.image",
      title: "products.productX.promotions.vahaPower.heading.title",
    },
    pricesBeforeAfter: {
      priceBefore:
        "products.productX.promotions.vahaPower.pricesBeforeAfter.priceBefore",
      priceAfter:
        "products.productX.promotions.vahaPower.pricesBeforeAfter.priceAfter",
    },
    footer: "products.productX.promotions.vahaPower.footer",
    total: {
      headline: "products.productX.promotions.vahaPower.total.headline",
      priceBefore: "products.productX.promotions.vahaPower.total.priceBefore",
      priceAfter: "products.productX.promotions.vahaPower.total.priceAfter",
    },
    discount: {
      heading: "products.productX.promotions.vahaPower.discount.heading",
      title: "products.productX.promotions.vahaPower.discount.title",
      amount: "products.productX.promotions.vahaPower.discount.amount",
      code: {
        description:
          "products.productX.promotions.vahaPower.discount.code.description",
        promoCode:
          "products.productX.promotions.vahaPower.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title: "products.productX.promotions.vahaPower.items.item1.title",
        description:
          "products.productX.promotions.vahaPower.items.item1.description",
        priceBefore:
          "products.productX.promotions.vahaPower.items.item1.priceBefore",
        priceAfter:
          "products.productX.promotions.vahaPower.items.item1.priceAfter",
      },
      item2: {
        title: "products.productX.promotions.vahaPower.items.item2.title",
        description:
          "products.productX.promotions.vahaPower.items.item2.description",
        priceBefore:
          "products.productX.promotions.vahaPower.items.item2.priceBefore",
        priceAfter: "products.productX.promotions.vahaPower.items.item2.priceAfter",
      },
      item3: {
        title: "products.productX.promotions.vahaPower.items.item3.title",
        description:
          "products.productX.promotions.vahaPower.items.item3.description",
        priceBefore:
          "products.productX.promotions.vahaPower.items.item3.priceBefore",
        priceAfter:
          "products.productX.promotions.vahaPower.items.item3.priceAfter",
      },
      item4: {
        title: "products.productX.promotions.vahaPower.items.item4.title",
        description:
          "products.productX.promotions.vahaPower.items.item4.description",
        priceBefore:
          "products.productX.promotions.vahaPower.items.item4.priceBefore",
        priceAfter:
          "products.productX.promotions.vahaPower.items.item4.priceAfter",
      },
      item5: {
        title: "products.productX.promotions.vahaPower.items.item5.title",
        description:
          "products.productX.promotions.vahaPower.items.item5.description",
        priceBefore:
          "products.productX.promotions.vahaPower.items.item5.priceBefore",
        priceAfter:
          "products.productX.promotions.vahaPower.items.item5.priceAfter",
      },
    },
  };
  
  export const flatrateVahaPowerProductX = {
    mainPromoHeadline:
      "products.productX.promotions.flatrateVahaPower.mainPromoHeadline",
    heading: {
      image: "products.productX.promotions.flatrateVahaPower.heading.image",
      title: "products.productX.promotions.flatrateVahaPower.heading.title",
    },
    footer: "products.productX.promotions.flatrateVahaPower.footer",
    total: {
      headline:
        "products.productX.promotions.flatrateVahaPower.total.headline",
      priceBefore:
        "products.productX.promotions.flatrateVahaPower.total.priceBefore",
      priceAfter:
        "products.productX.promotions.flatrateVahaPower.total.priceAfter",
    },
    discount: {
      heading: "products.productX.promotions.vahaPower.discount.heading",
      title: "products.productX.promotions.flatrateVahaPower.discount.title",
      amount: "products.productX.promotions.flatrateVahaPower.discount.amount",
      code: {
        description:
          "products.productX.promotions.flatrateVahaPower.discount.code.description",
        promoCode:
          "products.productX.promotions.flatrateVahaPower.discount.code.promoCode",
      },
    },
    items: {
      item1: {
        title:
          "products.productX.promotions.flatrateVahaPower.items.item1.title",
        description:
          "products.productX.promotions.flatrateVahaPower.items.item1.description",
        priceBefore:
          "products.productX.promotions.flatrateVahaPower.items.item1.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateVahaPower.items.item1.priceAfter",
      },
      item2: {
        title:
          "products.productX.promotions.flatrateVahaPower.items.item2.title",
        description:
          "products.productX.promotions.flatrateVahaPower.items.item2.description",
        priceBefore:
          "products.productX.promotions.flatrateVahaPower.items.item2.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateVahaPower.items.item2.priceAfter",
      },
      item3: {
        title:
          "products.productX.promotions.flatrateVahaPower.items.item3.title",
        description:
          "products.productX.promotions.flatrateVahaPower.items.item3.description",
        priceBefore:
          "products.productX.promotions.flatrateVahaPower.items.item3.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateVahaPower.items.item3.priceAfter",
      },
      item4: {
        title:
          "products.productX.promotions.flatrateVahaPower.items.item4.title",
        description:
          "products.productX.promotions.flatrateVahaPower.items.item4.description",
        priceBefore:
          "products.productX.promotions.flatrateVahaPower.items.item4.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateVahaPower.items.item4.priceAfter",
      },
      item5: {
        title:
          "products.productX.promotions.flatrateVahaPower.items.item5.title",
        description:
          "products.productX.promotions.flatrateVahaPower.items.item5.description",
        priceBefore:
          "products.productX.promotions.flatrateVahaPower.items.item5.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateVahaPower.items.item5.priceAfter",
      },
      item6: {
        title:
          "products.productX.promotions.flatrateVahaPower.items.item6.title",
        description:
          "products.productX.promotions.flatrateVahaPower.items.item6.description",
        priceBefore:
          "products.productX.promotions.flatrateVahaPower.items.item6.priceBefore",
        priceAfter:
          "products.productX.promotions.flatrateVahaPower.items.item6.priceAfter",
      },
    },
  };
  